import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { Home } from "./Home";
import { ResetPass } from "./ResetPass";
import { TermPage } from "../../TermAndCondition/pages/TermPage";
import { AboutUs } from "./AboutUs";
import { Blog } from "./Blog";
import { AgeDetail } from "../../AgeDetail/AgeDetail";

export function AuthIndex() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/home" />}
        <ContentRoute path="/home/about" component={AboutUs} />
        <ContentRoute path="/home/age/:id" component={AgeDetail} />
        <ContentRoute path="/home/blog" component={Blog} />
        <ContentRoute path="/home" component={Home} />
        <ContentRoute
          path="/home/:id/term-and-condition"
          component={TermPage}
        />
        <Redirect to="/home" />
      </Switch>
    </Suspense>
  );
}
