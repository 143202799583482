import React, { useState, useEffect, useMemo, forwardRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  NoRecordsFoundMessage,
  getHandlerTableChange,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import { isEqual } from "lodash";
import * as actions from "../_redux/EvaluateChildrenActions";
import * as uiHelpers from "./EvaluateChildrenUIHelpers";
import { useEvaluateChildrenUIContext } from "./EvaluateChildrenUIContext";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useToast, Icon } from "@chakra-ui/react";
import { IoReloadSharp } from "react-icons/io5";
import { RiSearchEyeLine } from "react-icons/ri";
import DatePicker, { registerLocale } from "react-datepicker";
import { th } from "date-fns/locale";
import moment from "moment";
import "moment/locale/th";

moment.locale("th");
registerLocale("th", th);

export function EvaluateChildrenTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const toast = useToast();

  const evaluateChildrenUIContext = useEvaluateChildrenUIContext();
  const evaluateChildrenUIProps = useMemo(() => {
    return {
      ids: evaluateChildrenUIContext.ids,
      queryParams: evaluateChildrenUIContext.queryParams,
      setQueryParams: evaluateChildrenUIContext.setQueryParams,
    };
  }, [evaluateChildrenUIContext]);

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.evaluateChildren,
    }),
    shallowEqual
  );

  const { totalCount, entities, actionsLoading, error } = currentState;

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState());
    }
  }, [error]);

  useEffect(() => {
    dispatch(
      actions.getEvaluateChildrenList(evaluateChildrenUIProps.queryParams, id)
    );
    console.log(evaluateChildrenUIProps.queryParams);
  }, [evaluateChildrenUIProps.queryParams]);

  const applyFilter = (values) => {
    const newQueryParams = {
      ...evaluateChildrenUIProps.queryParams,
      ...values,
    };
    if (!isEqual(newQueryParams, evaluateChildrenUIProps.queryParams)) {
      // newQueryParams.pageNumber = 1;
      evaluateChildrenUIProps.setQueryParams(newQueryParams);
    }
  };

  const onClickInfo = (date) => {
    history.push(`/evaluate-children/${id}/result?date=${date}`);
  };

  const columns = [
    {
      text: "วันที่",
      style: { width: "10%" },
      formatter: (cell, row, rowIndex) => {
        return moment(row.date)
          .add(543, "year")
          .format("DD/MM/YYYY");
      },
    },
    {
      headerAlign: "center",
      style: { display: "flex", justifyContent: "center" },
      text: "ผลภาวะโภชนาการ",
      formatter: (cell, row, rowIndex) => {
        return (
          <div
            className="py-2 px-4"
            style={{
              borderRadius: 5,
              backgroundColor: row.status != "-" ? "#D8EEBE" : "",
              color: row.status != "-" ? "#6B8A47" : "",
            }}
          >
            {row.status}
          </div>
        );
      },
    },
    {
      text: "ผลการประเมิน",
      formatter: ActionCell,
      formatExtraData: {
        onClickInfo: onClickInfo,
      },
      style: { width: "12%" },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: evaluateChildrenUIProps.queryParams.pageSize,
    page: evaluateChildrenUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  const formattedDate = (date) => {
    return moment(date)
      .add(543, "year")
      .format("MMMM YYYY");
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-5">
        <div>
          <div className="text-primary font-size-h5 font-weight-bolder">
            การประเมินผลภาวะโภชนาการ
          </div>
          <div className="text-f2">
            จากเกณฑ์ประเมินสารอาหารที่ได้รับจากการบริโภค
          </div>
        </div>
        <div className="d-flex">
          <DatePicker
            selected={evaluateChildrenUIProps.queryParams.START_DATE}
            onChange={(date) => applyFilter({ START_DATE: date })}
            locale={th}
            customInput={<CustomInputDateStart />}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              customHeaderCount,
              prevMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--previous"
                  }
                  style={
                    customHeaderCount === 1 ? { visibility: "hidden" } : null
                  }
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                    }
                  >
                    {"<"}
                  </span>
                </button>
                <span className="react-datepicker__current-month">
                  {formattedDate(date)}
                </span>
                <button
                  aria-label="Next Month"
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--next"
                  }
                  style={
                    customHeaderCount === 0 ? { visibility: "hidden" } : null
                  }
                  onClick={increaseMonth}
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                    }
                  >
                    {">"}
                  </span>
                </button>
              </div>
            )}
            dateFormat="dd MM yyyy"
          />
          <DatePicker
            selected={evaluateChildrenUIProps.queryParams.END_DATE}
            onChange={(date) => applyFilter({ END_DATE: date })}
            locale={th}
            minDate={evaluateChildrenUIProps.queryParams.START_DATE}
            customInput={<CustomInputDateEnd />}
            dateFormat="dd MM yyyy"
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              customHeaderCount,
              prevMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--previous"
                  }
                  style={
                    customHeaderCount === 1 ? { visibility: "hidden" } : null
                  }
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                    }
                  >
                    {"<"}
                  </span>
                </button>
                <span className="react-datepicker__current-month">
                  {formattedDate(date)}
                </span>
                <button
                  aria-label="Next Month"
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--next"
                  }
                  style={
                    customHeaderCount === 0 ? { visibility: "hidden" } : null
                  }
                  onClick={increaseMonth}
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                    }
                  >
                    {">"}
                  </span>
                </button>
              </div>
            )}
          />
          <div
            className="d-flex"
            style={{ alignItems: "center", cursor: "pointer" }}
            onClick={() =>
              applyFilter({ END_DATE: new Date(), START_DATE: new Date() })
            }
          >
            <Icon
              as={IoReloadSharp}
              style={{ color: "#979797", fontSize: 20 }}
            />
            <span
              className="ml-2"
              style={{ color: "#979797", fontSize: 15, userSelect: "none" }}
            >
              เคลียร์วันที่ที่เลือก
            </span>
          </div>
        </div>
      </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={actionsLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-vertical-center overflow-hidden text-dark font-size-xs font-weight-bolder"
                bodyClasses="text-dark font-size-xs font-weight-bolder"
                bootstrap4
                remote
                keyField="id"
                columns={columns}
                data={entities || []}
                onTableChange={getHandlerTableChange(
                  evaluateChildrenUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

function ActionCell(cellContent, row, rowIndex, { onClickInfo }) {
  return (
    <>
      <div className="d-flex">
        <button
          className="btn mx-2"
          onClick={() => onClickInfo(row.date)}
          style={{
            width: 45,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#979797",
          }}
        >
          <Icon as={RiSearchEyeLine} style={{ color: "white", fontSize: 20 }} />
        </button>
      </div>
    </>
  );
}

const CustomInputDateStart = forwardRef(
  ({ value, onClick, placeholder }, ref) => (
    <button
      className="d-flex align-items-center rounded mx-5"
      onClick={onClick}
      ref={ref}
      style={{
        border: "1px solid #6E6E6E",
        backgroundColor: "#F0F0F0",
        textAlign: "left",
      }}
    >
      <div className="font-weight-bolder px-5 py-1">
        <div style={{ color: "#6E6E6E", width: "180px" }}>
          เลือกวันที่เริ่มต้น
        </div>
        <div className="font-size-sm text-dark">
          {moment(value, "DD MM YYYY")
            .add(543, "year")
            .format("DD MMMM YYYY")}
        </div>
      </div>
      <div
        className="d-flex bg-primary"
        style={{
          minHeight: 50,
          width: 56,
          borderTopRightRadius: ".31rem",
          borderBottomRightRadius: ".31rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <i class="fa-regular fa-calendar text-white icon-lg"></i>
      </div>
    </button>
  )
);

const CustomInputDateEnd = forwardRef(({ value, onClick }, ref) => (
  <button
    className="d-flex align-items-center rounded mr-5"
    onClick={onClick}
    ref={ref}
    style={{
      border: "1px solid #6E6E6E",
      backgroundColor: "#F0F0F0",
      textAlign: "left",
    }}
  >
    <div className="font-weight-bolder px-5 py-1">
      <div style={{ color: "#6E6E6E", width: "180px" }}>เลือกวันที่สิ้นสุด</div>
      <div className="font-size-sm text-dark">
        {moment(value, "DD MM YYYY")
          .add(543, "year")
          .format("DD MMMM YYYY")}
      </div>
    </div>
    <div
      className="d-flex bg-primary"
      style={{
        minHeight: 50,
        width: 56,
        borderTopRightRadius: ".31rem",
        borderBottomRightRadius: ".31rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <i class="fa-regular fa-calendar text-white icon-lg"></i>
    </div>
  </button>
));

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-5">
    <span className="text-f2">แสดง</span> {from} - {to}{" "}
    <span className="text-f2">จาก</span> {size}
  </span>
);
