import React from "react";
import { Button } from "@chakra-ui/react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from "moment";

moment.locale("th");

export function ProfileInfo({
  editProfile,
  childrenProfile,
  childrenCenter,
  canEdit,
}) {
  const defaultImageGender =
    childrenProfile?.GENDER == "M" ? "/media/boy.jpg" : "/media/girl.jpg";

  return (
    <div className="d-flex">
      <div>
        <img
          src={toAbsoluteUrl(defaultImageGender)}
          style={{ height: 200, width: 200 }}
          alt=""
        />
      </div>
      <div className="ml-5">
        <p className="text-primary" style={{ fontSize: 18, fontWeight: 700 }}>
          {childrenProfile?.FIRSTNAME || "-"} {childrenProfile?.LASTNAME || "-"}
        </p>
        <div className="text-f2 d-flex" style={{marginTop: 5}}>
          <span>รหัสประจำตัว : {childrenProfile?.ID_CODE || "-"}</span>
        </div>
        <div className="text-f2">พื้นที่ : {childrenCenter?.NAME} </div>
        <div className="text-f2">
          วันเกิด :{" "}
          {moment(childrenProfile?.BIRTHDAY)
            .add(543, "year")
            .format("DD/MM/YYYY")}
             <span className="ml-5">
            เพศ :{" "}
            {childrenProfile?.GENDER == "M"
              ? "ชาย"
              : childrenProfile?.GENDER == "F"
              ? "หญิง"
              : "-"}
          </span>
        </div>
        {canEdit && (
          <Button
            onClick={editProfile}
            size="lg"
            style={{ color: "#ffff", backgroundColor: "#FEA73F", marginTop: 10 }}
          >
            แก้ไขประวัติสุขภาพเด็ก
          </Button>
        )}
      </div>
    </div>
  );
}
