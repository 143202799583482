import React from "react";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { Manual } from "../../../components/HomePage/Manual";
import { ContactUs } from "../../../components/ContactUs";
import { ABOUT, EXPERT } from "../../../../_mock/Home";
import Card from "../../../components/Card";
import { Divider } from "@chakra-ui/react";

export function AboutUs() {
  return (
    <div className="container-fluid p-0">
      <div className="bg-c3">
        <div>
          <div
            style={{
              height: 600,
              background: 'url("/media/home/Mask_group-1.png")',
              backgroundSize: "50% 100%",
              backgroundPositionX: "right",
              backgroundRepeat: "no-repeat",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div>
              <p style={{ fontSize: 30, fontWeight: 600 }}>บอกเล่าเรื่องราว</p>
              <p className="text-primary fw-bold" style={{ fontSize: 60 }}>
                เกี่ยวกับเรา
              </p>
            </div>
            <div></div>
          </div>
        </div>
        <div className="container mt-10">
          <div className="col-12 p-10">
            <div
              className="text-dark fw-medium text-center"
              style={{ fontSize: 30 }}
            >
              การพัฒนาระบบฐานข้อมูลภาวะโภชนาการ
            </div>
            <div
              className="fw-bold text-primary text-center"
              style={{ fontSize: 60 }}
            >
              “ของเด็กก่อนวัยเรียน”
            </div>
            <div
              className="fw-medium text-dark text-center mb-5"
              style={{ fontSize: 25 }}
            >
              ในศูนย์พัฒนาเด็กเล็กในพื้นที่ อำเภอแม่ฟ้าหลวง จังหวัดเชียงราย
            </div>
            <div
              className="fw-medium text-dark text-center"
              style={{ fontSize: 25 }}
            >
              Development of nutritional status data system among hill tribe
              pre-school children
            </div>
            <div
              className="fw-medium text-dark text-center"
              style={{ fontSize: 25 }}
            >
              In Mae Fah Luang district, Chiang Rai
            </div>
          </div>
        </div>
        <div className="container pt-10 pb-2">
          <p
            className="text-left text-primary fw-bold"
            style={{ fontSize: 25, fontWeight: 700 }}
          >
            ความเป็นมาของโครงการ
          </p>
          <div className="d-flex flex-wrap justify-content-center">
            <p
              className="text-dark"
              style={{ fontSize: 18, whiteSpace: "pre-wrap" }}
            >
              ปัญหาภาวะโภชนาการในเด็กก่อนวัยเรียนยังคงเป็นปัญหาสาธารณสุขของประเทศ
              และของทั่วโลกที่สำคัญ
              โดยเฉพาะปัญหาภาวะโภชนาการต่ำกว่าเกณฑ์หรือขาดสารอาหารซึ่งเป็นสาเหตุหลักต่อพัฒนาการด้านร่างกายและจิตใจ
              จากการสำรวจความชุกของโรค และปัจจัยเสี่ยงทางสุขภาพตามกลุ่มอายุ พบ
              ว่า เด็กไทยประมาณร้อยละ 4.1
              มีน้ำหนักต่ำกว่าเกณฑ์ซึ่งเสี่ยงต่อภาวะเชาว์ปัญญาต่ำ
              และส่งผลให้ผลผลิตมวลรวมประชาชาติของประเทศลดลงประมาณร้อยละ 3
              อีกทั้งยังมีผลต่อคุณภาพชีวิต
              และความสูญเสียทางเศรษฐกิจเนื่องจากเพิ่มค่าใช้จ่ายทางสุขภาพ
              และการสูญเสียสุขภาวะจาก ภาวะพิการ และการเสียชีวิตก่อนวัยอันควร
              จากประสบการณ์การลงพื้นที่ในการทำวิจัยที่ผ่านมาของคณะผู้วิจัย
              เรื่อง Experiences and expectations regarding COVID-19 prevention
              and control measures among the hill tribe population of northern
              Thailand: a qualitative study
              ในพื้นที่อำเภอแม่ฟ้าหลวงที่มีประชากรเป็นชาวเขา
              พบว่าทางพื้นที่มีปัญหาเรื่องภาวะทุพโภชนาการในเด็กก่อนวัยเรียนและทางพื้นที่มีความต้องการและความจำเป็นในการแก้ปัญหาเรื่องดังกล่าว
              การวิจัยชิ้นนี้ใช้วิธีการศึกษาทั้งเชิงคุณภาพและเชิงปริมาณ
              ควบคู่กันไป เพื่อประเมินภาวะโภชนาการ การประเมินอาหารบริโภค
              พฤติกรรมการบริโภคอาหารของเด็กก่อนวัยเรียนชาวเขาในศูนย์พัฒนาเด็กเล็กในพื้นที่เขตรับผิดชอบของโรงพยาบาลส่งเสริมสุขภาพตำบลเทอดไทย
              อำเภอแม่ฟ้าหลวง จังหวัดเชียงราย จำนวน 9 แห่ง
              ซึ่งเก็บข้อมูลในรูปแบบเชิงปริมาณ จำนวนทั้งสิ้น 341 คน
              ส่วนการศึกษาเชิงคุณภาพเป็นการสัมภาษณ์เชิงลึก
              และอภิปรายกลุ่มในเรื่องของพฤติกรรมการบริโภคอาหาร และปัจจัยต่างๆ
              ในบริบทของสังคมวัฒนธรรมชาวเขาที่มีการเปลี่ยนแปลงไปในสังคมยุคปัจจุบัน
              ที่มีผลต่อพฤติกรรมการบริโภคจากผุ้เกี่ยวข้อง ได้แก่ ครูผู้ดูแล
              ผู้ปกครองเด็กวัยก่อนเรียน เจ้าหน้าที่ทางด้านสาธารณสุข
              และเจ้าหน้าที่องค์การบริหารส่วนท้องถิ่น
              เมื่อได้ข้อมูลทั้งหมดจะนำมาวิเคราะห์และสร้างระบบฐานข้อมูลภาวะโภชนาการของเด็กกลุ่มนี้
              โดยเป็นเว็บไซต์ที่สามารถเก็บข้อมูลและประมวลผลด้านภาวะโภชนาการเป็นรายบุคคล
              โดยใช้ระยะเวลาดำเนินการทั้งสิ้น 1 ปี
              ผู้ใช้ประกอบด้วยเจ้าหน้าที่สาธารณสุขที่รับผิดชอบ
              ครูของศูนย์พัฒนาเด็กเล็ก
              และเจ้าหน้าที่องค์การบริหารส่วนตำบลที่รับผิดชอบ
              สามารถล็อกอินและเชื่อมโยงลิงค์หากันได้
              ซึ่งเว็บไซต์ระบบฐานข้อมูลดังกล่าวสามารถที่จะระบุได้ว่าแต่ละบุคคลมีภาวะโภชนาการอย่างไร
              รวมถึงพัฒนาการทางด้านร่างกาย และข้อแนะนำในการแก้ไขปัญหาสุขภาพ
              อันจะเป็นประโยชน์ต่อการพัฒนาระบบบริการสุขภาพที่เป็นความร่วมมือของทุกภาคส่วนที่เกี่ยวข้อง
              เพื่อพัฒนาคุณภาพชีวิตของกลุ่มประชากรเปราะบางกลุ่มนี้ต่อไป
            </p>
          </div>
        </div>
        <div className="container pt-5 pb-10">
          {ABOUT.map((item, index) => (
            <div className="row">
              <div className="col-md-6 col-12">
                {index % 2 == 0 ? (
                  <div className="d-flex" style={{ height: 400 }}>
                    <img
                      src={item.img}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        borderRadius: 5,
                      }}
                      alt="img"
                    />
                  </div>
                ) : (
                  <div
                    className="d-flex flex-column"
                    style={{ justifyContent: "center", height: "100%" }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ justifyContent: "center", height: "100%" }}
                    >
                      {item.detail.map((key) => (
                        <div className="mb-5">
                          <p
                            className="text-primary mb-5"
                            style={{ fontSize: 25, fontWeight: 700 }}
                          >
                            {key.title}
                          </p>
                          <p
                            className="text-dark"
                            style={{ fontSize: 18, whiteSpace: "pre-wrap" }}
                          >
                            {key.detail}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-6 col-12">
                {index % 2 == 0 ? (
                  <div
                    className="d-flex flex-column"
                    style={{ justifyContent: "center", height: "100%" }}
                  >
                    {item.detail.map((key) => (
                      <div className="mb-5">
                        <p
                          className="text-primary mb-5"
                          style={{ fontSize: 25, fontWeight: 700 }}
                        >
                          {key.title}
                        </p>
                        <p
                          className="text-dark"
                          style={{ fontSize: 16, whiteSpace: "pre-wrap" }}
                        >
                          {key.detail}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="d-flex" style={{ height: 400 }}>
                    <img
                      src={item.img}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        borderRadius: 5,
                      }}
                      alt="img"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div>
          <Manual />
        </div>
        <div className="bg-white pb-20">
          <div className="container">
            <Divider />
            <p
              className="text-center text-primary fw-bold my-10"
              style={{ fontSize: 40 }}
            >
              รายชื่อทีมนักวิจัย
            </p>
            <div className="d-flex flex-wrap justify-content-center">
              {EXPERT.map((item) => (
                <Card
                  name={item.name}
                  img={item.img}
                  position={item.position}
                  className="mr-5 mb-5 shadow"
                />
              ))}
            </div>
          </div>
        </div>
        {/* <div>
          <ContactUs />
        </div> */}
      </div>
    </div>
  );
}
