import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export function DeleteSuccessDialog({show, onHide}) {
  const history = useHistory()

  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide()}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
        <div className="d-flex justify-content-center align-items-center"
          style={{
            height: 500,
          }}>
            <div>
              <div className="d-flex justify-content-center align-items-center my-8" >
                <span className="navi-icon"><i class="fa fa-check-circle icon-8x" style={{color: "#00BA00"}}></i></span>
              </div>
              <div className="text-primary font-weight-boldest font-size-h3 text-center mt-3">ลบบัญชีผู้ใช้งาน สำเร็จ!</div>
              <div className="text-center font-size-sm text-dark font-weight-bolder">
                คุณได้ทำการลบบัญชีสำเร็จแล้ว 
              </div>
              <div className="d-flex justify-content-center align-items-center" style={{marginBottom: "5rem", marginTop: "3rem"}}>
                <button type="button" className="btn btn-primary font-size-sm" onClick={()=> onHide()}>ปิด</button>
              </div>
            </div>
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
