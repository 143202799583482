import React, { useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { DatePicker } from "../../../components/DatePicker";
import { RadioCheck } from "../../../components/RadioCheck";
import { useChildrensProfileUIContext } from "./ChildrensProfileUIContext";
import * as actions from "../_redux/ChildrensProfileActions";
import { useToast } from "@chakra-ui/react";
import moment from "moment"

export function EditProfileDialog({ show, onHide }) {
  const { childrenId } = useParams();
  const dispatch = useDispatch();
  const childrensProfileUIContext = useChildrensProfileUIContext();
  const childrensProfileUIProps = useMemo(() => {
    return {
      ids: childrensProfileUIContext.ids,
      queryParams: childrensProfileUIContext.queryParams,
      setQueryParams: childrensProfileUIContext.setQueryParams,
    };
  }, [childrensProfileUIContext]);

  const toast = useToast();

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.childrensProfile,
    }),
    shallowEqual
  );

  const { childrenProfile, actionsLoading, error } = currentState;

  const [initialValues, setInitialValues] = useState({
    ID: null,
    CHILDREN_CENTER_ID: null,
    ID_CODE: null,
    FIRSTNAME: null,
    LASTNAME: null,
    BIRTHDAY: null,
    GENDER: null,
    USED: "Y",
    CHILDREN_PROFILE_ID: null,
    WEIGHT: null,
    HEIGHT: null,
    HISTORYID: null, // แทน ID จาก service history
  });

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState())
    }
  }, [error]);

  useEffect(() => {
    if (childrenId) {
      dispatch(actions.getChildrenById(childrenId));
    }
  }, [childrenId]);

  useEffect(() => {
    if (childrenProfile) {
      setInitialValues(childrenProfile)
    }
  }, [childrenProfile]);

  const EditSchema = Yup.object().shape({
    ID_CODE: Yup.string()
      .nullable()
      .trim()
      .required("กรุณากรอกรหัสประจำตัว"),
    FIRSTNAME: Yup.string()
      .nullable()
      .required("กรุณากรอกชื่อ")
      .matches(/^[ก-๙]+$/gi, "กรุณากรอกตัวอักษรภาษาไทยเท่านั้น"),
    LASTNAME: Yup.string()
      .nullable()
      .required("กรุณากรอกนามสกุล")
      .matches(/^[ก-๙]+$/gi, "กรุณากรอกตัวอักษรภาษาไทยเท่านั้น"),
    BIRTHDAY: Yup.date()
      .nullable()
      .required("กรุณาเลือกวันเกิด"),
    GENDER: Yup.string()
      .nullable()
      .required("กรุณาเลือกเพศ"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: EditSchema,
    onSubmit: (values) => {
      console.log(`submit`);
      console.log(values);

      dispatch(
        actions.editChildren(values)
      ).then(() => {
        toast({
          title: "แก้ไขประวัติข้อมูลสุขภาพเด็ก สำเร็จ!",
          description: "คุณได้ทำการแก้ไขประวัติข้อมูลสุขภาพเด็กสำเร็จ",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onHide();
      });
    },
  });

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        onHide={onHide}
        size="lg"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between w-100 pb-5 border-bottom">
            <div className="text-primary font-weight-boldest font-size-h5">
              แก้ไขประวัติข้อมูลเด็ก
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="container-fluid p-5 d-flex flex-column font-weight-bolder"
          >
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark ">
                <div className="required">รหัสประจำตัว</div>
              </div>
              <div className="col px-0">
                <input
                  type="text"
                  className={`form-control ${
                    formik.touched.ID_CODE && formik.errors.ID_CODE
                      ? "border-danger"
                      : "border-f2"
                  } font-size-sm mr-1 text-dark`}
                  name="ID_CODE"
                  style={{ backgroundColor: "transparent" }}
                  {...formik.getFieldProps("ID_CODE")}
                />
                {formik.touched.ID_CODE && formik.errors.ID_CODE && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.ID_CODE}
                  </span>
                )}
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark">
                <div className="required">ชื่อ-นามสกุล</div>
              </div>
              <div
                className="col px-0 d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ width: "49%" }}>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.FIRSTNAME && formik.errors.FIRSTNAME
                        ? "border-danger"
                        : "border-f2"
                    } font-size-sm mr-1 text-dark`}
                    name="FIRSTNAME"
                    style={{ backgroundColor: "transparent", fontSize: "1rem" }}
                    {...formik.getFieldProps("FIRSTNAME")}
                  />
                  {formik.touched.FIRSTNAME && formik.errors.FIRSTNAME && (
                    <span
                      className="text-danger"
                      style={{ fontWeight: "normal" }}
                    >
                      {formik.errors.FIRSTNAME}
                    </span>
                  )}
                </div>
                <div style={{ width: "49%" }}>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.LASTNAME && formik.errors.LASTNAME
                        ? "border-danger"
                        : "border-f2"
                    } font-size-sm text-dark`}
                    name="LASTNAME"
                    style={{ backgroundColor: "transparent", fontSize: "1rem" }}
                    {...formik.getFieldProps("LASTNAME")}
                  />
                  {formik.touched.LASTNAME && formik.errors.LASTNAME && (
                    <span
                      className="text-danger"
                      style={{ fontWeight: "normal" }}
                    >
                      {formik.errors.LASTNAME}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark ">
                <div className="required">วัน/เดือน/ปี พ.ศ.เกิด</div>
              </div>
              <div className="col px-0">
                <DatePicker
                  DateStyle={{ backgroundColor: "transparent" }}
                  className={`${
                    formik.touched.BIRTHDAY && formik.errors.BIRTHDAY
                      ? "border-danger"
                      : "border-f2"
                  }`}
                  onFocus={() =>
                    formik.setTouched({ ...formik.touched, ["BIRTHDAY"]: true })
                  }
                  placeholder={"เลือก วัน/เดือน/ปี พ.ศ.เกิด"}
                  name={"BIRTHDAY"}
                  onChange={(e) => {
                    formik.setFieldValue('BIRTHDAY', moment(e).format('YYYY-MM-DD'))
                  }}
                  value={formik.values.BIRTHDAY}
                 type="dropdown"
                />
                {formik.touched.BIRTHDAY && formik.errors.BIRTHDAY && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.BIRTHDAY}
                  </span>
                )}
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark">
                <div className="required">เพศ</div>
              </div>
              <div className="col px-0">
                <div className="d-flex">
                  <div>
                    <RadioCheck
                      label={"ชาย"}
                      name="GENDER"
                      value={"M"}
                      checked={formik.values.GENDER == "M"}
                      onFocus={() =>
                        formik.setTouched({
                          ...formik.touched,
                          ["GENDER"]: true,
                        })
                      }
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="ml-10">
                    <RadioCheck
                      label={"หญิง"}
                      name="GENDER"
                      value={"F"}
                      checked={formik.values.GENDER == "F"}
                      onFocus={() =>
                        formik.setTouched({
                          ...formik.touched,
                          ["GENDER"]: true,
                        })
                      }
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                {formik.touched.GENDER && formik.errors.GENDER && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.GENDER}
                  </span>
                )}
              </div>
            </div>
          </form>
          <div
            className="d-flex align-items-center py-5 border-top"
            style={{ justifyContent: "space-between" }}
          >
            <button
              type="button"
              className="btn font-size-sm fw-bold"
              onClick={() => onHide()}
              style={{ backgroundColor: "#E0DEDE" }}
            >
              ยกเลิก
            </button>
            <button
              type="button"
              className="btn btn-primary font-size-sm fw-bold"
              disabled={!formik.isValid || actionsLoading}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {actionsLoading && (
                <span
                  className="spinner-border spinner-border-sm mr-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              บันทึก
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
