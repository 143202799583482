import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
// import { Brand } from "../Layout/Brand";
import { Brand } from "./Brand";
import { Button } from "@chakra-ui/react";
import { IoPersonCircleSharp } from "react-icons/io5";
export function HeaderBar({ showModal }) {
  const history = useHistory();

  const onClickLogin = () => {
    showModal('modalLogin')
  };

  const onClickAboutUs = () => {
    history.push("/home/about");
  };

  const onClickHomePage = () => {
    history.push("/home");
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="d-flex justify-content-center">
          <Brand />
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <button
            type="button"
            className="btn btn-link text-white font-size-h6 mx-3"
            onClick={() => onClickHomePage()}
          >
            หน้าแรก
          </button>
          <button
            type="button"
            className="btn btn-link text-white font-size-h6 mx-3"
            onClick={() => onClickAboutUs()}
          >
            เกี่ยวกับเรา
          </button>
          <Button
            height={"40px"}
            className="bg-secondary text-white text-f1 font-size-h6"
            onClick={() => onClickLogin()}
            rightIcon={<IoPersonCircleSharp />}
          >
            เข้าสู่ระบบ
          </Button>
        </div>
      </div>
    </div>
  );
}
