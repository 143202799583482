/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { Route, Switch } from "react-router-dom";
import { SuccessDialog } from "../../../components/ResetPage/SuccessDialog";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { resetPassword } from "../_redux/authCrud";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import usePasswordToggle from "../../../components/Layout/usePasswordToggle";

const initialValues = {
  password: "",
  confirm_password: "",
};

export function ResetPass() {
  const [PasswordInputType1, ToggleIcon1] = usePasswordToggle();
  const [PasswordInputType2, ToggleIcon2] = usePasswordToggle();
  const history = useHistory();
  const location = useLocation().search;
  const token = new URLSearchParams(location).get("token");

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const ResetSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, " ")
      .max(16, " ")
      .required(t("VALIDATION.REQUIRED", { field: "Password" })),
    confirm_password: Yup.string()
      .required(t("VALIDATION.REQUIRED", { field: "Confirm password" }))
      .oneOf([Yup.ref("password"), null], " "),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ResetSchema,
    onSubmit: (values) => {
      setLoading(true);
      const payload = {
        password: values.password,
      };
      resetPassword(payload, token)
        .then((res) => {
          history.push("/reset_password/success");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const onHide = () => {
    history.push("/home?login");
  };

  return (
    <>
      <Switch>
        <Route path="/reset_password/success">
          {({ history, match }) => (
            <SuccessDialog show={match != null} onHide={onHide} />
          )}
        </Route>
      </Switch>
      <div className="container-fluid p-0  h-100 bg-light-white">
        <div className="container-fluid p-0 row  h-100">
          <div className="col-5 bg-primary p-0">
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <div>
                <p
                  className="text-white font-weight-bold text-center"
                  style={{ fontSize: 25 }}
                >
                  ระบบฐานข้อมูลภาวะโภชนาการ
                </p>
                <p
                  className="text-white fw-bold text-center"
                  style={{ fontSize: 40 }}
                >
                  “ของเด็กวัยก่อนเรียน”
                </p>
                <p
                  className="text-white font-weight-bold text-center"
                  style={{ fontSize: 20 }}
                >
                  ในศูนย์พัฒนาเด็กเล็กในพื้นที่ อำเภอแม่ฟ้าหลวง จังหวัดเชียงราย
                </p>
              </div>
              <div style={{ widht: "100%" }}>
                <img
                  src={`${toAbsoluteUrl("/media/home/Frame_415.png")}`}
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-7 d-flex justify-content-center align-items-center">
            <div className="w-50">
              <div className="container-fluid p-0 bg-light-white">
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <div className="w-100">
                    <div className="text-primary font-size-h3 font-weight-boldest">
                      รีเซ็ตรหัสผ่าน
                    </div>
                    <div className="font-size-sm text-dark font-weight-bolder">
                      กรอกรหัสผ่านใหม่ของคุณ
                    </div>
                    <form
                      onSubmit={formik.handleSubmit}
                      className="form fv-plugins-bootstrap fv-plugins-framework my-5 "
                    >
                      <div className="form-group fv-plugins-icon-container">
                        <div className="d-flex justify-content-between">
                          <div className="font-size-xs font-weight-bolder">
                            รหัสผ่านใหม่
                          </div>
                          <div className="font-size-xs">8 - 16 ตัวอักษร</div>
                        </div>
                        <div className="input-block">
                          <input
                            placeholder="Password"
                            type={PasswordInputType1}
                            className={`form-control form-control-solid font-size-xs h-auto py-5 px-6
                      ${
                        formik.touched.password && formik.errors.password
                          ? "border-danger"
                          : ""
                      }`}
                            name="password"
                            {...formik.getFieldProps("password")}
                          />
                          <span
                            className="password-toogle-icon "
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "10px",
                              transform: "translate(0, -50%)",
                              zIndex: 1000,
                              cursor: "pointer",
                            }}
                          >
                            {ToggleIcon1}
                          </span>
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.password}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <div className="font-size-xs font-weight-bolder">
                          ยืนยันรหัสผ่านใหม่
                        </div>
                        <div className="input-block">
                          <input
                            placeholder="Confirm password"
                            type={PasswordInputType2}
                            className={`form-control form-control-solid font-size-xs h-auto py-5 px-6
                      ${
                        formik.touched.confirm_password &&
                        formik.errors.confirm_password
                          ? "border-danger"
                          : ""
                      }`}
                            name="confirm_password"
                            {...formik.getFieldProps("confirm_password")}
                          />
                          <span
                            className="password-toogle-icon "
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "10px",
                              transform: "translate(0, -50%)",
                              zIndex: 1000,
                              cursor: "pointer",
                            }}
                          >
                            {ToggleIcon2}
                          </span>
                        </div>
                        {formik.touched.confirm_password &&
                        formik.errors.confirm_password ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.confirm_password}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="d-flex align-items-center">
                        <button
                          type="submit"
                          disabled={loading}
                          className={`btn btn-primary font-size-xs d-flex align-items-center justify-content-center`}
                          style={{ width: "8rem" }}
                        >
                          ยืนยัน
                          {loading && (
                            <span
                              className="spinner-border spinner-border-sm ml-3"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
