import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  stateAction: null,
  entities: null,
  error: null,
  childrenCenter: [],
  dashboardData: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    catchError: (state, action) => {
      state.error =
        action.payload.error.status >= 500
          ? "กรุณาติดต่อผู้ดูแลระบบ"
          : action.payload.error?.response?.data?.message;
      state.stateAction = "error";
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    stopCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    clearError: (state, action) => {
      state.error = null;
    },
    startCall: (state, action) => {
      state.error = null;
      state.stateAction = "wait";
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    setChildrenCenter: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.childrenCenter = action.payload;
    },
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload;
      state.actionsLoading = false;
      state.error = null;
    },
  },
});
