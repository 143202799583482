import React from "react";

export function Select({
  item = [],
  value,
  onChange,
  disabled,
  className,
  SelectStyle,
  name,
  onFocus,
  labelObject,
  valueObject,
  placeholder,
  ...props
}) {
  return (
    <div className="select" {...props}>
      <select
        onFocus={onFocus}
        disabled={disabled}
        onChange={onChange}
        value={value}
        name={name}
        className={`mySelectArrow ${className}`}
        style={{
          ...SelectStyle,
          backgroundColor: disabled ? "#F2F3EE" : "transparent",
          borderColor: disabled ? '#979797' : "",
        }}
        placeholder={placeholder}
      >
        {item.map((option) => (
          <option key={option[labelObject]} value={option[valueObject]}>
            {option[labelObject]}
          </option>
        ))}
      </select>
    </div>
  );
}
