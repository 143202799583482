/* eslint-disable no-unused-vars */
import React from "react";
import { PaginationTotalStandalone } from "react-bootstrap-table2-paginator";
import { Select } from "../../../../app/components/Select";
export function PaginationToolbar(props) {
  const { isLoading, paginationProps } = props;
  const {
    sizePerPageList,
    sizePerPage,
    totalSize,
    onSizePerPageChange = [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 },
    ],
  } = paginationProps;
  const style = {
    width: "75px",
  };

  const onSizeChange = (event) => {
    const newSize = +event.target.value;
    onSizePerPageChange(newSize);
  };

  return (
    <div className="d-flex align-items-center py-3" style={{ flex: 1 }}>
      {isLoading && (
        <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div>
      )}
      <div style={{ width: 100 }}>
        <Select
          SelectStyle={{ height: 40 }}
          disabled={totalSize === 0}
          onChange={onSizeChange}
          value={sizePerPage}
          item={sizePerPageList}
          labelObject={'text'}
          valueObject={'page'}
        />
      </div>

      <PaginationTotalStandalone className="text-muted" {...paginationProps} />
    </div>
  );
}
