import axios from "axios";
import * as config from "../../../../config";

const { API_ENDPOINT } = config;

export function getFood() {
  return axios.get(`${API_ENDPOINT}/masterdata/foodmenu`);
}

export function getMealTimeByChildren(date, childrenId) {
  return axios.get(
    `${API_ENDPOINT}/childrenFood/mealtime?childrenProfileId=${childrenId}&date=${date}`
  );
}

export function getFoodDetail(date, childrenId) {
  return axios.get(
    `${API_ENDPOINT}/childrenFood/detail?childrenProfileId=${childrenId}&date=${date}`
  );
}

export function masterDataMealTime() {
  return axios.get(`${API_ENDPOINT}/masterdata/mealTime`)
}

export function getMealTime() {
  return axios.get(`${API_ENDPOINT}/masterdata/mealTime`);
}

export function getInfoChildrenById(id) {
  return axios.get(`${API_ENDPOINT}/children/info?childrenProfileId=${id}`);
}

export function postChildrenFood(payload) {
  return axios.post(`${API_ENDPOINT}/childrenFood/`, payload)
}