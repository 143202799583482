import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import * as actions from "../_redux/NutritionFoodEditActions";
import {
  Button,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Checkbox,
  CheckboxGroup,
  Box,
  Input,
  useNumberInput,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { DatePicker } from "../../../components/DatePicker";
import { Select } from "../../../components/Select";
import moment from "moment";

function CustomNumberInput({
  step,
  defaultValue,
  min,
  onChangeValue,
  disabled,
}) {
  const {
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
  } = useNumberInput({
    step: step,
    value: defaultValue,
    min: min,
    onChange: (valueString) => {
      onChangeValue(valueString);
    },
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <div className="d-flex" style={{ alignItems: "center", height: "100%" }}>
      <Button
        {...dec}
        style={{
          borderRadius: 50,
          backgroundColor: "#E00000",
          color: "#ffffff",
          fontWeight: 600,
          border: "1px solid #979797",
        }}
        disabled={!disabled}
      >
        -
      </Button>
      <Input
        {...input}
        className="mx-3 text-center"
        style={{ border: "1px solid #979797" }}
        disabled={!disabled}
        isInvalid={defaultValue == 0 && disabled ? true : false}
      />
      <Button
        {...inc}
        style={{
          borderRadius: 50,
          backgroundColor: "#FEA73F",
          color: "#ffffff",
          fontWeight: 600,
          border: "1px solid #979797",
        }}
        disabled={!disabled}
      >
        +
      </Button>
    </div>
  );
}

export function NutritionFoodEditPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();
  const { id, childrenId } = useParams();
  const [isSubmit, setIsSubmit] = useState(false);
  const [initialState, setInitialState] = useState({
    ID: null,
    MEAL_TIME_ID: null,
    CHILDREN_PROFILE_ID: childrenId,
    DATE: null,
    CHILDREN_FOOD_INFO_DETAIL: [],
  });

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.nutritionFoodEdit,
    }),
    shallowEqual
  );

  const {
    actionsLoading,
    childrenInfo,
    error,
    mealTime,
    food,
    foodDetail,
  } = currentState;

  const [menuSelect, setMenuSelect] = useState([]); // ใช้เก็บเฉพาะ id ของ menu

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState());
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      if (initialState.DATE) {
        dispatch(actions.getFoodEdit(initialState.DATE, childrenId));
      }
      
    } else {
      console.log(`create`);
      dispatch(actions.getFood(initialState.DATE, childrenId));
    }
  }, [initialState.DATE]);

  useEffect(() => {
    // work on edit
    if (foodDetail.length > 0) {
      const food = foodDetail.find((item) => item.MEAL_TIME == id);

      if (food) {
        const CHILDREN_FOOD_INFO_DETAIL = [];

        let payload = { ...initialState };
        food.value.map((item) => {
          CHILDREN_FOOD_INFO_DETAIL.push({
            FOOD_MENU_ID: item.FOOD_MENU_ID,
            UNIT: item.UNIT,
          });
        });

        payload = {
          ...payload,
          ID: food.ID,
          MEAL_TIME_ID: food.MEAL_TIME,
          CHILDREN_PROFILE_ID: childrenId,
          CHILDREN_FOOD_INFO_DETAIL: CHILDREN_FOOD_INFO_DETAIL,
        };
       
        setInitialState(payload);
      }
    }
  }, [foodDetail]);

  useEffect(() => {
    if (initialState.CHILDREN_FOOD_INFO_DETAIL.length > 0) {
      const arr = [];
      initialState.CHILDREN_FOOD_INFO_DETAIL.map((item) => {
        arr.push(`${item.FOOD_MENU_ID}`);
      });

      setMenuSelect(arr);
    }
  }, [initialState.CHILDREN_FOOD_INFO_DETAIL]);

  const onChangeMenu = (e) => {
    setMenuSelect(e);
    const arr = [];
    e.map((item) => {
      const findMenu = initialState.CHILDREN_FOOD_INFO_DETAIL.find(
        (menuId) => menuId.FOOD_MENU_ID == item
      );

      const payload = {
        FOOD_MENU_ID: parseInt(item),
        UNIT: findMenu?.UNIT || 1,
      };
      arr.push(payload);
    });
    setInitialState({
      ...initialState,
      CHILDREN_FOOD_INFO_DETAIL: arr,
    });
  };

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const param = searchParams.get("date");
      setInitialState({ ...initialState, DATE: param });
    }
  }, [location]);

  const onBack = () => {
    history.goBack();
  };

  const onChange = (name, value) => {
    let findMenu = initialState.CHILDREN_FOOD_INFO_DETAIL.find(
      (item) => item.FOOD_MENU_ID == name
    );

    findMenu = {
      ...findMenu,
      UNIT: value,
    };

    let CHILDREN_FOOD_INFO_DETAIL = [...initialState.CHILDREN_FOOD_INFO_DETAIL];
    CHILDREN_FOOD_INFO_DETAIL = CHILDREN_FOOD_INFO_DETAIL.map((item) => {
      if (item.FOOD_MENU_ID == name) {
        return {
          ...item,
          UNIT: parseInt(value),
        };
      } else {
        return item;
      }
    });

    setInitialState({ ...initialState, CHILDREN_FOOD_INFO_DETAIL });
  };

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (
      initialState.DATE != null &&
      initialState.MEAL_TIME_ID != null &&
      initialState.CHILDREN_FOOD_INFO_DETAIL.length != 0 &&
      initialState.CHILDREN_FOOD_INFO_DETAIL.every((item) => item.UNIT != 0)
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [initialState]);

  const onSubmit = () => {
    setIsSubmit(true);
    if (
      initialState.DATE != null &&
      initialState.MEAL_TIME_ID != null &&
      initialState.CHILDREN_FOOD_INFO_DETAIL.length != 0 &&
      initialState.CHILDREN_FOOD_INFO_DETAIL.every((item) => item.UNIT != 0)
    ) {
      let payload = {
        ...initialState,
        CHILDREN_PROFILE_ID: parseInt(initialState.CHILDREN_PROFILE_ID),
        MEAL_TIME_ID: parseInt(initialState.MEAL_TIME_ID),
      };
      dispatch(actions.postChildrenDetail(payload)).then(() => {
        toast({
          title: `${id ? "แก้ไข" : "เพิ่ม"}ข้อมูลโภชนาการ สำเร็จ!`,
          description: `คุณได้ทำการ${
            id ? "แก่ไข" : "เพิ่ม"
          }ข้อมูลโภชนาการสำเร็จ`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.goBack();
      });
    } else {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <div className="container-fluid gb-light-white p-10 mt-5 shadow-sm rounded">
        <div className="d-flex justify-content-between mb-5">
          <div>
            <div
              className="font-weight-bolder text-primary"
              style={{ fontSize: 18 }}
            >
              {id ? "แก้ไข" : "เพิ่ม"}ข้อมูลโภชนาการ
            </div>
            <div
              className="text-f2 font-weight-bolder"
              style={{ fontSize: 14 }}
            >
              {childrenInfo?.CHILDREN.FIRSTNAME}{" "}
              {childrenInfo?.CHILDREN.LASTNAME}
            </div>
          </div>
        </div>
        <div className="mb-5">
          <Divider />
        </div>
        <div className="d-flex">
          <div
            className="d-flex pr-5"
            style={{ alignItems: "center", width: "25%" }}
          >
            <div style={{ minWidth: 40 }} className="required">
              วันที่
            </div>
            <DatePicker
              DateStyle={{ backgroundColor: "transparent" }}
              className={`${
                isSubmit && !initialState.DATE ? "border-danger" : "border-f2"
              }`}
              placeholder={"เลือกวันที่"}
              name={"DATE"}
              disabled={location.search}
              value={initialState.DATE}
              onChange={(date) =>
                setInitialState({ ...initialState, DATE: moment(date).format('YYYY-MM-DD') })
              }
            />
          </div>
          <div
            className="d-flex"
            style={{ alignItems: "center", width: "30%" }}
          >
            <div style={{ minWidth: 70 }} className="required">
              มื้ออาหาร
            </div>
            <Select
              item={mealTime}
              labelObject={"MEAL_TIME"}
              valueObject={"ID"}
              value={initialState.MEAL_TIME_ID}
              className={`${
                isSubmit && !initialState.MEAL_TIME_ID
                  ? "border-danger"
                  : "border-f2"
              }`}
              placeholder={"เลือกมื้ออาหาร"}
              disabled={initialState.DATE == null || id}
              onChange={(e) =>
                setInitialState({
                  ...initialState,
                  MEAL_TIME_ID: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Accordion
          allowMultiple
          style={{ boxShadow: "none" }}
          defaultIndex={[0]}
        >
          <CheckboxGroup size="lg" onChange={onChangeMenu} value={menuSelect}>
            {food.map((item, index) => (
              <AccordionItem
                borderColor="transparent"
                className="mb-5"
                key={index}
                style={{ border: "none", boxShadow: "none" }}
              >
                {({ isExpanded }) => (
                  <div className="bg-light-white shadow-sm rounded">
                    <h2>
                      <AccordionButton p={10}>
                        <Box flex="1" textAlign="left">
                          <div className="text-f2">
                            เลือกหมวดหมู่อาหารที่รับประทาน
                          </div>
                          <div
                            className="text-primary mt-2"
                            style={{ fontSize: 18, fontWeight: 500 }}
                          >
                            {item.GROUP_MENU}
                          </div>
                        </Box>
                        {isExpanded ? (
                          <Button
                            size={"lg"}
                            className="text-white"
                            style={{ backgroundColor: "#FEA73F" }}
                          >
                            ย่อ
                          </Button>
                        ) : (
                          <Button
                            size={"lg"}
                            className="text-white"
                            style={{ backgroundColor: "#FEA73F" }}
                          >
                            ขยาย
                          </Button>
                        )}
                      </AccordionButton>
                    </h2>
                    {isExpanded && (
                      <div className="container-fluid">
                        <Divider />
                      </div>
                    )}

                    <AccordionPanel p={10}>
                      <div className="row mb-5">
                        <div className="col-10">
                          <div className="required">เมนูอาหารที่รับประทาน</div>
                        </div>
                        <div className="col-2 text-center">
                          <div>จำนวน</div>
                        </div>
                      </div>

                      {item.DATA.map((menuItem, index) => (
                        <div className="row" key={index}>
                          <div className="col-10">
                            <Checkbox value={`${menuItem.ID}`}>
                              <div
                                className="d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <img
                                  src={menuItem.PICTURE}
                                  alt=""
                                  style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 5,
                                    objectFit: "cover",
                                  }}
                                />
                                <div className="ml-2">
                                  <div
                                    className="d-flex"
                                    style={{ alignItems: "center" }}
                                  >
                                    <span style={{ fontWeight: 600 }}>
                                      {menuItem.NAME}
                                    </span>
                                  </div>
                                  <span style={{ color: "#58A3BC" }}>
                                    ({menuItem.UNIT} {menuItem.UNIT_TEXT})
                                  </span>
                                </div>
                              </div>
                            </Checkbox>
                          </div>
                          <div className="col-2 text-center">
                            <CustomNumberInput
                              step={1}
                              defaultValue={
                                initialState.CHILDREN_FOOD_INFO_DETAIL
                                  ? initialState.CHILDREN_FOOD_INFO_DETAIL.find(
                                      (item) => item.FOOD_MENU_ID == menuItem.ID
                                    )?.UNIT || 0
                                  : 0
                              }
                              min={1}
                              disabled={menuSelect.some(
                                (item) => item == menuItem.ID
                              )}
                              id={menuItem.ID}
                              onChangeValue={(value) =>
                                onChange(menuItem.ID, value)
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </AccordionPanel>
                  </div>
                )}
              </AccordionItem>
            ))}
          </CheckboxGroup>
        </Accordion>
      </div>
      <div>
        <Divider />
      </div>
      <div className="my-5 d-flex" style={{ justifyContent: "space-between" }}>
        <Button
          size={"lg"}
          className="text-dark mr-3"
          style={{ backgroundColor: "#E0DEDE" }}
          onClick={onBack}
        >
          ยกเลิก
        </Button>
        <Button
          size={"lg"}
          className="text-white"
          style={{ backgroundColor: "#28527A" }}
          onClick={onSubmit}
          isLoading={actionsLoading}
          disabled={isValid || actionsLoading}
          loadingText={"กำลังบันทึก"}
        >
          บันทึก
        </Button>
      </div>
    </>
  );
}
