/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import {
  Redirect,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
// import { Layout } from "../_metronic/layout";
import { Layout } from "../app/components/Layout/Layout";
import { LayoutAuth } from "./components/LayoutAuth";
import BasePage from "./BasePage";
import { Logout, AuthPage, AuthIndex } from "./modules/Auth";
import { ResetPass } from "./modules/Auth/pages/ResetPass";
import * as actions from "../redux/general/generalAction";

export function Routes() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");
    if (splashScreen) {
      // if (process.env.NODE_ENV !== "development") {
      //   if (isAuthorized) {
      //     history.push("/dashboard");
      //   }
      // }
      Promise.all([
        dispatch(actions.getLocation()),
        dispatch(actions.getDepartment(), dispatch(actions.getRole())),
      ]).then(() => {
        splashScreen.classList.add("hidden");
      });
      // dispatch(actions.getLocation()).then(() => {
      //   splashScreen.classList.add("hidden");
      // });
    }
  }, []);

  return (
    <Switch>
      {!isAuthorized ? (
        <>
          <RedirectsCustom />
          <Route path="/home">
            <LayoutAuth>
              <AuthIndex />
            </LayoutAuth>
          </Route>
          <Route path="/reset_password" component={ResetPass} />
        </>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      {/* <Route path="/error" component={ErrorsPage} /> */}
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        <Redirect to="/home" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}

const RedirectsCustom = () => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  return location.pathname.includes("/reset_password") ? (
    <Redirect
      from="/"
      to={token ? `/reset_password?token=${token}` : location.pathname}
    />
  ) : (
    <Redirect from="/" to="/home" />
  );
};
