import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { HistoryChildrensPage } from "./modules/HistoryChildrens";
import { DashPage } from "./modules/Dashboard";
import { ManageChildrensPage } from "./modules/ManageChildrens";
import { ChildrensProfilePage } from "./modules/ChildrensProfile";
import { ManageUserPage } from "./modules/ManageUser";
import { EvaluateChildrenPage } from "./modules/EvaluateChildren";
import { EvaluateChildrenDetail } from "./modules/EvaluateChildren/pages/EvaluateChildrenDetail";
import { NutritionInformationPage } from "./modules/NutritionInformation";
import { NutritionFoodPage } from "./modules/NutritionFood";
import { NutritionFoodEditPage } from "./modules/NutritionFoodEdit";

export default function BasePage() {
  const { authToken } = useSelector(
    (state) => ({
      authToken: state.auth.authToken,
    }),
    shallowEqual
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <Route path="/dashboard" component={DashPage} />
        <Route
          path="/nutrition-information/:childrenId/children/:id/edit"
          component={NutritionFoodEditPage}
        />
        <Route
          path="/nutrition-information/:childrenId/children/new"
          component={NutritionFoodEditPage}
        />
        <Route
          path="/nutrition-information/:id/edit"
          component={NutritionFoodEditPage}
        />
        <Route
          path="/nutrition-information/:id/food"
          component={NutritionFoodPage}
        />
        <Route
          path="/nutrition-information/:id/profile"
          component={NutritionInformationPage}
        />
        <Route
          path="/nutrition-information/:id/childrens"
          component={ManageChildrensPage}
        />
        <Route path="/nutrition-information" component={HistoryChildrensPage} />
        <Route
          path="/history-childrens/:id/profile"
          component={ChildrensProfilePage}
        />
        <Route
          path="/history-childrens/:id/manage-childrens"
          component={ManageChildrensPage}
        />
        <Route path="/history-childrens" component={HistoryChildrensPage} />
        <Route
          path="/evaluate-children/:id/childrens"
          component={ManageChildrensPage}
        />
        <Route
          path="/evaluate-children/:id/age-detail"
          component={EvaluateChildrenDetail}
        />
        <Route
          path="/evaluate-children/:id/result"
          component={EvaluateChildrenPage}
        />
        <Route
          path="/evaluate-children/:id/profile"
          component={EvaluateChildrenPage}
        />
        <Route path="/evaluate-children" component={HistoryChildrensPage} />
        <Route path="/manage-user" component={ManageUserPage} />
        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
