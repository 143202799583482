import React, { useMemo, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/NutritionFoodActions";
import { useToast } from "@chakra-ui/react";

export function DeleteDialog({ show, onHide }) {
  const toast = useToast();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [childrenId, setChildrenId] = useState(null);
  const [date, setDate] = useState(null);
  const { currentState, generalState } = useSelector(
    (state) => ({
      currentState: state.manageChildrens,
      generalState: state.general,
    }),
    shallowEqual
  );

  const { actionsLoading, error } = currentState;

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const date = searchParams.get("date");
      const childrenId = searchParams.get("childrenId");
      setDate(date);
      setChildrenId(childrenId);
    }
  }, [location]);

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState())
    }
  }, [error]);

  const onClickCancel = () => {
    onHide();
  };

  const onClickDelete = () => {
    dispatch(actions.deleteFoodDetail(date, childrenId, id)).then(() => {
      toast({
        title: "ลบข้อมูลโภชนาการ สำเร็จ!",
        description: "คุณได้ทำการลบข้อมูลโภชนาการสำเร็จ",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onHide();
    });
  };

  const getMealTime = () => {
    const find = generalState.mealTime.find((item) => item.ID == id);
    return find?.MEAL_TIME;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: 300,
            }}
          >
            <div>
              <div className="p-10">
                <div className="text-center font-size-sm text-dark font-weight-boldest my-10">
                  <div className="text-primary font-size-h3 mb-2">
                    ลบข้อมูลโภชนาการ
                  </div>
                  <div className="">
                    คุณแน่ใจหรือไม่ ? ที่ต้องการลบข้อมูลโภชนาการ
                  </div>
                  <div className="text-danger">{getMealTime()}</div>
                  <div className="">ออกจากระบบ</div>
                </div>
                <div className="d-flex justify-content-between w-100 py-5 border-top mt-10">
                  <button
                    className="btn text-dark font-size-sm font-weight-bolder"
                    style={{ backgroundColor: "#E0DEDE" }}
                    onClick={onClickCancel}
                  >
                    ยกเลิก
                  </button>
                  <button
                    disabled={actionsLoading}
                    className="btn btn-primary font-size-sm font-weight-bolder d-flex align-items-center"
                    onClick={onClickDelete}
                  >
                    {actionsLoading && (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    ลบข้อมูลโภชนาการ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
