import * as requestFromServer from "./NutritionFoodEditCrud";
import { NutritionFoodEditSlice, callTypes } from "./NutritionFoodEditSlice";
const { actions } = NutritionFoodEditSlice;

export const getFood = (date, childrenId) => async (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));

  const getMenu = await requestFromServer.getFood();

  const mealTime = await requestFromServer.getMealTimeByChildren(
    date,
    childrenId
  );

  const foodDetail = await requestFromServer.getFoodDetail(date, childrenId);

  Promise.all([getMenu, mealTime, foodDetail])
    .then((response) => {
      dispatch(actions.setFood(response[0].data));
      dispatch(actions.setMealTime(response[1].data));
      dispatch(actions.setFoodDetail(response[2].data));
      dispatch(actions.stopCall({ callTypes: callTypes.action }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getFoodEdit = (date, childrenId) => async (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));

  const getMenu = await requestFromServer.getFood();

  const mealTime = await requestFromServer.getMealTime();

  const foodDetail = await requestFromServer.getFoodDetail(date, childrenId);

  Promise.all([getMenu, mealTime, foodDetail])
    .then((response) => {
      dispatch(actions.setFood(response[0].data));
      dispatch(actions.setMealTime(response[1].data));
      dispatch(actions.setFoodDetail(response[2].data));
      dispatch(actions.stopCall({ callTypes: callTypes.action }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getChildrenById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getInfoChildrenById(id)
      .then((res) => {
        dispatch(actions.setChildrenInfo(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const postChildrenDetail = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .postChildrenFood(payload)
      .then((res) => {
        dispatch(actions.stopCall({ callTypes: callTypes.action }));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const clearState = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.clearState());
  });
};
