export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "25", value: 25 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  filter_role: "",
  filter_department: "",
  filter: "",
  // sortOrder: "asc",
  // sortField: "VINCode",
  pageNumber: 1,
  pageSize: 25,
};
