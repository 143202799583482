import { createSlice } from "@reduxjs/toolkit";

const initialHistoryChildrensState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  error: undefined,
  entities: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const HistoryChildrensSlice = createSlice({
  name: "historyChildrens",
  initialState: initialHistoryChildrensState,
  reducers: {
    catchError: (state, action) => {
      state.error =
        action.payload.error.status >= 500
          ? "กรุณาติดต่อผู้ดูแลระบบ"
          : action.payload.error?.response?.data?.message;
      state.stateAction = "error";
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    setEntities: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = action.payload.docs;
      state.totalCount = action.payload.total;
    },
    stopCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    clearState: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
  },
});
