import * as requestFromServer from "./EvaluateChildrenCrud";
import { EvaluateChildrenSlice, callTypes } from "./EvaluateChildrenSlice";
const { actions } = EvaluateChildrenSlice;

export const getEvaluateChildrenList = (queryParams, childrenId) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getDateList(queryParams, childrenId)
      .then((res) => {
        dispatch(actions.setEntities(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getChildrenCenterById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getChildrensCenterById(id)
      .then((res) => {
        dispatch(actions.setChildrenCenter(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getEstimateByChildrenProfileId = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getEstimateByChildrenProfileId(id)
      .then((res) => {
        dispatch(actions.stopCall({ callTypes: callTypes.action }));
        dispatch(actions.setChildrenProfile(res.data));
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getFoodEstimateByChildrenProfileId = (id, date) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getFoodEstimateByChildrenProfileId(id, date)
      .then((res) => {
        dispatch(actions.stopCall({ callTypes: callTypes.action }));
        dispatch(actions.setFoodEstimate(res.data));
      })
      .catch((error) => {
        dispatch(actions.setFoodEstimate(null));
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const clearState = () => (dispatch) => {
  dispatch(actions.clearState());
};
