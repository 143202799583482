import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Divider } from "@chakra-ui/react";
export function Brand() {
  return (
    <div>
      {/* <div style={{ height: "4rem" }}>
        <img
          src={`${toAbsoluteUrl("/media/forweb/logo_mfu_3d_colour.png")}`}
          alt="image"
          className="h-100"
        />
      </div> */}
      <div className="mx-3 mb-3">
        <div className="text-white font-weight-bold" style={{ fontSize: 16 }}>
          ระบบฐานข้อมูลภาวะโภชนาการ
        </div>
        <div className="text-white font-weight-bold" style={{ fontSize: 12 }}>
          ของเด็กวัยก่อนเรียน
        </div>
      </div>
      <Divider />
    </div>
  );
}
