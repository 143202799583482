import * as requestFromServer from "./ChildrensProfileCrud";
import { ChildrensProfileSlice, callTypes } from "./ChildrensProfileSlice";
const { actions } = ChildrensProfileSlice;

export const getChildrensHistoryList = (queryParams, childrenId) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getChildrensProfilList(queryParams, childrenId)
      .then((res) => {
        dispatch(actions.setEntities(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const postWeightHeight = (payload, queryParams) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .postWeightHeight(payload)
      .then((res) => {
        dispatch(
          getChildrensHistoryList(queryParams, payload.CHILDREN_PROFILE_ID)
        );
        dispatch(getChildrenById(payload.CHILDREN_PROFILE_ID));
        dispatch(getDashboard(payload.CHILDREN_PROFILE_ID));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getWeightHeightById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getWeightHeightById(id)
      .then((res) => {
        dispatch(actions.stopCall({ callTypes: callTypes.action }));
        resolve(res.data);
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getChildrenCenterById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getChildrensCenterById(id)
      .then((res) => {
        dispatch(actions.setChildrenCenter(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getChildrenById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getChildrensById(id)
      .then((res) => {
        dispatch(actions.stopCall({ callTypes: callTypes.action }));
        dispatch(actions.setChildrenProfile(res.data));
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getChildrenInfoById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getInfoChildrenById(id)
      .then((res) => {
        dispatch(actions.stopCall({ callTypes: callTypes.action }));
        resolve(res.data);
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const editChildren = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  return new Promise((resolve, reject) => {
    requestFromServer
      .postChildrens(payload)
      .then(() => {
        dispatch(getChildrenById(payload.ID));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const deleteWeightHeightById = (payload, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  return new Promise((resolve, reject) => {
    requestFromServer
      .deleteWeightHeight(payload)
      .then(() => {
        dispatch(getChildrensHistoryList(queryParams, payload.CHILDREN_ID));
        dispatch(getDashboard(payload.CHILDREN_ID));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const clearState = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.clearState());
  });
};

export const getDashboard = (id) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  return new Promise((resolve, reject) => {
    requestFromServer
      .getDashboardById(id)
      .then((response) => {
        dispatch(actions.setDashboard(response.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};
