import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../modules/Dashboard/_redux/dashboardAction";
import { CgPassword } from "react-icons/cg";
import { Icon } from "@chakra-ui/react";
import { ResetDialog } from "../Layout/ResetDialog";
import { SuccessDialog } from "../Layout/SuccessDialog";

export function UserProfile() {
  const dispatch = useDispatch();

  const { authToken, currentState } = useSelector(
    (state) => ({
      authToken: state.auth.authToken,
      currentState: state.auth,
    }),
    shallowEqual
  );

  const history = useHistory();

  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showCompletePassword, setShowCompletePassword] = useState(false);
  const user = {
    firstname: currentState?.user?.FIRSTNAME,
    lastname: currentState?.user?.LASTNAME,
  };

  const onHide = () => {
    setShowResetPassword(false);
  };

  const onComplete = () => {
    setShowResetPassword(false);
    setShowCompletePassword(true);
  };

  const onHideComplete = () => {
    setShowCompletePassword(true);
  };

  return (
    <>
      {showResetPassword && (
        <ResetDialog show={true} onHide={onHide} onComplete={onComplete} />
      )}
      {showCompletePassword && <SuccessDialog show={true} onHide={onHideComplete} />}
      <Dropdown style={{ minWidth: "180px" }} drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
          >
            <span className="text-dark font-weight-bolder font-size-xs d-none d-md-inline">
              {user.firstname} {user.lastname}
            </span>
            <span className="symbol symbol-35 p-5 ">
              <span className="symbol-label  bg-primary text-white font-weight-bold">
                {user.firstname == undefined
                  ? user.firstname
                  : user.firstname[0]}
              </span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround w-100 bg-light-white">
          <div className="w-100 bg-white my-5">
            <button
              type="button"
              className="btn btn-outline-primary border-0 font-size-xs w-100 rounded-0 d-flex align-items-center"
              onClick={() => {
                setShowResetPassword(true);
                // history.push("/logout")
              }}
            >
              <Icon as={CgPassword} />
              <div className="ml-2">เปลี่ยนรหัสผ่าน</div>
            </button>
            <button
              type="button"
              className="btn btn-outline-primary border-0 font-size-xs w-100 rounded-0 d-flex align-items-center"
              onClick={() => history.push("/logout")}
            >
              <span className="svg-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Sign-out.svg"
                  )}
                ></SVG>{" "}
              </span>
              <div className="mx-1">ออกจากระบบ</div>
            </button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
