import React, { useState, useEffect, useMemo, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { isEqual } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  NoRecordsFoundMessage,
  getHandlerTableChange,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import * as actions from "../_redux/ManageUserActions";
import * as uiHelpers from "./ManageUserUIHelpers";
import { useManageUserUIContext } from "./ManageUserUIContext";
import { Pagination } from "../../../../_metronic/_partials/controls";
import {
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Icon,
} from "@chakra-ui/react";
import { CiSearch } from "react-icons/ci";
import { Select } from "../../../components/Select";
import { AiFillPlusCircle } from "react-icons/ai";
import { RiSearchEyeLine } from "react-icons/ri";
import { PiPencilSimpleDuotone } from "react-icons/pi";
import { CgTrash } from "react-icons/cg";
import * as masterData from "../../../../config";
import { useToast } from "@chakra-ui/react";

export function ManageUserTable() {
  const toast = useToast();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [department, setDepartment] = useState([
  //   { ID: null, NAME: "หน่วยงานที่สังกัด" },
  // ]);
  // const [role, setRole] = useState([{ ID: null, ROLE: "บทบาท" }]);
  const manageUserUIContext = useManageUserUIContext();
  const manageUserUIProps = useMemo(() => {
    return {
      ids: manageUserUIContext.ids,
      queryParams: manageUserUIContext.queryParams,
      setQueryParams: manageUserUIContext.setQueryParams,
    };
  }, [manageUserUIContext]);

  const { currentState, generalState } = useSelector(
    (state) => ({
      currentState: state.manageUser,
      generalState: state.general,
    }),
    shallowEqual
  );

  const { entities, actionsLoading } = currentState;
  const { department, role } = generalState;
  const firstRender = useRef(true);

  useEffect(() => {
    // const departmentFetch = masterData.masterDataDepartment();

    // const roleFetch = masterData.masterDataRole();
    dispatch(actions.getUserList(manageUserUIProps.queryParams));

    // Promise.all([departmentFetch, roleFetch])
    //   .then((response) => {
    //     setDepartment(department.concat(response[0].data));
    //     setRole(role.concat(response[1].data));
    //     dispatch(actions.getUserList(manageUserUIProps.queryParams));
    //   })
    //   .catch((error) => {
    //     toast({
    //       title: "เกิดข้อผิดพลาด!",
    //       description: error,
    //       status: "error",
    //       duration: 3000,
    //       isClosable: true,
    //     });
    //   });
  }, []);

  const [state, setState] = useState({
    filter_role: "",
    filter_department: "",
    filter: "",
  });

  useEffect(() => {
    applyFilter(state);
  }, [state]);

  const applyFilter = (values) => {
    const newQueryParams = { ...manageUserUIProps.queryParams, ...values };
    if (!isEqual(newQueryParams, manageUserUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      manageUserUIProps.setQueryParams(newQueryParams);
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    dispatch(actions.getUserList(manageUserUIProps.queryParams));
  }, [manageUserUIProps.queryParams]);

  const onClickInfo = (id) => {
    history.push(`/manage-user/${id}/info`);
  };

  const onClickEdit = (id) => {
    history.push(`/manage-user/${id}/edit`);
  };

  const onClickNew = () => {
    history.push(`/manage-user/new`);
  };

  const onClickDelete = (id) => {
    history.push(`/manage-user/${id}/delete`);
  };

  const getRoleName = (id) => {
    return role.find((item) => item.ID == id)?.ROLE || "-";
  };

  const getDepartmentName = (id) => {
    return department.find((item) => item.ID == id)?.NAME || "-";
  };

  const columns = [
    {
      dataField: "",
      text: "ลำดับ",
      style: { width: "10%" },
      formatter: (cell, row, rowIndex) => {
        return (
          rowIndex +
          (manageUserUIProps.queryParams.pageNumber - 1) *
            manageUserUIProps.queryParams.pageSize +
          1
        );
      },
    },
    {
      dataField: "name",
      text: "ชื่อ - นามสกุล ",
      formatter: (cell, row, rowIndex) => {
        return `${row.FIRSTNAME} ${row.LASTNAME}`;
      },
    },
    {
      dataField: "",
      text: "อีเมล",
      formatter: (cell, row, rowIndex) => {
        return `${row.USERNAME}`;
      },
    },
    {
      dataField: "",
      text: "บทบาทผู้ใช้งาน",
      formatter: (cell, row, rowIndex) => {
        return `${getRoleName(row.ROLE)}`;
      },
    },
    {
      dataField: "",
      text: "หน่วยงานที่สังกัด",
      formatter: (cell, row, rowIndex) => {
        return `${getDepartmentName(row.DEPARTMENT)}`;
      },
    },
    {
      dataField: "action",
      text: "จัดการ",
      formatter: ActionCell,
      formatExtraData: {
        onClickEdit: onClickEdit,
        onClickInfo: onClickInfo,
        onClickDelete: onClickDelete,
      },
      style: { width: "10%" },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: entities?.total || 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: manageUserUIProps.queryParams.pageSize,
    page: manageUserUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div>
          <div className="text-primary font-size-h5 font-weight-bolder">
            จัดการผู้ใช้งาน
          </div>
          <div className="text-f2" style={{ fontSize: 14 }}>
            จำนวนผู้ใช้งานในระบบทั้งหมด
            <span className="text-primary mx-2">{entities?.total || 0}</span>
            ท่าน
          </div>
        </div>
        <div>
          <Button
            size={"lg"}
            className="text-white bg-primary"
            onClick={onClickNew}
            leftIcon={<AiFillPlusCircle style={{ fontSize: 20 }} />}
          >
            เพิ่มผู้ใช้งาน
          </Button>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div className="d-flex" style={{ width: "40%" }}>
          <Select
            style={{ marginRight: 20 }}
            item={[{ ID: null, ROLE: "บทบาท" }, ...role]}
            labelObject={"ROLE"}
            valueObject={"ID"}
            onChange={(e) => {
              setState({ ...state, filter_role: e.target.value });
            }}
          />
          <Select
            item={[{ ID: null, NAME: "หน่วยงานที่สังกัด" }, ...department]}
            labelObject={"NAME"}
            valueObject={"ID"}
            onChange={(e) => {
              setState({ ...state, filter_department: e.target.value });
            }}
          />
        </div>
        <div style={{ width: "40%" }}>
          <InputGroup
            size="lg"
            style={{ border: "1px solid #979797", borderRadius: 5 }}
          >
            <InputLeftElement
              pointerEvents="none"
              children={<CiSearch color="gray" />}
            />
            <Input
              type="text"
              placeholder="ค้นหา"
              style={{ border: "none", backgroundColor: "transparent" }}
              onChange={(e) => {
                setState({ ...state, filter: e.target.value });
              }}
            />
          </InputGroup>
        </div>
      </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={actionsLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-vertical-center overflow-hidden text-dark font-size-xs font-weight-bolder"
                bodyClasses="text-dark font-size-xs font-weight-bolder"
                bootstrap4
                remote
                keyField="id"
                columns={columns}
                data={entities?.docs || []}
                onTableChange={getHandlerTableChange(
                  manageUserUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities?.docs} />
                <NoRecordsFoundMessage entities={entities?.docs} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

function ActionCell(
  cellContent,
  row,
  rowIndex,
  { onClickEdit, onClickInfo, onClickDelete }
) {
  return (
    <>
      <div className="d-flex">
        <button
          className="btn mx-2"
          onClick={() => onClickInfo(row.ID)}
          style={{
            width: 45,
            backgroundColor: "#979797",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon as={RiSearchEyeLine} style={{ color: "white", fontSize: 20 }} />
        </button>
        <button
          className="btn btn-warning mx-2"
          onClick={() => onClickEdit(row.ID)}
          style={{
            width: 45,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            as={PiPencilSimpleDuotone}
            style={{ color: "white", fontSize: 20 }}
          />
        </button>
        <button
          className="btn btn-danger mx-2"
          onClick={() => onClickDelete(row.ID)}
          style={{
            width: 45,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon as={CgTrash} style={{ color: "white", fontSize: 20 }} />
        </button>
      </div>
    </>
  );
}

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-5">
    <span className="text-f2">แสดง</span> {from} - {to}{" "}
    <span className="text-f2">จาก</span> {size}
  </span>
);
