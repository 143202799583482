import React, { useState } from "react";
import { Icon } from "@material-ui/core";
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Visibility, VisibilityOff } from "@material-ui/icons";

const usePasswordToggle = () => {
  const [visible, setVisiblity] = useState(false);

  const Icon = visible ? (
    <Visibility
      onClick={() => setVisiblity((visiblity) => !visiblity)}
      fontSize="large"
    />
  ) : (
    <VisibilityOff
      onClick={() => setVisiblity((visiblity) => !visiblity)}
      fontSize="large"
    />
  );
  const InputType = visible ? "text" : "password";

  return [InputType, Icon];
};

export default usePasswordToggle;
