import axios from "axios";
import * as config from "../../../../config";

const { API_ENDPOINT } = config;

export function createUser(payload) {
  return axios.post(`${API_ENDPOINT}/user/register`, payload);
}

export function updateUser(payload) {
  return axios.put(`${API_ENDPOINT}/user/`, payload);
}

export function getUser(params) {
  let formatParams = "";
  Object.keys(params).map((item, index) => {
    if (params[item] == "บทบาท" || params[item] == "หน่วยงานที่สังกัด" || params[item] == '' && item != "filter") return;
    if (formatParams == "") {
      return (formatParams += `?${item}=${params[item]}`);
    }
    formatParams += `&${item}=${params[item]}`;
  });
  return axios.get(`${API_ENDPOINT}/user/${formatParams}`);
}

export function getUserById(id) {
  return axios.get(`${API_ENDPOINT}/user/?id=${id}`);
}

export function deleteUser(id) {
  return axios.delete(`${API_ENDPOINT}/user/deleteUser/${id}`);
}
