import * as requestFromServer from "./AgeDetailCrud";

import { ageDetailSlice, callTypes } from "./AgeDetailSlice";
const { actions } = ageDetailSlice;

export const getDietary = (year) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(actions.startCall({ callTypes: callTypes.action }));
      requestFromServer
        .getDietary(year)
        .then((res) => {
          dispatch(actions.setDietary(res.data));
          resolve();
        })
        .catch((error) => {
          dispatch(actions.catchError({ error, callType: callTypes.action }));
          reject();
        });
    });
  };

