import React from "react";
import { ManageUserTable } from "./ManageUserTable";
import { Switch, Route, useHistory } from "react-router-dom";
import { EditDialog } from "./EditDialog";
import { InfoDialog } from "./InfoDialog";
import { DeleteDialog } from "./DeleteDialog";
import { DeleteSuccessDialog } from "../../../components/DeleteSuccessDialog";
import { ManageUserUIProvider } from "./ManageUserUIContext";

export function ManageUserPage() {
  const history = useHistory();

  const onHide = () => {
    history.push("/manage-user");
  };

  return (
    <ManageUserUIProvider>
      <Switch>
        <Route path="/manage-user/:id/info">
          {({ history, match }) => (
            <InfoDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/manage-user/:id/edit">
          {({ history, match }) => (
            <EditDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/manage-user/new">
          {({ history, match }) => (
            <EditDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/manage-user/:id/delete">
          {({ history, match }) => (
            <DeleteDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/manage-user/delete_success">
          {({ history, match }) => (
            <DeleteSuccessDialog show={match != null} onHide={onHide} />
          )}
        </Route>
      </Switch>
      <div className="constainer-fluid gb-light-white p-10 mt-5 shadow-sm rounded">
        <ManageUserTable />
      </div>
    </ManageUserUIProvider>
  );
}
