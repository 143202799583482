import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { TextWhite } from "../HomePage/TextWhite";
import { Image } from "@chakra-ui/react";

export function Brand() {
  return (
    <div className="d-flex align-items-center py-3 flex-wrap">
      {/* <Image
        borderRadius="full"
        boxSize={"60px"}
        src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
        alt="Dan Abramov"
      /> */}
      <div className="ml-3">
      <p className="text-white">ระบบฐานข้อมูลภาวะโภชนาการ </p>
      <span className="text-white fw-bold font-size-sm">ของเด็กวัยก่อนเรียน</span>
      </div>
    </div>
  );
}
