import React, { useState, useEffect, useMemo, forwardRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { isEqual } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  NoRecordsFoundMessage,
  getHandlerTableChange,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import * as actions from "../_redux/NutritionInformationActions";
import * as uiHelpers from "./NutritionInformationUIHelpers";
import { useNutritionInformationUIContext } from "./NutritionInformationUIContext";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { Button, Icon } from "@chakra-ui/react";
import { RiSearchEyeLine } from "react-icons/ri";
import { FiChevronLeft } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";
import DatePicker, { registerLocale } from "react-datepicker";
import { th } from "date-fns/locale";
import { useToast } from "@chakra-ui/react";
import { IoReloadSharp } from "react-icons/io5";
import moment from "moment";

moment.locale("th");
registerLocale("th", th);

export function NutritionInformationTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const nutritionInformationUIContext = useNutritionInformationUIContext();
  const nutritionInformationUIProps = useMemo(() => {
    return {
      ids: nutritionInformationUIContext.ids,
      queryParams: nutritionInformationUIContext.queryParams,
      setQueryParams: nutritionInformationUIContext.setQueryParams,
    };
  }, [nutritionInformationUIContext]);

  const { currentState, authState } = useSelector(
    (state) => ({
      currentState: state.nutritionInformation,
      authState: state.auth.user,
    }),
    shallowEqual
  );

  const { totalCount, entities, actionsLoading, childrenInfo } = currentState;

  const { ROLE } = authState;

  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (ROLE == 2 || ROLE == 4) {
      setCanEdit(false);
    } else {
      setCanEdit(true);
    }
  }, [ROLE]);

  useEffect(() => {
    dispatch(actions.getChildrenById(id));
  }, []);

  useEffect(() => {
    dispatch(
      actions.getNutritionInformationList(
        nutritionInformationUIProps.queryParams,
        id
      )
    );
  }, [nutritionInformationUIProps.queryParams, dispatch]);

  const onClickInfo = (date) => {
    history.push(`/nutrition-information/${id}/food?date=${date}`);
  };

  const onClickNew = () => {
    history.push(`/nutrition-information/${id}/children/new`);
  };

  const applyFilter = (values) => {
    const newQueryParams = {
      ...nutritionInformationUIProps.queryParams,
      ...values,
    };
    if (!isEqual(newQueryParams, nutritionInformationUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      nutritionInformationUIProps.setQueryParams(newQueryParams);
    }
  };

  const columns = [
    {
      text: "วันที่",
      style: { width: "10%" },
      formatter: (cell, row, rowIndex) => {
        return moment(row.date)
          .add(543, "year")
          .format("DD/MM/YYYY");
      },
    },
    {
      headerAlign: "center",
      style: { textAlign: "center", width: "18%" },
      text: "มื้อเช้า",
      formatter: (cell, row, rowIndex) => {
        return (
          <div
            className="py-2 px-4"
            style={{
              borderRadius: 5,
              backgroundColor: row["มื้อเช้า"] != "-" ? "#D8EEBE" : "",
              color: row["มื้อเช้า"] != "-" ? "#6B8A47" : "",
            }}
          >
            {row["มื้อเช้า"]}
          </div>
        );
      },
    },
    {
      headerAlign: "center",
      style: { textAlign: "center", width: "18%" },
      text: "อาหารว่างเช้า",
      formatter: (cell, row, rowIndex) => {
        return (
          <div
            className="py-2 px-4"
            style={{
              borderRadius: 5,
              backgroundColor: row["อาหารว่างเช้า"] != "-" ? "#D8EEBE" : "",
              color: row["อาหารว่างเช้า"] != "-" ? "#6B8A47" : "",
            }}
          >
            {row["อาหารว่างเช้า"]}
          </div>
        );
      },
    },
    {
      headerAlign: "center",
      style: { textAlign: "center", width: "18%" },
      text: "มื้อกลางวัน",
      formatter: (cell, row, rowIndex) => {
        return (
          <div
            className="py-2 px-4"
            style={{
              borderRadius: 5,
              backgroundColor: row["มื้อกลางวัน"] != "-" ? "#D8EEBE" : "",
              color: row["มื้อกลางวัน"] != "-" ? "#6B8A47" : "",
            }}
          >
            {row["มื้อกลางวัน"]}
          </div>
        );
      },
    },
    {
      headerAlign: "center",
      style: { textAlign: "center", width: "18%" },
      text: "อาหารว่างบ่าย",
      formatter: (cell, row, rowIndex) => {
        return (
          <div
            className="py-2 px-4"
            style={{
              borderRadius: 5,
              backgroundColor: row["อาหารว่างบ่าย"] != "-" ? "#D8EEBE" : "",
              color: row["อาหารว่างบ่าย"] != "-" ? "#6B8A47" : "",
            }}
          >
            {row["อาหารว่างบ่าย"]}
          </div>
        );
      },
    },
    {
      headerAlign: "center",
      style: { textAlign: "center", width: "18%" },
      text: "มื้อเย็น",
      formatter: (cell, row, rowIndex) => {
        return (
          <div
            className="py-2 px-4"
            style={{
              borderRadius: 5,
              backgroundColor: row["มื้อเย็น"] != "-" ? "#D8EEBE" : "",
              color: row["มื้อเย็น"] != "-" ? "#6B8A47" : "",
            }}
          >
            {row["มื้อเย็น"]}
          </div>
        );
      },
    },
    {
      headerAlign: "center",
      style: { display: "flex", justifyContent: "center" },
      dataField: "action",
      text: "ดูข้อมูล",
      formatter: ActionCell,
      formatExtraData: {
        onClickInfo: onClickInfo,
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: nutritionInformationUIProps.queryParams.pageSize,
    page: nutritionInformationUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  const formattedDate = (date) => {
    return moment(date)
      .add(543, "year")
      .format("MMMM YYYY");
  };

  return (
    <>
      <div className="mb-10">
        <Button
          onClick={() => history.goBack()}
          leftIcon={<FiChevronLeft style={{ fontSize: 20 }} />}
          size="lg"
          style={{ color: "#ffff", backgroundColor: "#58A3BC" }}
        >
          ย้อนกลับไป
        </Button>
      </div>
      <div className="d-flex justify-content-between mb-5">
        <div>
          <div className="font-weight-bolder text-f2" style={{ fontSize: 16 }}>
            ข้อมูลภาวะโภชนาการ
          </div>
          <div className="text-primary font-size-h5 font-weight-bolder">
            {childrenInfo?.CHILDREN.FIRSTNAME} {childrenInfo?.CHILDREN.LASTNAME}
          </div>
          <div className="text-f2" style={{ fontSize: 14 }}>
            อายุ
            <span className="text-primary mx-2">
              {childrenInfo?.AGE.years} ปี {childrenInfo?.AGE.months} เดือน
            </span>
            น้ำหนัก
            <span className="text-primary mx-2">
              {childrenInfo?.WEIGHT} กิโลกรัม
            </span>
            ส่วนสูง
            <span className="text-primary mx-2">
              {childrenInfo?.HEIGHT} เซนติเมตร
            </span>
          </div>
        </div>
        {canEdit && (
          <div
            style={{
              width: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size={"lg"}
              className="text-white bg-primary"
              onClick={onClickNew}
              leftIcon={<AiFillPlusCircle style={{ fontSize: 20 }} />}
              style={{ alignSelf: "center" }}
            >
              เพิ่มข้อมูลโภชนาการ
            </Button>
          </div>
        )}
      </div>
      <div className="mb-5">
        <div className="d-flex">
          <DatePicker
            selected={nutritionInformationUIProps.queryParams.START_DATE}
            onChange={(date) => applyFilter({ START_DATE: date })}
            locale={th}
            customInput={<CustomInputDateStart />}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              customHeaderCount,
              prevMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--previous"
                  }
                  style={
                    customHeaderCount === 1 ? { visibility: "hidden" } : null
                  }
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                    }
                  >
                    {"<"}
                  </span>
                </button>
                <span className="react-datepicker__current-month">
                  {formattedDate(date)}
                </span>
                <button
                  aria-label="Next Month"
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--next"
                  }
                  style={
                    customHeaderCount === 0 ? { visibility: "hidden" } : null
                  }
                  onClick={increaseMonth}
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                    }
                  >
                    {">"}
                  </span>
                </button>
              </div>
            )}
            dateFormat="dd MM yyyy"
          />
          <DatePicker
            selected={nutritionInformationUIProps.queryParams.END_DATE}
            onChange={(date) => applyFilter({ END_DATE: date })}
            locale={th}
            minDate={nutritionInformationUIProps.queryParams.START_DATE}
            customInput={<CustomInputDateEnd />}
            dateFormat="dd MM yyyy"
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              customHeaderCount,
              prevMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--previous"
                  }
                  style={
                    customHeaderCount === 1 ? { visibility: "hidden" } : null
                  }
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                    }
                  >
                    {"<"}
                  </span>
                </button>
                <span className="react-datepicker__current-month">
                  {formattedDate(date)}
                </span>
                <button
                  aria-label="Next Month"
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--next"
                  }
                  style={
                    customHeaderCount === 0 ? { visibility: "hidden" } : null
                  }
                  onClick={increaseMonth}
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                    }
                  >
                    {">"}
                  </span>
                </button>
              </div>
            )}
          />
          <div
            className="d-flex"
            style={{ alignItems: "center", cursor: "pointer" }}
            onClick={() =>
              applyFilter({ END_DATE: new Date(), START_DATE: new Date() })
            }
          >
            <Icon
              as={IoReloadSharp}
              style={{ color: "#979797", fontSize: 20 }}
            />
            <span
              className="ml-2"
              style={{ color: "#979797", fontSize: 15, userSelect: "none" }}
            >
              เคลียร์วันที่ที่เลือก
            </span>
          </div>
        </div>
      </div>

      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={actionsLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-vertical-center overflow-hidden text-dark font-size-xs font-weight-bolder"
                bodyClasses="text-dark font-size-xs font-weight-bolder"
                bootstrap4
                remote
                keyField="id"
                columns={columns}
                data={entities || []}
                onTableChange={getHandlerTableChange(
                  nutritionInformationUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

function ActionCell(cellContent, row, rowIndex, { onClickInfo }) {
  return (
    <>
      <div className="d-flex">
        <button
          className="btn mx-2"
          onClick={() => onClickInfo(row.date)}
          style={{
            width: 45,
            backgroundColor: "#979797",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon as={RiSearchEyeLine} style={{ color: "white", fontSize: 20 }} />
        </button>
      </div>
    </>
  );
}

const CustomInputDateStart = forwardRef(({ value, onClick }, ref) => (
  <button
    className="d-flex align-items-center rounded mr-5"
    onClick={onClick}
    ref={ref}
    style={{
      border: "1px solid #6E6E6E",
      backgroundColor: "#F0F0F0",
      textAlign: "left",
    }}
  >
    <div className="font-weight-bolder px-5 py-1">
      <div style={{ color: "#6E6E6E", width: "180px" }}>
        เลือกวันที่เริ่มต้น
      </div>
      <div className="font-size-sm text-dark">{moment(value, "DD MM YYYY").add(543, 'year').format('DD MMMM YYYY')}</div>
    </div>
    <div
      className="d-flex bg-primary"
      style={{
        minHeight: 50,
        width: 56,
        borderTopRightRadius: ".31rem",
        borderBottomRightRadius: ".31rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <i class="fa-regular fa-calendar text-white icon-lg"></i>
    </div>
  </button>
));

const CustomInputDateEnd = forwardRef(({ value, onClick }, ref) => (
  <button
    className="d-flex align-items-center rounded mr-5"
    onClick={onClick}
    ref={ref}
    style={{
      border: "1px solid #6E6E6E",
      backgroundColor: "#F0F0F0",
      textAlign: "left",
    }}
  >
    <div className="font-weight-bolder px-5 py-1">
      <div style={{ color: "#6E6E6E", width: "180px" }}>เลือกวันที่สิ้นสุด</div>
      <div className="font-size-sm text-dark">{moment(value, "DD MM YYYY").add(543, 'year').format('DD MMMM YYYY')}</div>
    </div>
    <div
      className="d-flex bg-primary"
      style={{
        minHeight: 50,
        width: 56,
        borderTopRightRadius: ".31rem",
        borderBottomRightRadius: ".31rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <i class="fa-regular fa-calendar text-white icon-lg"></i>
    </div>
  </button>
));

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-5">
    <span className="text-f2">แสดง</span> {from} - {to}{" "}
    <span className="text-f2">จาก</span> {size}
  </span>
);
