import React, { useRef, ChangeEventHandler, useState, useEffect } from "react";
import DatePickerCustom, { registerLocale } from "react-datepicker";
import "react-day-picker/dist/style.css";
import { th } from "date-fns/locale";
import moment from "moment";
import "moment/locale/th";

moment.locale("th");
registerLocale("th", th);

export function DatePicker({
  value,
  onChange,
  disabled,
  className,
  DateStyle,
  onFocus,
  name,
  placeholder,
  type = null,
  ...props
}) {
  const startYear = () => {
    const date = moment().subtract(6, "year");
    return date.toDate();
  };

  const endYear = () => {
    const date = moment().subtract(2, "year");
    return date.toDate();
  };

  const [years, setYear] = useState([]);

  const [currentYear, setCurrentYear] = useState(null);

  useEffect(() => {
    const format = "YYYY";
    const start = moment(startYear(), format),
      end = moment(endYear(), format);
    const result = [];
    while (start.isSameOrBefore(end)) {
      result.push(start.format("YYYY"));
      setCurrentYear(start.format("YYYY"));
      start.add(1, "year");
    }
    setYear(result);
  }, []);

  const months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const renderCustomHeader = (
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    customHeaderCount,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  ) => {
    return type == "dropdown" ? (
      <CustomHeaderDropdown
        changeYear={changeYear}
        changeMonth={changeMonth}
        decreaseMonth={decreaseMonth}
        customHeaderCount={customHeaderCount}
        increaseMonth={increaseMonth}
        prevMonthButtonDisabled={prevMonthButtonDisabled}
        nextMonthButtonDisabled={nextMonthButtonDisabled}
        years={years}
        months={months}
        value={value}
        currentYear={
          value
            ? moment(value).format("YYYY")
            : moment(currentYear).format("YYYY")
        }
        currentMonth={value ? moment(value).format("MM") - 1 : 0}
      />
    ) : (
      <CustomHeader
        date={date}
        value={value}
        changeYear={changeYear}
        changeMonth={changeMonth}
        decreaseMonth={decreaseMonth}
        increaseMonth={increaseMonth}
        customHeaderCount={customHeaderCount}
        prevMonthButtonDisabled={prevMonthButtonDisabled}
        currentMonth={
          value
            ? moment(value).format("MM") - 1
            : moment().format("MM") - 1
        }
        currentYear={
          value ? moment(value).format("YYYY") : moment().format("YYYY")
        }
      />
    );
  };

  return (
    <div className="dateCustom">
      <DatePickerCustom
        value={
          value
            ? moment(value)
                .add(543, "year")
                .format("DD/MM/YYYY")
            : value
        }
        name={name}
        placeholderText={placeholder}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          customHeaderCount,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) =>
          renderCustomHeader(
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            customHeaderCount,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          )
        }
        onChange={(date) => {
          onChange(date);
        }}
        disabled={disabled}
        locale={th}
        dateFormat="dd MM yyyy"
        className={`myDate ${className}`}
        style={{ ...DateStyle, backgroundColor: disabled ? "#F2F3EE" : "" }}
      />
      {/* <input
        type={"text"}
        onFocus={onFocus}
        ref={dateRef}
        disabled={disabled}
        onClick={handleButtonClick}
        value={value}
        name={name}
        {...props}
        className={`myDate ${className}`}
        style={{ ...DateStyle, backgroundColor: disabled ? "#F2F3EE" : "" }}
        placeholder={placeholder}
      ></input> */}
    </div>
  );
}

const formattedDate = (date) => {
  return moment(date)
    .add(543, "year")
    .format("MMMM YYYY");
};

const CustomHeader = ({
  date,
  value,
  decreaseMonth,
  changeYear,
  changeMonth,
  increaseMonth,
  customHeaderCount,
  prevMonthButtonDisabled,
  currentMonth,
  currentYear,
}) => {
  useEffect(() => {
    changeYear(currentYear);
    changeMonth(currentMonth);
  }, []);

  return (
    <div
      style={{
        margin: 10,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        type="button"
        className={
          "react-datepicker__navigation react-datepicker__navigation--previous"
        }
        style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
          }
        >
          {"<"}
        </span>
      </button>
      <span className="react-datepicker__current-month">
        {formattedDate(date)}
      </span>
      <button
        aria-label="Next Month"
        type="button"
        className={
          "react-datepicker__navigation react-datepicker__navigation--next"
        }
        style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
        onClick={increaseMonth}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
          }
        >
          {">"}
        </span>
      </button>
    </div>
  );
};

const CustomHeaderDropdown = ({
  changeYear,
  changeMonth,
  decreaseMonth,
  customHeaderCount,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  years,
  months,
  currentYear,
  currentMonth,
}) => {
  const [indexMonth, setIndexMonth] = useState(currentMonth)
  const [indexYear, setIndexYear] = useState(currentYear)
  
  useEffect(() => {
    changeMonth(currentMonth);
    changeYear(currentYear);
  }, []);

  return (
    <div
      style={{
        margin: 10,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        type="button"
        className={
          "react-datepicker__navigation react-datepicker__navigation--previous"
        }
        style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
          }
        >
          {"<"}
        </span>
      </button>
      <select
        value={indexYear}
        onChange={({ target: { value } }) => {
          setIndexYear(value)
          changeYear(value);
        }}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {moment(option)
              .add(543, "year")
              .format("YYYY")}
          </option>
        ))}
      </select>

      <select
        value={months[indexMonth]}
        onChange={({ target: { value } }) => {
          console.log(months.indexOf(value));
          setIndexMonth(months.indexOf(value))
          changeMonth(months.indexOf(value))
        }}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        type="button"
        className={
          "react-datepicker__navigation react-datepicker__navigation--next"
        }
        style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
          }
        >
          {">"}
        </span>
      </button>
    </div>
  );
};
