import React, { useState, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { isEqual } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  NoRecordsFoundMessage,
  getHandlerTableChange,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import * as actions from "../_redux/ManageChildrensActions";
import * as uiHelpers from "./ManageChildrensUIHelpers";
import { useManageChildrensUIContext } from "./ManageChildrensUIContext";
import { Pagination } from "../../../../_metronic/_partials/controls";
import {
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Icon,
} from "@chakra-ui/react";
import { CiSearch } from "react-icons/ci";
import { RiSearchEyeLine } from "react-icons/ri";
import { FiChevronLeft } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";
import { PiPencilSimpleDuotone } from "react-icons/pi";
import { CgTrash } from "react-icons/cg";
import {AiFillSetting} from 'react-icons/ai' 
import { useToast } from "@chakra-ui/react";

export function ManageChildrensTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const manageChildrensUIContext = useManageChildrensUIContext();
  const manageChildrensUIProps = useMemo(() => {
    return {
      ids: manageChildrensUIContext.ids,
      queryParams: manageChildrensUIContext.queryParams,
      setQueryParams: manageChildrensUIContext.setQueryParams,
    };
  }, [manageChildrensUIContext]);

  const { currentState, authState } = useSelector(
    (state) => ({
      currentState: state.manageChildrens,
      authState: state.auth.user,
    }),
    shallowEqual
  );

  const [state, setState] = useState({
    filter: "",
  });

  const { totalCount, entities, actionsLoading, childrenCenter } = currentState;

  const { ROLE } = authState;

  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (ROLE == 2 || ROLE == 4) {
      setCanEdit(false);
    } else {
      setCanEdit(true);
    }
  }, [ROLE]);

  useEffect(() => {
    dispatch(actions.getChildrenCenterById(id));
  }, []);

  useEffect(() => {
    dispatch(
      actions.getManageChildrensList(manageChildrensUIProps.queryParams, id)
    );
  }, [manageChildrensUIProps.queryParams, dispatch]);

  useEffect(() => {
    applyFilter(state);
  }, [state]);

  const onClickInfo = (id) => {
    if (location.pathname.includes("/evaluate-children")) {
      history.push(`/evaluate-children/${id}/profile`);
    } else {
      history.push(`/history-childrens/${id}/profile`);
    }
  };

  const onClickEdit = (childrenId) => {
    if (location.pathname.includes("/nutrition-information")) {
      history.push(`/nutrition-information/${childrenId}/profile`);
    } else {
      history.push(
        `/history-childrens/${id}/manage-childrens/${childrenId}/edit`
      );
    }
  };

  const onClickNew = () => {
    history.push(`/history-childrens/${id}/manage-childrens/new`);
  };

  const onClickDelete = (childrenId) => {
    history.push(
      `/history-childrens/${id}/manage-childrens/${childrenId}/delete`
    );
  };

  const applyFilter = (values) => {
    const newQueryParams = {
      ...manageChildrensUIProps.queryParams,
      ...values,
    };
    if (!isEqual(newQueryParams, manageChildrensUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      manageChildrensUIProps.setQueryParams(newQueryParams);
    }
  };

  const columns = [
    {
      dataField: "",
      text: "ลำดับ",
      style: { width: "10%" },
      formatter: (cell, row, rowIndex) => {
        return (
          rowIndex +
          (manageChildrensUIProps.queryParams.pageNumber - 1) *
            manageChildrensUIProps.queryParams.pageSize +
          1
        );
      },
    },
    {
      dataField: "ID_CODE",
      text: "รหัสประจำตัว",
    },
    {
      dataField: "",
      text: "ชื่อ - นามสกุล",
      formatter: (cell, row, rowIndex) => {
        return `${row.FIRSTNAME} ${row.LASTNAME}`;
      },
    },
    {
      dataField: "action",
      text: "จัดการ",
      formatter: ActionCell,
      formatExtraData: {
        onClickEdit: onClickEdit,
        onClickInfo: onClickInfo,
        onClickDelete: onClickDelete,
        canEdit: canEdit,
        evaluate: location.pathname.includes("/evaluate-children"),
        nutrition: location.pathname.includes("/nutrition-information"),
        manageChildrens: location.pathname.includes("manage-childrens"),
      },
      style: { width: "10%" },
    },
  ];

  useEffect(() => {
    console.log(location.pathname);
  }, []);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: manageChildrensUIProps.queryParams.pageSize,
    page: manageChildrensUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  return (
    <>
      <div className="mb-10">
        <Button
          onClick={() => history.goBack()}
          leftIcon={<FiChevronLeft style={{ fontSize: 20 }} />}
          size="lg"
          style={{ color: "#ffff", backgroundColor: "#58A3BC" }}
        >
          ย้อนกลับไป
        </Button>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div>
          <div className="font-weight-bolder text-f2" style={{ fontSize: 16 }}>
            {location.pathname.includes("/evaluate-children")
              ? "ระบบประเมินผลภาวะโภชนาการเด็กเป็นรายบุคคล"
              : location.pathname.includes("/evaluate-children")
              ? "ระบบจัดการข้อมูลภาวะโภชนาการ"
              : "ระบบจัดการข้อมูลประวัติสุขภาพของเด็กก่อนวัยเรียน"}
          </div>
          <div className="text-primary font-size-h5 font-weight-bolder">
            {childrenCenter?.NAME} ต.{childrenCenter?.TAMBON_NAME} อ.{childrenCenter?.AMPHOE_NAME} จ.{childrenCenter?.PROVINCE_NAME}
          </div>
          <div className="text-f2" style={{ fontSize: 14 }}>
            จำนวนเด็กเล็กทั้งหมด
            <span className="text-primary mx-2">{totalCount}</span>
            คน
          </div>
        </div>
        <div
          style={{
            width:
              location.pathname.includes("/evaluate-children") ||
              location.pathname.includes("/nutrition-information")
                ? "40%"
                : "auto",
          }}
        >
          {location.pathname.includes("/evaluate-children") ||
          location.pathname.includes("/nutrition-information") ? (
            <InputGroup
              size="lg"
              style={{ border: "1px solid #979797", borderRadius: 5 }}
            >
              <InputLeftElement
                pointerEvents="none"
                children={<CiSearch color="gray" />}
              />
              <Input
                type="text"
                placeholder="ค้นหา"
                style={{ border: "none", backgroundColor: "transparent" }}
                onChange={(e) => {
                  setState({ ...state, filter: e.target.value });
                }}
              />
            </InputGroup>
          ) : (
            canEdit && (
              <Button
                size={"lg"}
                className="text-white bg-primary"
                onClick={onClickNew}
                leftIcon={<AiFillPlusCircle style={{ fontSize: 20 }} />}
              >
                เพิ่มข้อมูลสุขภาพเด็ก
              </Button>
            )
          )}
        </div>
      </div>
      {!location.pathname.includes("/evaluate-children") ||
        (location.pathname.includes("/nutrition-information") && (
          <div className="mb-5">
            <div style={{ width: "40%" }}>
              <InputGroup
                size="lg"
                style={{ border: "1px solid #979797", borderRadius: 5 }}
              >
                <InputLeftElement
                  pointerEvents="none"
                  children={<CiSearch color="gray" />}
                />
                <Input
                  type="text"
                  placeholder="ค้นหา"
                  style={{ border: "none", backgroundColor: "transparent" }}
                  onChange={(e) => {
                    setState({ ...state, filter: e.target.value });
                  }}
                />
              </InputGroup>
            </div>
          </div>
        ))}

      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={actionsLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-vertical-center overflow-hidden text-dark font-size-xs font-weight-bolder"
                bodyClasses="text-dark font-size-xs font-weight-bolder"
                bootstrap4
                remote
                keyField="id"
                columns={columns}
                data={entities || []}
                onTableChange={getHandlerTableChange(
                  manageChildrensUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

function ActionCell(
  cellContent,
  row,
  rowIndex,
  {
    onClickEdit,
    onClickInfo,
    onClickDelete,
    evaluate,
    nutrition,
    canEdit,
    manageChildrens,
  }
) {
  return (
    <>
      <div className="d-flex">
        {!nutrition && (
          <button
            className="btn mx-2"
            onClick={() => onClickInfo(row.ID)}
            style={{
              width: 45,
              backgroundColor: "#979797",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              as={RiSearchEyeLine}
              style={{ color: "white", fontSize: 20 }}
            />
          </button>
        )}
        {((!evaluate && manageChildrens && canEdit) || nutrition) && (
          <button
            className="btn btn-warning mx-2"
            onClick={() => onClickEdit(row.ID)}
            style={{
              width: 45,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {canEdit ? (
              <Icon
                as={PiPencilSimpleDuotone}
                style={{ color: "white", fontSize: 20 }}
              />
            ) : (
              <Icon
                as={AiFillSetting}
                style={{ color: "white", fontSize: 20 }}
              />
            )}
          </button>
        )}
        {!evaluate && !nutrition && canEdit && (
          <button
            className="btn btn-danger mx-2"
            onClick={() => onClickDelete(row.ID)}
            style={{
              width: 45,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon as={CgTrash} style={{ color: "white", fontSize: 20 }} />
          </button>
        )}
      </div>
    </>
  );
}

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-5">
    <span className="text-f2">แสดง</span> {from} - {to}{" "}
    <span className="text-f2">จาก</span> {size}
  </span>
);
