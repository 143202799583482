export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "25", value: 25 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  START_DATE: new Date(),
  END_DATE: new Date(),
  pageNumber: 1,
  pageSize: 25,
};
