import React from "react";
import { UserProfile } from "../HomePage/UserProfile";
// import { ChangeLanguage } from "../HomePage/ChangeLanguage";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { MenuTop } from "./MenuTop";

export function Header() {
  const { t } = useTranslation();
  const { currentState } = useSelector(
    (state) => ({ currentState: state.auth }),
    shallowEqual
  );
  const getRoleName = (role) => {
    const obj = {
      "1": "ผู้ดูแลระบบ",
      "2": "เจ้าหน้าที่สาธารณสุข",
      "3": "ครูศูนย์เด็กเล็ก",
      "4": "เจ้าหน้าที่ อบต.",
    };
    return obj[role];
  };

  return (
    <div className="header header-fixed  d-flex align-items-center justify-content-between shadow-sm">
      <div
        className="mx-8 p-4 font-size-sm font-weight-boldest rounded text-dark text-center"
        style={{ backgroundColor: "#F4D160" }}
      >
        {getRoleName(currentState.user.ROLE)}
      </div>
      <div className="d-flex mx-5">
        {/* <ChangeLanguage /> */}
        <UserProfile />
      </div>
    </div>
  );
}
