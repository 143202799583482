import axios from "axios";
import * as config from "../../../../config";

const { API_ENDPOINT } = config;

export function getNutritionFood(params, childrenCenterId) {
  let formatParams = `?CHILDREN_ID=${childrenCenterId}`;
  Object.keys(params).map((item, index) => {
    if (params[item] == "" && item != "filter") return;
    if (formatParams == "") {
      return (formatParams += `?${item}=${params[item]}`);
    }
    formatParams += `&${item}=${params[item]}`;
  });
  return axios.get(`${API_ENDPOINT}/childrenFood/${formatParams}`);
}

export function getChildrensCenterById(id) {
  return axios.get(`${API_ENDPOINT}/masterdata/childrenCenter/all?id=${id}`);
}

export function getInfoChildrenById(id) {
  return axios.get(`${API_ENDPOINT}/children/info?childrenProfileId=${id}`);
}

export function getFoodDetail(date, childrenId) {
  return axios.get(
    `${API_ENDPOINT}/childrenFood/detail?childrenProfileId=${childrenId}&date=${date}`
  );
}

export function deleteFoodDetail(date, childrenId, mealTimeId) {
  return axios.delete(
    `${API_ENDPOINT}/childrenFood/?MEAL_TIME_ID=${mealTimeId}&CHILDREN_PROFILE_ID=${childrenId}&DATE=${date}`
  );
}
