export const Nutrients = {
  ENER: {
    nameTH: "พลังงาน",
    type: "kcal",
  },
  PRO: {
    nameTH: "โปรตีน",
    type: "g",
  },
  SUGAR: {
    nameTH: "น้ำตาล",
    type: "g",
  },
  SATFAT: {
    nameTH: "ไขมันอิ่มตัว",
    type: "g",
  },
  CHOLE: {
    nameTH: "คอเลสเตอรอล",
    type: "mg",
  },
  CA: {
    nameTH: "แคลเซียม",
    type: "mg",
  },
  P: {
    nameTH: "ฟอสฟอรัส",
    type: "mg",
  },
  FE: {
    nameTH: "ธาตุเหล็ก",
    type: "mg",
  },
  K: {
    nameTH: "โปตัสเซียม",
    type: "mg",
  },
  NA: {
    nameTH: "โซเดียม",
    type: "mg",
  },
  CU: {
    nameTH: "ทองแดง",
    type: "mg",
  },
  MG: {
    nameTH: "แมกนีเซียม",
    type: "mg",
  },
  SE: {
    nameTH: "ซีลีเนียม",
    type: "mcg",
  },
  ZN: {
    nameTH: "สังกะสี",
    type: "mg",
  },
  VITA: {
    nameTH: "วิตามินเอ",
    type: "RAE",
  },
  VITB1: {
    nameTH: "วิตามินบี1/ไทอะมีน",
    type: "mg",
  },
  VITB2: {
    nameTH: "วิตามินบี2/ไรโบฟลาวิน",
    type: "mg",
  },
  VITB6: {
    nameTH: "วิตามินบี6/ไพริด็อกซิน",
    type: "mg",
  },
  VITB12: {
    nameTH: "วิตามินบี12/ไพริด็อกซิน",
    type: "mcg",
  },
  VITC: {
    nameTH: "วิตามินซี/แอสคอรฺบิค",
    type: "mg",
  },
  NIA: {
    nameTH: "วิตามินบี3/ไนอะซิน",
    type: "mg",
  },
  VITE: {
    nameTH: "วิตามินอี",
    type: "mg",
  },
  FBD: {
    nameTH: "ปริมาณเส้นใยอาหาร",
    type: "g",
  },
  CHO_D: {
    nameTH: "ร้อยละการกระจายพลังงานจากคาร์โบไฮเดรต",
    type: "",
  },
  FAT_D: {
    nameTH: "ร้อยละการกระจายพลังงานจากไขมัน",
    type: "",
  },
};
