import React from "react";
import { Modal } from "react-bootstrap";

export function SuccessDialog({ show, onHide }) {

  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide()}
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: 500,
            }}
          >
            <div>
              <div className="d-flex justify-content-center align-items-center mb-8">
                <span className="navi-icon">
                  <i
                    class="fa fa-check-circle icon-8x"
                    style={{ color: "#6B8A47" }}
                  ></i>
                </span>
              </div>
              <div className="text-primary font-weight-boldest font-size-h3 text-center mt-3">
                รีเซ็ตรหัสผ่านสำเร็จ
              </div>
              <div
                className="text-center font-size-sm text-dark font-weight-bolder"
                style={{ marginBottom: "5rem" }}
              >
                รหัสผ่านของคุณถูกรีเซ็ตเรียบร้อยแล้ว
                <br />
                คุณสามารถล็อคอินเข้าใช้งานในระบบได้เลยตอนนี้
              </div>
              <div className="d-flex justify-content-center align-items-center my-5">
                <button
                  type="button"
                  className="btn btn-primary font-size-sm"
                  onClick={onHide}
                >
                  เข้าสู่ระบบ
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
