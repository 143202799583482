import React, { useMemo, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/ChildrensProfileActions";
import { useChildrensProfileUIContext } from "./ChildrensProfileUIContext";
import { useToast } from "@chakra-ui/react";

export function DeleteDialog({ show, onHide }) {
  const childrensProfileUIContext = useChildrensProfileUIContext();
  const childrensProfileUIProps = useMemo(() => {
    return {
      ids: childrensProfileUIContext.ids,
      queryParams: childrensProfileUIContext.queryParams,
      setQueryParams: childrensProfileUIContext.setQueryParams,
    };
  }, [childrensProfileUIContext]);

  const { id, childrenId } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.childrensProfile,
    }),
    shallowEqual
  );

  const { actionsLoading, error } = currentState;

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState())
    }
  }, [error]);

  const [childrensProfile, setChildrensProfile] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(actions.getWeightHeightById(id)).then((res) => {
        console.log(res);
        setChildrensProfile({ ...res });
      });
    }
  }, []);

  const onClickCancel = () => {
    onHide();
  };

  const onClickDelete = () => {
    dispatch(
      actions.deleteWeightHeightById(
        { ID: id, CHILDREN_ID: childrenId },
        childrensProfileUIProps.queryParams
      )
    ).then(() => {
      const newQueryParams = { ...childrensProfileUIProps.queryParams };
      newQueryParams.pageNumber = 1;
      childrensProfileUIProps.setQueryParams(newQueryParams);
      toast({
        title: "ลบประวัติน้ำหนักและส่วนสูง สำเร็จ!",
        description: "คุณได้ทำการลบประวัติข้อมูลสุขภาพเด็กสำเร็จ",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onHide();
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: 300,
            }}
          >
            <div>
              <div className="p-10">
                <div className="text-center font-size-sm text-dark font-weight-boldest my-10">
                  <div className="text-primary font-size-h3 mb-2">
                    ลบประวัติน้ำหนักและส่วนสูง
                  </div>
                  <div className="">
                    คุณแน่ใจหรือไม่ ? ที่ต้องการลบประวัติน้ำหนักและส่วนสูง
                  </div>
                  <div>
                    น้ำหนัก{" "}
                    <span className="text-danger">
                      {childrensProfile?.WEIGHT || "-"}
                    </span>{" "}
                    กิโลกรัม ส่วนสูง{" "}
                    <span className="text-danger">
                      {childrensProfile?.HEIGHT || "-"}{" "}
                    </span>{" "}
                    เซนติเมตร
                  </div>
                  <div className="">ออกจากระบบ</div>
                </div>
                <div className="d-flex justify-content-between w-100 py-5 border-top mt-10">
                  <button
                    className="btn text-dark font-size-sm font-weight-bolder"
                    style={{ backgroundColor: "#E0DEDE" }}
                    onClick={onClickCancel}
                  >
                    ยกเลิก
                  </button>
                  <button
                    disabled={actionsLoading}
                    className="btn btn-primary font-size-sm font-weight-bolder d-flex align-items-center"
                    onClick={onClickDelete}
                  >
                    {actionsLoading && (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    ลบประวัติข้อมูล
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
