/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Brand } from "../../../components/HomePage/Brand";
import { FooterBar } from "../../../components/HomePage/FooterBar";
export function TermPage() {
  const history = useHistory();

  const [fileDoc, setFileDoc] = useState(null);

  useEffect(() => {
    axios
      .get("http://e-learning.mfessplayground.io/api/manualDoc")
      .then((res) => {
        const filterTerm = res.data.find((item) => item.MANUAL_DOC_ID == 4);
        setFileDoc(filterTerm);
      });
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <header
        className="p-3"
        style={{
          background: "linear-gradient(90deg, #000000 0%, #333333 100%)",
        }}
      >
        <div className="container align-items-center">
          <div className="col-6">
            <div className="d-flex">
              <Brand />
            </div>
          </div>
          <div className="col-6">{/* <Brand /> */}</div>
        </div>
      </header>
      <div className="text-center p-10">
        <h2 className="text-primary" style={{ fontWeight: "bold" }}>
          ข้อกำหนดและเงื่อนไขการใช้งาน และนโยบายความเป็นส่วนตัว
        </h2>
        <h3 className="mb-10" style={{ color: "#544216" }}>
          บทเรียนจริยธรรมงานวิจัยออนไลน์
        </h3>
        <iframe src={fileDoc?.URL} width="80%" height="700px" seamless></iframe>
      </div>
      <footer>
        <FooterBar />
      </footer>
    </div>
  );
}
