import * as requestFromServer from "./dashboardCrud";
import { dashboardSlice, callTypes } from "./dashboardSlice";

const { actions } = dashboardSlice;

export const getChildrenCenter = (authToken) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const permission = getState().auth.menu.find(
      (item) => item.NAME == "แดชบอร์ด"
    ).PERMISSION;
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getChildrenCenter(authToken)
      .then((res) => {
        let data = []
        if (permission == "FULL_ACCESS") {
          data.push({ID: "", NAME: "ทั้งหมด"})
        }
        data = [...data, ...res.data]
        dispatch(actions.setChildrenCenter(data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getDashboardByChildrenCenter = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getDashboarByCenterId(id)
      .then((res) => {
        dispatch(actions.setDashboardData(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const changePassword = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    requestFromServer
      .changePassword(payload)
      .then((response) => {
        dispatch(actions.stopCall({ callType: callTypes.action }));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const clearError = () => (dispatch) => {
  dispatch(actions.clearError());
};

export const clearDashboardData = () => (dispatch) => {
  dispatch(actions.setDashboardData(null));
};
