import * as requestFromServer from "./generalCrud";
import { generalSlice, callTypes } from "./generalSlice";
import * as masterData from "../../config";

const { actions } = generalSlice;

export const getLocation = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const province = await masterData.getProvince();
    const amphoe = await masterData.masterDataAmphures();
    const tambon = await masterData.masterDataDistricts();
    const mealTime = await masterData.masterDataMealTime();

    Promise.all([province, amphoe, tambon, mealTime])
      .then((res) => {
        const payload = {
          province: res[0].data,
          amphoe: res[1].data,
          tambon: res[2].data,
          mealTime: res[3].data,
        };
        dispatch(actions.setLocation(payload));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getDepartment = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    masterData
      .masterDataDepartment()
      .then((res) => {
        dispatch(actions.setDepartment(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getRole = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    masterData
      .masterDataRole()
      .then((res) => {
        dispatch(actions.setRole(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const foceLogin = (payload) => (dispatch) => {
  dispatch(actions.setForceLogin(payload));
};
