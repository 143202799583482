import React, { useEffect, useState } from "react";
import { HeaderBar } from "../components/HomePage/HeaderBar";
import { FooterBar } from "../components/HomePage/FooterBar";
import LoginDialog from "../components/HomePage/LoginDialog";
import ForgotDialog from "../components/HomePage/ForgotDialog";
import { ForgotSuccessDialog } from "../components/HomePage/ForgotSuccessDialog";
import { useHistory, BrowserRouter, useLocation } from "react-router-dom";
import { IoArrowUp } from "react-icons/io5";
import { Box, IconButton } from "@chakra-ui/react";

export function LayoutAuth({ children }) {
  const history = useHistory();
  const isShowLoginModal = useLocation().search;
  const [isVisible, setIsVisible] = useState(false);
  const [state, setState] = useState({
    modalLogin: false,
    modalForgotPassword: false,
    modalForgotSuccess: false,
    modalSignUp: false,
    modalSignUpSuccess: false,
  });

  useEffect(() => {
    if (isShowLoginModal) {
      setState({
        ...state,
        modalLogin: true,
      });
    }
  }, []);

  const onShowModal = (typeModal) => {
    setState({
      modalLogin: false,
      modalForgotPassword: false,
      modalForgotSuccess: false,
      modalSignUp: false,
      modalSignUpSuccess: false,
      [typeModal]: true,
    });
  };

  const onHideModal = (typeModal) => {
    setState({
      modalLogin: false,
      modalForgotPassword: false,
      modalForgotSuccess: false,
      modalSignUp: false,
      modalSignUpSuccess: false,
      [typeModal]: false,
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {state.modalForgotSuccess && (
        <ForgotSuccessDialog
          show={true}
          onHide={onHideModal}
          onShow={onShowModal}
        />
      )}
      {state.modalForgotPassword && (
        <ForgotDialog show={true} onHide={onHideModal} onShow={onShowModal} />
      )}
      {state.modalLogin && (
        <LoginDialog show={true} onHide={onHideModal} onShow={onShowModal} />
      )}

      <header className="p-3 bg-primary">
        <HeaderBar showModal={onShowModal} />
      </header>
      <div>{children}</div>
      <div>
        <FooterBar />
      </div>
      {isVisible && (
        <Box
          onClick={scrollToTop}
          position="fixed"
          bottom="20px"
          right={["16px", "84px"]}
          zIndex={3}
        >
          <IconButton
            icon={<IoArrowUp />}
            colorScheme="whatsapp"
            style={{
              opacity: 1,
              backgroundColor: "#546E86",
              height: 100,
              width: 60,
              fontSize: 30,
            }}
          ></IconButton>
        </Box>
      )}
    </>
  );
}
