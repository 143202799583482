import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { DatePicker } from "../../../components/DatePicker";
import { useToast } from "@chakra-ui/react";
import { useChildrensProfileUIContext } from "./ChildrensProfileUIContext";
import * as actions from "../_redux/ChildrensProfileActions";
import moment from "moment";
import "moment/locale/th";

moment.locale("th");

export function EditDialog({ show, onHide }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const childrensProfileUIContext = useChildrensProfileUIContext();
  const childrensProfileUIProps = useMemo(() => {
    return {
      ids: childrensProfileUIContext.ids,
      queryParams: childrensProfileUIContext.queryParams,
      setQueryParams: childrensProfileUIContext.setQueryParams,
    };
  }, [childrensProfileUIContext]);

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.childrensProfile,
    }),
    shallowEqual
  );

  const { actionsLoading, error } = currentState;

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState())
    }
  }, [error]);

  const [initialValues, setInitialValues] = useState({
    ID: null,
    WEIGHT: null,
    HEIGHT: null,
    CHILDREN_PROFILE_ID: parseInt(id),
    DATE: null,
  });

  useEffect(() => {
    dispatch(actions.getWeightHeightById(id)).then((res) => {
      setInitialValues({
        ...initialValues,
        ...res,
        DATE: moment(res.DATE).format("YYYY-MM-DD"),
      });
    });
  }, [id]);

  const AddSchema = Yup.object().shape({
    WEIGHT: Yup.number().required(),
    HEIGHT: Yup.number().required(),
    DATE: Yup.date().required(),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AddSchema,
    onSubmit: (values) => {
      console.log(`submit`);
      console.log(values);
      dispatch(
        actions.postWeightHeight(values, childrensProfileUIProps.queryParams)
      ).then(() => {
        toast({
          title: "แก้ไขประวัติน้ำหนักและส่วนสูง สำเร็จ!",
          description: "คุณได้ทำการแก้ไขประวัติน้ำหนักและส่วนสูงสำเร็จ",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onHide();
      });
    },
  });

  useEffect(() => {
    console.log(formik.values.DATE);
  }, [formik.values]);

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={onHide}
      size="lg"
      centered
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Body>
        <div className="d-flex justify-content-between w-100 pb-5 border-bottom">
          <div className="text-primary font-weight-boldest font-size-h5">
            แก้ไขประวัติน้ำหนักและส่วนสูง
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="container-fluid p-5 d-flex flex-column font-weight-bolder"
        >
          <div className="row mb-3">
            <div className="col px-0 text-dark-75 font-size-xs ">
              <div className="required">วันที่บันทึก</div>
            </div>
            <div className="col px-0 d-flex">
              <DatePicker
                style={{ minWidth: 200 }}
                placeholder={"เลือกวันที่"}
                className={`${
                  formik.touched.DATE && formik.errors.DATE
                    ? "border-danger"
                    : "border-f2"
                }`}
                onFocus={() =>
                  formik.setTouched({
                    ...formik.touched,
                    ["DATE"]: true,
                  })
                }
                name={"DATE"}
                value={formik.values.DATE}
                onChange={(e) => {
                  formik.setFieldValue('DATE', moment(e).format('YYYY-MM-DD'))
                }}
                // {...formik.getFieldProps("DATE")}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col px-0 text-dark-75 font-size-xs ">
              <div className="required">น้ำหนัก</div>
            </div>
            <div className="col px-0 d-flex">
              <input
                type="number"
                className={`form-control ${
                  formik.touched.WEIGHT && formik.errors.WEIGHT
                    ? "border-danger"
                    : "border-f2"
                } font-size-sm text-dark`}
                style={{ backgroundColor: "#ffffff", fontSize: "1rem" }}
                name="WEIGHT"
                {...formik.getFieldProps("WEIGHT")}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col px-0 text-dark-75 font-size-xs ">
              <div className="required">ส่วนสูง</div>
            </div>
            <div className="col px-0 d-flex">
              <input
                type="number"
                placeholder="ส่วนสูง"
                style={{ backgroundColor: "#ffffff", fontSize: "1rem" }}
                className={`form-control ${
                  formik.touched.HEIGHT && formik.errors.HEIGHT
                    ? "border-danger"
                    : "border-f2"
                } font-size-sm text-dark`}
                name="HEIGHT"
                {...formik.getFieldProps("HEIGHT")}
              />
            </div>
          </div>
        </form>
        <div
          className="d-flex align-items-center py-5 border-top"
          style={{ justifyContent: "space-between" }}
        >
          <button
            type="button"
            className="btn font-size-sm fw-bold"
            onClick={() => onHide()}
            style={{ backgroundColor: "#E0DEDE" }}
          >
            ยกเลิก
          </button>
          <button
            type="button"
            className="btn btn-primary font-size-sm fw-bold"
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={!formik.isValid || actionsLoading}
          >
            {actionsLoading && (
              <span
                className="spinner-border spinner-border-sm mr-3"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            บันทึก
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
