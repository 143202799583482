import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import * as actions from "../../../redux/Banner/bannerAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
export function Banner() {
  return (
    <div
      style={{
        height: 500,
        background: 'url("/media/home/Mask_group.png")',
        backgroundSize: "50% 100%",

        backgroundPositionX: "right",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <div>
        <p style={{ fontSize: 30, fontWeight: 600 }}>
          ระบบฐานข้อมูลภาวะโภชนาการ
        </p>
        <p className="text-primary fw-bold" style={{ fontSize: 60 }}>
          “ของเด็กวัยก่อนเรียน”
        </p>
        <span
          className="font-size-sm text-primary"
          style={{ fontSize: 25, fontWeight: 600 }}
        >
          ในศูนย์พัฒนาเด็กเล็กในพื้นที่ อำเภอแม่ฟ้าหลวง จังหวัดเชียงราย
        </span>
      </div>
      <div></div>
    </div>
  );
}
