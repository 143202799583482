import React, { useEffect, useState } from "react";
import { EvaluateChildrenTable } from "./EvaluateChildrenTable";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { EvaluateChildrenUIProvider } from "./EvaluateChildrenUIContext";
import { ProfileInfo } from "./ProfileInfo";
import { Button } from "@chakra-ui/react";
import { FiChevronLeft } from "react-icons/fi";
import * as actions from "../_redux/EvaluateChildrenActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { Nutrients } from "../../../../_metronic/_helpers";
import moment from "moment";

export function EvaluateChildrenPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();
  const { id } = useParams();

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.evaluateChildren,
    }),
    shallowEqual
  );

  const { childrenCenter, childrenProfile, error, foodEstimate } = currentState;

  const [evaludateState, setEvaluateState] = useState("PROFILE"); // PROFILE or RESULT

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState());
    }
  }, [error]);

  useEffect(() => {
    dispatch(actions.getEstimateByChildrenProfileId(id));
  }, []);

  useEffect(() => {
    if (childrenProfile) {
      dispatch(
        actions.getChildrenCenterById(
          childrenProfile?.INFO.CHILDREN.CHILDREN_CENTER_ID
        )
      );
    }
  }, [childrenProfile]);

  const [date, setDate] = useState(null);

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const param = searchParams.get("date");
      dispatch(actions.getFoodEstimateByChildrenProfileId(id, param));
      setDate(param);
      setEvaluateState("RESULT");
    } else {
      setEvaluateState("PROFILE");
    }
  }, [location]);

  const onHide = () => {
    history.goBack();
  };

  const dietaryDetail = () => {
    history.push(
      `/evaluate-children/${childrenProfile.INFO.AGE.years}/age-detail`
    );
  };

  return (
    <EvaluateChildrenUIProvider>
      <div className="mt-5">
        <Button
          onClick={() => history.goBack()}
          leftIcon={<FiChevronLeft style={{ fontSize: 20 }} />}
          size="lg"
          style={{ color: "#ffff", backgroundColor: "#58A3BC" }}
        >
          ย้อนกลับไป
        </Button>
      </div>
      <div className="d-flex my-5" style={{ justifyContent: "space-between" }}>
        {evaludateState == "PROFILE" ? (
          <div
            className="text-primary"
            style={{
              backgroundColor: "#E4F1FF",
              borderRadius: 5,
              padding: 10,
              fontSize: 14,
            }}
          >
            ข้อมูลสุขภาพ ณ ปัจจุบัน
          </div>
        ) : (
          <>
            <div>
              <div className="text-primary font-size-h5 font-weight-bolder">
                ผลการประเมินผลภาวะโภชนาการ
              </div>
              <div className="text-f2" style={{ fontSize: 14 }}>
                จากเกณฑ์ประเมินสารอาหารที่ได้รับจากการบริโภค
              </div>
            </div>
            <div className="d-flex" style={{ alignItems: "center" }}>
              <div className="text-dark mr-5" style={{ fontSize: 14 }}>
                ประจำวันที่ :{" "}
                {moment(date)
                  .add(543, "year")
                  .format("D MMMM YYYY")}
              </div>
              <button onClick={dietaryDetail} className="btn btn-primary">
                คำแนะนำตามช่วงอายุของเด็ก
              </button>
            </div>
          </>
        )}
      </div>

      <div className="row">
        <div className="col-md-6 col-12">
          <div className="p-10 shadow-sm rounded">
            <ProfileInfo
              childrenProfile={childrenProfile}
              childrenCenter={childrenCenter}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div
            className="d-flex flex-column"
            style={{
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div className="row" style={{ height: "100%" }}>
              <div className="col-4">
                <div
                  className="py-10 px-5 d-flex text-center flex-column text-primary shadow-sm rounded"
                  style={{
                    fontWeight: 700,
                    height: "100%",
                    justifyContent: "space-between",
                    fontSize: 14,
                  }}
                >
                  <div className="d-flex flex-column">
                    <span style={{ fontSize: 16 }}>น้ำหนักตามเกณฑ์ส่วนสูง</span>
                    <span
                      className="text-f2"
                      style={{ fontSize: 12, fontWeight: "normal" }}
                    >
                      (Weight for Height : W/H)
                    </span>
                  </div>
                  <span
                    className="text-f2"
                    style={{ fontSize: 12, fontWeight: "normal" }}
                  >
                    ผลการประเมิน
                  </span>
                  <div>
                    <div
                      className="p-3 mb-3"
                      style={{
                        backgroundColor:
                          childrenProfile?.RESULT?.WEIGHT_FOR_HEIGHT == "สมส่วน"
                            ? "#D8EEBE"
                            : "#FFFBEB",
                        color:
                          childrenProfile?.RESULT?.WEIGHT_FOR_HEIGHT == "สมส่วน"
                            ? "#6B8A47"
                            : "#FEA73F",
                        borderRadius: 5,
                        fontWeight: 500,
                        fontSize: 20,
                      }}
                    >
                      {childrenProfile?.RESULT?.WEIGHT_FOR_HEIGHT}
                    </div>
                    <span style={{ fontSize: 12, fontWeight: "normal" }}>
                      {/* {childrenProfile?.RESULT?.WEIGHT_FOR_HEIGHT} */}
                    </span>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="py-10 px-5 d-flex text-center flex-column text-primary shadow-sm rounded"
                  style={{
                    fontWeight: 700,
                    height: "100%",
                    justifyContent: "space-between",
                    fontSize: 14,
                  }}
                >
                  <div className="d-flex flex-column">
                    <span style={{ fontSize: 16 }}>น้ำหนักตามอายุ</span>
                    <span
                      className="text-f2"
                      style={{ fontSize: 12, fontWeight: "normal" }}
                    >
                      (Weight for Age : W/A)
                    </span>
                  </div>
                  <span
                    className="text-f2"
                    style={{ fontSize: 12, fontWeight: "normal" }}
                  >
                    ผลการประเมิน
                  </span>
                  <div>
                    <div
                      className="p-3 mb-3"
                      style={{
                        backgroundColor:
                          childrenProfile?.RESULT?.WEIGHT_FOR_AGE ==
                          "น้ำหนักตามเกณฑ์"
                            ? "#D8EEBE"
                            : "#FFFBEB",
                        color:
                          childrenProfile?.RESULT?.WEIGHT_FOR_AGE ==
                          "น้ำหนักตามเกณฑ์"
                            ? "#6B8A47"
                            : "#FEA73F",
                        borderRadius: 5,
                        fontWeight: 500,
                        fontSize: 20,
                      }}
                    >
                      {childrenProfile?.RESULT?.WEIGHT_FOR_AGE}
                    </div>
                    <span style={{ fontSize: 12, fontWeight: "normal" }}>
                      {/* {childrenProfile?.RESULT?.WEIGHT_FOR_AGE} */}
                    </span>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="py-10 px-5 d-flex text-center flex-column text-primary shadow-sm rounded"
                  style={{
                    fontWeight: 700,
                    height: "100%",
                    justifyContent: "space-between",
                    fontSize: 14,
                  }}
                >
                  <div className="d-flex flex-column">
                    <span style={{ fontSize: 16 }}>ส่วนสูงตามอายุ</span>
                    <span
                      className="text-f2"
                      style={{ fontSize: 12, fontWeight: "normal" }}
                    >
                      (Height for Age : W/A)
                    </span>
                  </div>
                  <span
                    className="text-f2"
                    style={{ fontSize: 12, fontWeight: "normal" }}
                  >
                    ผลการประเมิน
                  </span>
                  <div>
                    <div
                      className="p-3 mb-3"
                      style={{
                        backgroundColor:
                          childrenProfile?.RESULT?.HEIGHT_FOR_AGE ==
                          "สูงตามเกณฑ์"
                            ? "#D8EEBE"
                            : "#FFFBEB",
                        color:
                          childrenProfile?.RESULT?.HEIGHT_FOR_AGE ==
                          "สูงตามเกณฑ์"
                            ? "#6B8A47"
                            : "#FEA73F",
                        borderRadius: 5,
                        fontWeight: 500,
                        fontSize: 20,
                      }}
                    >
                      {childrenProfile?.RESULT?.HEIGHT_FOR_AGE}
                    </div>
                    <span style={{ fontSize: 12, fontWeight: "normal" }}>
                      {/* {childrenProfile?.RESULT?.HEIGHT_FOR_AGE} */}
                    </span>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light-white p-10 mt-5 shadow-sm rounded">
        {evaludateState == "PROFILE" ? (
          <EvaluateChildrenTable />
        ) : (
          <>
            <div
              className="d-flex"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <div>
                <div className="text-primary font-size-h5 font-weight-bolder">
                  เกณฑ์ปริมาณสารอาหารที่ได้รับจากการบริโภค
                </div>
                <div className="text-f2" style={{ fontSize: 14 }}>
                  ประเมินจากข้อมูลภาวะโภชนาการ
                </div>
              </div>
              <div className="d-flex" style={{ alignItems: "center" }}>
                <div
                  className="text-primary py-3 px-5"
                  style={{
                    backgroundColor: "#E4F1FF",
                    borderRadius: 5,
                    fontSize: 12,
                  }}
                >
                  <span
                    className="mr-3"
                    style={{ fontWeight: "bold", fontSize: 18 }}
                  >
                    {foodEstimate?.mealTime || "-"} มื้อ
                  </span>
                  {foodEstimate?.mealTime == 5 && (
                    <span className="text-dark">(ครบทุกมื้อ)</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6 col-12">
                <div
                  className="p-5 text-center"
                  style={{
                    backgroundColor: "#E8F5D8",
                    color: "#6B8A47",
                    borderRadius: 5,
                  }}
                >
                  <p style={{ fontSize: 16, fontWeight: 600 }}>
                    เกณฑ์ปริมาณสารอาหาร : ค่าปกติ
                  </p>
                  <div className="row">
                    {foodEstimate?.result["N"].map((item) => (
                      <div className="col-3 my-2">
                        <div
                          className="text-center p-5"
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: 5,
                            maxWidth: 110,
                          }}
                        >
                          <p style={{ fontSize: 16, fontWeight: 600 }}>
                            {Object.keys(item)}
                          </p>
                          <span style={{ display: "block" }}>
                            {Nutrients[Object.keys(item)].nameTH}
                          </span>
                          <span style={{ display: "block" }}>
                            {item[Object.keys(item)]}
                            {Nutrients[Object.keys(item)].type}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div
                  className="p-5 text-center"
                  style={{
                    backgroundColor: "#FEDFDF",
                    color: "#E00000",
                    borderRadius: 5,
                  }}
                >
                  <p style={{ fontSize: 16, fontWeight: 600 }}>
                    เกณฑ์ปริมาณสารอาหาร : ผิดปกติ
                  </p>
                  <div className="row">
                    {foodEstimate?.result["NN"].map((item, index) => {
                      if (Object.keys(item).length > 0) {
                        return (
                          <div className="col-3 my-2">
                            <div
                              className="text-center p-5"
                              style={{
                                backgroundColor: "#ffffff",
                                borderRadius: 5,
                                maxWidth: 110,
                              }}
                            >
                              <p style={{ fontSize: 16, fontWeight: 600 }}>
                                {Object.keys(item)}
                              </p>
                              <span style={{ display: "block" }}>
                                {Nutrients[Object.keys(item)]?.nameTH}
                              </span>
                              <span style={{ display: "block" }}>
                                {item[Object.keys(item)]}
                                {Nutrients[Object.keys(item)]?.type}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
            {foodEstimate == null && (
              <div className="row mt-5">
                <div className="col-12 text-center" style={{ fontSize: 16 }}>
                  ไม่สามารถประเมินผลข้อมูลภาวะโภชนาการ
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </EvaluateChildrenUIProvider>
  );
}
