import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../modules/Dashboard/_redux/dashboardAction";

export function SuccessDialog({ show, onHide }) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.clearError());
    };
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: 500,
            }}
          >
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <span className="navi-icon">
                  <i
                    className="fa fa-check-circle icon-6x "
                    style={{ color: "#6B8A47" }}
                  ></i>
                </span>
              </div>
              <div className="text-primary font-weight-boldest font-size-h3 text-center mt-3">
                เปลี่ยนรหัสผ่านสำเร็จ
              </div>
              <div className="text-center font-size-sm text-dark font-weight-bolder mb-5">
                รหัสผ่านของคุณถูกเปลี่ยนเรียบร้อยแล้ว
              </div>
              <div className="d-flex justify-content-center align-items-center my-5">
                <button
                  type="button"
                  className="btn btn-primary font-size-sm"
                  onClick={() => history.push("/logout")}
                >
                  เข้าสู่ระบบใหม่อีกครั้ง
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
