import React, { useEffect } from "react";
import { Aside } from "./Aside";
import { Header } from "./Header";
import { MenuTop } from "./MenuTop";
import { useHistory } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../redux/general/generalAction";
import { SessionExpireDialog } from "../../components/Layout/SessionExpireDialog";

export function Layout({ children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { session_expire } = useSelector(
    (state) => ({
      session_expire: state.general.session_expire,
    }),
    shallowEqual
  );

  const onHide = () => {
    history.push("/logout");
    dispatch(actions.foceLogin(false))
  };

  return (
    <div className="container-fluid bg-light-white">
      {session_expire && <SessionExpireDialog show={true} onHide={onHide} />}
      <div className="d-flex flex-row flex-column-fluid page">
        <Aside />
        <div className="d-flex flex-column flex-row-fluid wrapper header-paddingTop">
          <MenuTop />
          <Header />
          <div className={`d-flex flex-column m-0 p-0 `}>{children}</div>
        </div>
      </div>
    </div>
  );
}
