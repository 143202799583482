import axios from "axios";
import * as config from "../../../../config";

const { API_ENDPOINT } = config;

export function getDateList(params, childrenId) {
  let formatParams = `?CHILDREN_ID=${childrenId}`;
  Object.keys(params).map((item, index) => {
    if (params[item] == "" && item != "filter") return;
    if (formatParams == "") {
      return (formatParams += `?${item}=${params[item]}`);
    }
    formatParams += `&${item}=${params[item]}`;
  });
  return axios.get(`${API_ENDPOINT}/estimate/list${formatParams}`);
}

export function getChildrensCenterById(id) {
  return axios.get(`${API_ENDPOINT}/masterdata/childrenCenter/all?id=${id}`);
}

export function getEstimateByChildrenProfileId(id) {
  return axios.get(`${API_ENDPOINT}/estimate/?childrenProfileId=${id}`);
}

export function getFoodEstimateByChildrenProfileId(id, date) {
  return axios.get(
    `${API_ENDPOINT}/estimate/foodEstimate?childrenProfileId=${id}&date=${date}`
  );
}
