import { createSlice } from "@reduxjs/toolkit";

const initialGeneralState = {
  province: null,
  amphoe: null,
  tambon: null,
  department: [],
  role: [],
  mealTime: [],
  error: null,
  listLoading: false,
  actionsLoading: false,
  session_expire: false,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const generalSlice = createSlice({
  name: "general",
  initialState: initialGeneralState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    setLocation: (state, action) => {
      const { province, amphoe, tambon, mealTime } = action.payload;
      state.province = province;
      state.amphoe = amphoe;
      state.tambon = tambon;
      state.mealTime = mealTime;
    },
    setDepartment: (state, action) => {
      state.department = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setForceLogin: (state, action) => {
      state.session_expire = action.payload;
    },
  },
});
