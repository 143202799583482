import React, { useMemo, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/ManageChildrensActions";
import { useManageChildrensUIContext } from "./ManageChildrensUIContext";
import { useToast } from "@chakra-ui/react";

export function DeleteDialog({ show, onHide }) {
  const manageChildrensUIContext = useManageChildrensUIContext();
  const manageChildrensUIProps = useMemo(() => {
    return {
      queryParams: manageChildrensUIContext.queryParams,
      setQueryParams: manageChildrensUIContext.setQueryParams,
    };
  }, [manageChildrensUIContext]);

  const toast = useToast();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.manageChildrens,
    }),
    shallowEqual
  );

  const { actionsLoading, error } = currentState;

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState())
    }
  }, [error]);

  const [manageChildrens, setChildre] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(actions.getChildrenById(id)).then((res) => {
        setChildre({ ...res });
      });
    }
  }, []);

  const onClickCancel = () => {
    onHide();
  };

  const onClickDelete = () => {
    dispatch(
      actions.deleteChildrenById(
        { ID: id, CHILDREN_CENTER_ID: manageChildrens.CHILDREN_CENTER_ID },
        manageChildrensUIProps.queryParams
      )
    ).then(() => {
      const newQueryParams = { ...manageChildrensUIProps.queryParams };
      newQueryParams.pageNumber = 1;
      manageChildrensUIProps.setQueryParams(newQueryParams);
      toast({
        title: "ลบบัญชีผู้ใช้งาน สำเร็จ!",
        description: "คุณได้ทำการลบบัญชีสำเร็จ",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onHide();
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: 300,
            }}
          >
            <div>
              <div className="p-10">
                <div className="text-center font-size-sm text-dark font-weight-boldest my-10">
                  <div className="text-primary font-size-h3 mb-2">
                    ลบประวัติข้อมูลสุขภาพเด็ก
                  </div>
                  <div className="">
                    คุณแน่ใจหรือไม่ ? ที่ต้องการลบประวัติข้อมูลสุขภาพเด็ก
                  </div>
                  <div className="text-danger">{`${manageChildrens?.FIRSTNAME} ${manageChildrens?.LASTNAME}`}</div>
                  <div className="">ออกจากระบบ</div>
                </div>
                <div className="d-flex justify-content-between w-100 py-5 border-top mt-10">
                  <button
                    className="btn text-dark font-size-sm font-weight-bolder"
                    style={{ backgroundColor: "#E0DEDE" }}
                    onClick={onClickCancel}
                  >
                    ยกเลิก
                  </button>
                  <button
                    disabled={actionsLoading}
                    className="btn btn-primary font-size-sm font-weight-bolder d-flex align-items-center"
                    onClick={onClickDelete}
                  >
                    {actionsLoading && (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    ลบประวัติข้อมูล
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
