import { createSlice } from "@reduxjs/toolkit";

const initialNutritionFoodEditState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  error: null,
  childrenInfo: null,
  mealTime: [],
  food: [],
  foodDetail: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const NutritionFoodEditSlice = createSlice({
  name: "nutritionFoodEdit",
  initialState: initialNutritionFoodEditState,
  reducers: {
    catchError: (state, action) => {
      state.error =
        action.payload.error.status >= 500
          ? "กรุณาติดต่อผู้ดูแลระบบ"
          : action.payload.error?.response?.data?.message;
      state.stateAction = "error";
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    setChildrenInfo: (state, action) => {
      state.childrenInfo = action.payload;
      state.actionsLoading = false;
    },
    setFood: (state, action) => {
      state.food = action.payload;
      state.error = null;
    },
    setMealTime: (state, action) => {
      state.mealTime = [
        {
          ID: "",
          MEAL_TIME: "เลือกมื้ออาหาร",
        },
        ...action.payload,
      ];
      state.error = null;
    },
    setFoodDetail: (state, action) => {
      state.foodDetail = action.payload;
      state.error = null;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    stopCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    clearState: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
  },
});
