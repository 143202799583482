import * as requestFromServer from "./ManageChildrensCrud";
import { ManageChildrensSlice, callTypes } from "./ManageChildrensSlice";
const { actions } = ManageChildrensSlice;

export const getManageChildrensList = (queryParams, childrenCenterId) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getManageChildrens(queryParams, childrenCenterId)
      .then((res) => {
        dispatch(actions.setEntities(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getChildrenCenterById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getChildrensCenterById(id)
      .then((res) => {
        dispatch(actions.setChildrenCenter(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getChildrenById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getChildrensById(id)
      .then((res) => {
        dispatch(actions.stopCall({ callTypes: callTypes.action }));
        resolve(res.data);
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const postChildren = (payload, queryParams) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    console.log({
      ID: payload.ID,
      ID_CODE: payload.ID_CODE,
      FIRSTNAME: payload.FIRSTNAME,
      LASTNAME: payload.LASTNAME,
      BIRTHDAY: payload.BIRTHDAY,
      GENDER: payload.GENDER,
      CHILDREN_CENTER_ID: payload.CHILDREN_CENTER_ID,
    });
    try {
      const childrenId = await requestFromServer.postChildrens({
        ID: payload.ID,
        ID_CODE: payload.ID_CODE,
        FIRSTNAME: payload.FIRSTNAME,
        LASTNAME: payload.LASTNAME,
        BIRTHDAY: payload.BIRTHDAY,
        GENDER: payload.GENDER,
        CHILDREN_CENTER_ID: payload.CHILDREN_CENTER_ID,
      });
      await requestFromServer.postWeightHeight({
        ID: payload.HISTORYID,
        CHILDREN_PROFILE_ID: childrenId.data.DATA.ID,
        WEIGHT: payload.WEIGHT,
        HEIGHT: payload.HEIGHT,
      });
      dispatch(getManageChildrensList(queryParams, payload.CHILDREN_CENTER_ID));
      resolve();
    } catch (error) {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      reject();
    }
  });
};

export const editChildren = (payload, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  return new Promise((resolve, reject) => {
    requestFromServer
      .postChildrens(payload)
      .then(() => {
        dispatch(
          getManageChildrensList(queryParams, payload.CHILDREN_CENTER_ID)
        );
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const deleteChildrenById = (payload, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  return new Promise((resolve, reject) => {
    requestFromServer
      .deleteChildrens(payload)
      .then(() => {
        dispatch(
          getManageChildrensList(queryParams, payload.CHILDREN_CENTER_ID)
        );
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const clearState = () => (dispatch) => {
  dispatch(actions.clearState());
};
