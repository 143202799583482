import { AiOutlineDashboard } from "react-icons/ai";
import { PiUsersThreeFill } from "react-icons/pi";
import { FaRegSmile } from "react-icons/fa";
import { FiShield } from "react-icons/fi";
import { FiFileText } from "react-icons/fi"

export const PATH = {
  "แดชบอร์ด": {
    path: "/dashboard",
    icon: AiOutlineDashboard
  },
  "จัดการผู้ใช้งานระบบ": {
    path: "/manage-user",
    icon: FiShield
  },
  "ประวัติสุขภาพเด็ก": {
    path: "/history-childrens",
    icon: PiUsersThreeFill
  },
  "ข้อมูลภาวะโภชนาการ": {
    path: "/nutrition-information",
    icon: FiFileText
  },
  "ประเมินผลโภชนาการเด็ก": {
    path: "/evaluate-children",
    icon: FaRegSmile
  },
};
