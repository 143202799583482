import React from "react";
import { ManageChildrensTable } from "./ManageChildrensTable";
import { Switch, Route, useHistory } from "react-router-dom";
import { EditDialog } from "./EditDialog";
import { DeleteDialog } from "./DeleteDialog";
import { DeleteSuccessDialog } from "../../../components/DeleteSuccessDialog";
import { ManageChildrensUIProvider } from "./ManageChildrensUIContext";

export function ManageChildrensPage() {
  const history = useHistory();

  const onHide = () => {
    history.goBack();
  };

  return (
    <ManageChildrensUIProvider>
      <Switch>
        <Route path="/history-childrens/:historyId/manage-childrens/:id/edit">
          {({ history, match }) => (
            <EditDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/history-childrens/:historyId/manage-childrens/new">
          {({ history, match }) => (
            <EditDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/history-childrens/:historyId/manage-childrens/:id/delete">
          {({ history, match }) => (
            <DeleteDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/history-childrens/:historyId/manage-childrens/delete_success">
          {({ history, match }) => (
            <DeleteSuccessDialog show={match != null} onHide={onHide} />
          )}
        </Route>
      </Switch>
      <div className="constainer-fluid gb-light-white p-10 mt-5 shadow-sm rounded">
        <ManageChildrensTable />
      </div>
    </ManageChildrensUIProvider>
  );
}
