import React from "react";
import { PaginationLinks } from "./PaginationLinks";
import { PaginationToolbar } from "./PaginationToolbar";

export function Pagination(props) {
  const { children, isLoading, paginationProps } = props;
  return (
    <>
      {children}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex" style={{ flex: 1 }}>
          <PaginationToolbar
            isLoading={isLoading}
            paginationProps={paginationProps}
          />
        </div>
        <div className="d-flex" style={{ flex: 1.5, justifyContent: 'flex-end' }}>
          <PaginationLinks paginationProps={paginationProps} />
        </div>
      </div>
    </>
  );
}
