import React, { useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { DatePicker } from "../../../components/DatePicker";
import { RadioCheck } from "../../../components/RadioCheck";
import { useManageChildrensUIContext } from "./ManageChildrensUIContext";
import * as actions from "../_redux/ManageChildrensActions";
import { useToast } from "@chakra-ui/react";
import moment from "moment";

export function EditDialog({ show, onHide }) {
  const { id, historyId } = useParams();
  const dispatch = useDispatch();
  const manageChildrensUIContext = useManageChildrensUIContext();
  const manageChildrensUIProps = useMemo(() => {
    return {
      queryParams: manageChildrensUIContext.queryParams,
    };
  }, [manageChildrensUIContext]);

  const toast = useToast();

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.manageChildrens,
    }),
    shallowEqual
  );

  const { error, actionsLoading } = currentState;

  const [initialValues, setInitialValues] = useState({
    ID: null,
    CHILDREN_CENTER_ID: parseInt(historyId),
    ID_CODE: null,
    FIRSTNAME: null,
    LASTNAME: null,
    BIRTHDAY: null,
    GENDER: null,
    USED: "Y",
    CHILDREN_PROFILE_ID: null,
    WEIGHT: null,
    HEIGHT: null,
    HISTORYID: null, // แทน ID จาก service history
  });

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState());
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      dispatch(actions.getChildrenById(id)).then((res) => {
        console.log(res);
        setInitialValues(res);
      });
    }
  }, [id]);

  const AddSchema = Yup.object().shape({
    ID_CODE: Yup.string()
      .nullable()
      .trim()
      .required("กรุณากรอกรหัสประจำตัว"),
    FIRSTNAME: Yup.string()
      .nullable()
      .required("กรุณากรอกชื่อ")
      .matches(/^[ก-๙]+$/gi, "กรุณากรอกตัวอักษรภาษาไทยเท่านั้น"),
    LASTNAME: Yup.string()
      .nullable()
      .required("กรุณากรอกนามสกุล")
      .matches(/^[ก-๙]+$/gi, "กรุณากรอกตัวอักษรภาษาไทยเท่านั้น"),
    BIRTHDAY: Yup.string()
      .nullable()
      .required("กรุณาเลือกวันเกิด"),
    GENDER: Yup.string()
      .nullable()
      .required("กรุณาเลือกเพศ"),
    WEIGHT: Yup.number()
      .nullable()
      .required("กรุณาระบุน้ำหนัก"),
    HEIGHT: Yup.number()
      .nullable()
      .required("กรุณาระบุส่วนสูง"),
  });

  const EditSchema = Yup.object().shape({
    ID_CODE: Yup.string()
      .nullable()
      .trim()
      .required("กรุณากรอกรหัสประจำตัว"),
    FIRSTNAME: Yup.string()
      .nullable()
      .required("กรุณากรอกชื่อ")
      .matches(/^[ก-๙]+$/gi, "กรุณากรอกตัวอักษรภาษาไทยเท่านั้น"),
    LASTNAME: Yup.string()
      .nullable()
      .required("กรุณากรอกนามสกุล")
      .matches(/^[ก-๙]+$/gi, "กรุณากรอกตัวอักษรภาษาไทยเท่านั้น"),
    BIRTHDAY: Yup.string()
      .nullable()
      .required("กรุณาเลือกวันเกิด"),
    GENDER: Yup.string()
      .nullable()
      .required("กรุณาเลือกเพศ"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: id ? EditSchema : AddSchema,
    onSubmit: (values) => {
      if (id) {
        dispatch(
          actions.editChildren(values, manageChildrensUIProps.queryParams)
        ).then(() => {
          toast({
            title: "แก้ไขประวัติข้อมูลสุขภาพเด็ก สำเร็จ!",
            description: "คุณได้ทำการแก้ไขประวัติข้อมูลสุขภาพเด็กสำเร็จ",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onHide();
        });
      } else {
        dispatch(
          actions.postChildren(values, manageChildrensUIProps.queryParams)
        ).then(() => {
          toast({
            title: "สร้างประวัติข้อมูลสุขภาพเด็ก สำเร็จ!",
            description: "คุณได้ทำการสร้างประวัติข้อมูลสุขภาพเด็กสำเร็จ",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onHide();
        });
      }
    },
  });

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        onHide={onHide}
        size="lg"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between w-100 pb-5 border-bottom">
            <div className="text-primary font-weight-boldest font-size-h5">
              {id ? "แก้ไขประวัติข้อมูลเด็ก" : "เพิ่มข้อมูลสุขภาพเด็ก"}
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="container-fluid p-5 d-flex flex-column font-weight-bolder"
          >
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark ">
                <div className="required">รหัสประจำตัว</div>
              </div>
              <div className="col px-0">
                <input
                  type="text"
                  className={`form-control ${
                    formik.touched.ID_CODE && formik.errors.ID_CODE
                      ? "border-danger"
                      : "border-f2"
                  } font-size-sm mr-1 text-dark`}
                  name="ID_CODE"
                  style={{ backgroundColor: "transparent" }}
                  {...formik.getFieldProps("ID_CODE")}
                />
                {formik.touched.ID_CODE && formik.errors.ID_CODE && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.ID_CODE}
                  </span>
                )}
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark">
                <div className="required">ชื่อ-นามสกุล</div>
              </div>
              <div
                className="col px-0 d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ width: "49%" }}>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.FIRSTNAME && formik.errors.FIRSTNAME
                        ? "border-danger"
                        : "border-f2"
                    } font-size-sm mr-1 text-dark`}
                    name="FIRSTNAME"
                    style={{ backgroundColor: "transparent", fontSize: "1rem" }}
                    {...formik.getFieldProps("FIRSTNAME")}
                  />
                  {formik.touched.FIRSTNAME && formik.errors.FIRSTNAME && (
                    <span
                      className="text-danger"
                      style={{ fontWeight: "normal" }}
                    >
                      {formik.errors.FIRSTNAME}
                    </span>
                  )}
                </div>
                <div style={{ width: "49%" }}>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.LASTNAME && formik.errors.LASTNAME
                        ? "border-danger"
                        : "border-f2"
                    } font-size-sm text-dark`}
                    name="LASTNAME"
                    style={{ backgroundColor: "transparent", fontSize: "1rem" }}
                    {...formik.getFieldProps("LASTNAME")}
                  />
                  {formik.touched.LASTNAME && formik.errors.LASTNAME && (
                    <span
                      className="text-danger"
                      style={{ fontWeight: "normal" }}
                    >
                      {formik.errors.LASTNAME}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark ">
                <div className="required">วัน/เดือน/ปี พ.ศ.เกิด</div>
              </div>
              <div className="col px-0">
                <DatePicker
                  DateStyle={{ backgroundColor: "transparent" }}
                  className={`${
                    formik.touched.BIRTHDAY && formik.errors.BIRTHDAY
                      ? "border-danger"
                      : "border-f2"
                  }`}
                  onFocus={() =>
                    formik.setTouched({ ...formik.touched, ["BIRTHDAY"]: true })
                  }
                  placeholder={"เลือก วัน/เดือน/ปี พ.ศ.เกิด"}
                  name={"BIRTHDAY"}
                  onChange={(e) => {
                    formik.setFieldValue('BIRTHDAY', moment(e).format('YYYY-MM-DD'))
                  }}
                  type="dropdown"
                  value={formik.values.BIRTHDAY}
                />
                {formik.touched.BIRTHDAY && formik.errors.BIRTHDAY && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.BIRTHDAY}
                  </span>
                )}
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark">
                <div className="required">เพศ</div>
              </div>
              <div className="col px-0">
                <div className="d-flex">
                  <div>
                    <RadioCheck
                      label={"ชาย"}
                      name="GENDER"
                      value={"M"}
                      checked={formik.values.GENDER == "M"}
                      onFocus={() =>
                        formik.setTouched({
                          ...formik.touched,
                          ["GENDER"]: true,
                        })
                      }
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="ml-10">
                    <RadioCheck
                      label={"หญิง"}
                      name="GENDER"
                      value={"F"}
                      checked={formik.values.GENDER == "F"}
                      onFocus={() =>
                        formik.setTouched({
                          ...formik.touched,
                          ["GENDER"]: true,
                        })
                      }
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                {formik.touched.GENDER && formik.errors.GENDER && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.GENDER}
                  </span>
                )}
              </div>
            </div>
            {!id && (
              <>
                <div
                  className="row mb-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="col px-0 text-dark ">
                    <div className="required">น้ำหนัก</div>
                  </div>
                  <div className="col px-0">
                    <div
                      className="d-flex"
                      style={{
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="number"
                        className={`form-control ${
                          formik.touched.WEIGHT && formik.errors.WEIGHT
                            ? "border-danger"
                            : "border-f2"
                        } font-size-sm mr-1 text-dark`}
                        name="WEIGHT"
                        maxLength={10}
                        style={{ backgroundColor: "transparent", width: "80%" }}
                        {...formik.getFieldProps("WEIGHT")}
                      />
                      <div className="ml-3" style={{ fontSize: "1rem" }}>
                        กิโลกรัม
                      </div>
                    </div>
                    {formik.touched.WEIGHT && formik.errors.WEIGHT && (
                      <span
                        className="text-danger"
                        style={{ fontWeight: "normal" }}
                      >
                        {formik.errors.WEIGHT}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="row mb-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="col px-0 text-dark ">
                    <div className="required">ส่วนสูง</div>
                  </div>
                  <div className="col px-0">
                    <div
                      className="d-flex"
                      style={{
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="number"
                        className={`form-control ${
                          formik.touched.HEIGHT && formik.errors.HEIGHT
                            ? "border-danger"
                            : "border-f2"
                        } font-size-sm mr-1 text-dark`}
                        name="HEIGHT"
                        style={{ backgroundColor: "transparent", width: "80%" }}
                        {...formik.getFieldProps("HEIGHT")}
                      />
                      <div className="ml-3" style={{ fontSize: "1rem" }}>
                        เซนติเมตร
                      </div>
                    </div>
                    {formik.touched.HEIGHT && formik.errors.HEIGHT && (
                      <span
                        className="text-danger"
                        style={{ fontWeight: "normal" }}
                      >
                        {formik.errors.HEIGHT}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </form>
          <div
            className="d-flex align-items-center py-5 border-top"
            style={{ justifyContent: "space-between" }}
          >
            <button
              type="button"
              className="btn font-size-sm fw-bold"
              onClick={() => onHide()}
              style={{ backgroundColor: "#E0DEDE" }}
            >
              ยกเลิก
            </button>
            <button
              type="button"
              className="btn btn-primary font-size-sm fw-bold"
              disabled={!formik.isValid || actionsLoading}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {actionsLoading && (
                <span
                  className="spinner-border spinner-border-sm mr-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {id ? "บันทึก" : "เพิ่มข้อมูล"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
