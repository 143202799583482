import axios from "axios";
import moment from "moment";
const PROVINCE_URL = process.env.REACT_APP_PROVINCE_URL;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const TIME_LOGOUT = 60; // minute

export function getProvince() {
  return axios.get(`${PROVINCE_URL}/provinces`);
}
export function getDistrictByProvinceId(id) {
  return axios.get(`${PROVINCE_URL}/amphures/${id}`);
}
export function getSubDistrictByDistrictId(id) {
  return axios.get(`${PROVINCE_URL}/districts/${id}`);
}
export function masterDataAmphures() {
  return axios.get(`${PROVINCE_URL}/amphures`)
}
export function masterDataDistricts() {
  return axios.get(`${PROVINCE_URL}/districts`);
}
export function masterDataRole() {
  return axios.get(`${API_ENDPOINT}/masterdata/role`);
}
export function masterDataDepartment() {
  return axios.get(`${API_ENDPOINT}/masterdata/department`)
}
export function masterDataMealTime() {
  return axios.get(`${API_ENDPOINT}/masterdata/mealTime`)
}
export function checkEmail(email) {
  return axios.get(`${API_ENDPOINT}/user/checkemail?email=${email}`);
}

export const asyncLocalStorage = {
  setItem: async (key, value) => {
    return Promise.resolve().then(() => {
      localStorage.setItem(key, value);
    });
  },
  getItem: async (key) => {
    return Promise.resolve().then(() => {
      return localStorage.getItem(key);
    });
  },
};

export const checkSession = () => {
  return new Promise(async (resolve, reject) => {
    const session = await asyncLocalStorage.getItem("last_action");
    if (session == null) {
      window.localStorage.setItem(
        "last_action",
        moment().format("DD-MM-YYYY HH:mm")
      );
      resolve(false);
    } else if (session != null) {
      const now = moment(moment(), "DD-MM-YYYY HH:mm");
      const last_action = moment(session, "DD-MM-YYYY HH:mm");
      const minute = now.diff(last_action, "minute");
      if (minute >= TIME_LOGOUT) {
        resolve(true);
      } else if (minute < TIME_LOGOUT) {
        window.localStorage.setItem(
          "last_action",
          moment().format("DD-MM-YYYY HH:mm")
        );
        resolve(false);
      }
    }
  });
};
