import React, { useEffect } from "react";
import { BLOG } from "../../../../_mock/Home";
import { useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { FiChevronLeft } from "react-icons/fi";

export function Blog() {
  const history = useHistory();
  const navigateDetail = (id) => {
    // history.push(`/home/blog/${id}`);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bg-c3 py-10">
      <div className="container">
        <Button
          onClick={() => history.goBack()}
          leftIcon={<FiChevronLeft style={{ fontSize: 20 }} />}
          size="lg"
          style={{ color: "#ffff", backgroundColor: "#58A3BC" }}
        >
          ย้อนกลับไป
        </Button>
        <div className="text-center">
          <p style={{ fontWeight: 600, fontSize: 30 }}>
            หน้าที่และแหล่งสารอาหาร
          </p>
          <p
            className="text-primary"
            style={{ fontWeight: "bold", fontSize: 40 }}
          >
            “ภาวะโภชนาการเด็กก่อนวัยเรียน”
          </p>
        </div>
        <div
          className="d-flex mt-5 flex-column"
          style={{ alignItems: "center" }}
        >
          {BLOG.map((item) => (
            <div
              className="d-flex mb-5 shadow"
              style={{
                backgroundColor: "#ffff",
                borderRadius: 5,
                width: "100%",
                padding: 20,
              }}
              onClick={() => navigateDetail(item.id)}
            >
              <div
                className="bg-primary"
                style={{
                  minWidth: 140,
                  // minHeight: '100%',
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                }}
              >
                <p
                  style={{ color: "#ffff", fontSize: 22, fontWeight: "bolder" }}
                >
                  {item.type}
                </p>
                <p style={{ color: "#ffff", fontSize: 14, fontWeight: 500 }}>
                  {item.typeTH}
                </p>
              </div>
              <div
                className="ml-5"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  minHeight: "100%",
                }}
              >
                <div>
                  <p
                    className="text-primary"
                    style={{ fontWeight: "bolder", fontSize: 18 }}
                  >
                    หน้าที่
                  </p>
                  <p>{item.duty}</p>
                </div>
                <div className="mt-3">
                  <p
                    className="text-primary"
                    style={{ fontWeight: "bolder", fontSize: 18 }}
                  >
                    แหล่งสารอาหาร
                  </p>
                  <p>{item.nutrientSource}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
