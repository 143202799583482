import { createSlice } from "@reduxjs/toolkit";

const initialAgeDetailState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  stateAction: null,
  dietary: [],
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const ageDetailSlice = createSlice({
  name: "ageDetail",
  initialState: initialAgeDetailState,
  reducers: {
    catchError: (state, action) => {
      state.error =
        action.payload.error.status >= 500
          ? "กรุณาติดต่อผู้ดูแลระบบ"
          : action.payload.error?.response?.data?.message;
      state.stateAction = "error";
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.stateAction = "wait";
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    stopCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    setDietary: (state, action) => {
        state.dietary = action.payload;
        state.error = null;
        state.actionsLoading = false
    }
  },
});
