import React from "react";
import { HistoryChildrensTable } from "./HistoryChildrensTable";
import { HistoryChildrensUIProvider } from "./HistoryChildrensUIContext";

export function HistoryChildrensPage() {

  return (
    <HistoryChildrensUIProvider>
      <div className="constainer-fluid gb-light-white p-10 mt-5 shadow-sm rounded">
        <HistoryChildrensTable />
      </div>
    </HistoryChildrensUIProvider>
  );
}
