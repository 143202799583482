import React from "react";
import { Modal } from "react-bootstrap";

export function SessionExpireDialog({ show, onHide }) {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: 500,
            }}
          >
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <span className="navi-icon">
                  <i
                    className="fa-solid fa-circle-exclamation icon-6x"
                    style={{ color: "#FEA73F" }}
                  ></i>
                </span>
              </div>
              <div className="text-primary font-weight-boldest font-size-h3 text-center mt-3">
                ข้อมูลของคุณมีการอัปเดต
              </div>
              <div className="text-center font-size-sm text-dark font-weight-bolder mb-5">
                เนื่องจากมีการอัปเดตข้อมูลของคุณ กรุณาเข้าสู่ระบบใหม่
              </div>
              <div className="d-flex justify-content-center align-items-center my-5">
                <button
                  type="button"
                  className="btn btn-primary font-size-sm"
                  onClick={onHide}
                >
                  เข้าสู่ระบบใหม่อีกครั้ง
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
