import React from "react";
import { Button } from "@chakra-ui/react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from "moment";

moment.locale("th");

export function ProfileInfo({
  childrenProfile,
  childrenCenter,
}) {
  const defaultImageGender =
    childrenProfile?.INFO.CHILDREN.GENDER == "M" ? "/media/boy.jpg" : "/media/girl.jpg";

  return (
    <div className="d-flex">
      <div>
        <img
          src={toAbsoluteUrl(defaultImageGender)}
          style={{ height: "100%", width: 200, objectFit: "cover" }}
          alt=""
        />
      </div>
      <div className="ml-5" style={{ flex: 1 }}>
        <p className="text-primary" style={{ fontSize: 18, fontWeight: 700 }}>
          {childrenProfile?.INFO.CHILDREN.FIRSTNAME || "-"} {childrenProfile?.INFO.CHILDREN.LASTNAME || "-"}
        </p>
        <div className="text-f2 d-flex">
          <span>รหัสประจำตัว : {childrenProfile?.INFO.CHILDREN.ID_CODE || "-"}</span>
          <span className="ml-5">
            เพศ :
            {childrenProfile?.INFO.CHILDREN.GENDER == "M"
              ? "ชาย"
              : childrenProfile?.INFO.CHILDREN.GENDER == "F"
              ? "หญิง"
              : "-"}
          </span>
        </div>
        <div className="text-f2">พื้นที่ : {childrenCenter?.NAME}</div>
        <div className="d-flex rounded mt-3" style={{ backgroundColor: "#E4F1FF", justifyContent: "space-around" }}>
          <div
            className="p-5 d-flex text-center flex-column text-primary"
            style={{
              fontWeight: 500,
              height: "100%",
              justifyContent: "space-between",
              fontSize: 14,
            }}
          >
            <span>อายุ (ปี)</span>
            <div
              className="p-3 my-3"
              style={{
                backgroundColor: "#E4F1FF",
                borderRadius: 5,
                fontSize: 30,
              }}
            >
              {childrenProfile?.INFO.AGE?.years} ปี
            </div>
            <span className="text-dark">
              {childrenProfile?.INFO.AGE?.months} เดือน
            </span>
          </div>
          <div
            className="p-5 d-flex text-center flex-column text-primary"
            style={{
              fontWeight: 500,
              height: "100%",
              justifyContent: "space-between",
              fontSize: 14,
            }}
          >
            <span>น้ำหนัก</span>
            <div
              className="p-3 my-3"
              style={{
                backgroundColor: "#E4F1FF",
                borderRadius: 5,
                fontSize: 30,
              }}
            >
              {childrenProfile?.INFO.WEIGHT}
            </div>
            <span className="text-dark">กิโลกรัม</span>
          </div>
          <div
            className="p-5 d-flex text-center flex-column text-primary rounded"
            style={{
              fontWeight: 500,
              height: "100%",
              justifyContent: "space-between",
              fontSize: 14,
            }}
          >
            <span>ส่วนสูง</span>
            <div
              className="p-3 my-3"
              style={{
                backgroundColor: "#E4F1FF",
                borderRadius: 5,
                fontSize: 30,
              }}
            >
              {childrenProfile?.INFO.HEIGHT}
            </div>
            <span className="text-dark">เซนติเมตร</span>
          </div>
        </div>
      </div>
    </div>
  );
}
