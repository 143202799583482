import axios from "axios";
import * as config from "../../../../config";

const { API_ENDPOINT } = config;

export function getHistoryChildrens(params) {
  let formatParams = "";
  Object.keys(params).map((item, index) => {
    if (params[item] == "" && item != "filter") return;
    if (formatParams == "") {
      return (formatParams += `?${item}=${params[item]}`);
    }
    formatParams += `&${item}=${params[item]}`;
  });
  return axios.get(`${API_ENDPOINT}/masterdata/childrenCenter/page${formatParams}`);
}
