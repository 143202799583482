import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { forgotPassword } from "../../modules/Auth/_redux/authCrud";
import * as auth from "../../modules/Auth/_redux/authRedux";
import { useTranslation } from "react-i18next";
const initialValues = {
  email: "",
};

function ForgotDialog(props) {
  // { show, onHide }
  const { t } = useTranslation();
  const history = useHistory();
  const ForgotSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("VALIDATION.INVALID_EMAIL"))
      .required(t("VALIDATION.REQUIRED", { field: "Email" })),
  });

  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotSchema,
    onSubmit: (values) => {
      setLoading(true);
      forgotPassword({ EMAIL: values.email })
        .then((res) => {
          props.forgotPassword(values.email);
          props.onShow("modalForgotSuccess");
        })
        .catch((err) => {
          console.log(err);
          setInvalid(true);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const onClickSignIn = () => {
    props.onShow("modalLogin");
  };

  const onClickSignUp = () => {
    props.onShow("modalSignUp");
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.onHide('modalForgotPassword')}
        // dialogClassName="modal-90w"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body ClassName="d-flex flex-column align-items-center">
          <div className="d-flex justify-content-end w-100">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.onHide('modalForgotPassword')}
            >
              <i aria-hidden="true" class="ki ki-close text-dark"></i>
            </button>
          </div>
          <div className="text-primary font-weight-boldest font-size-h3 text-center mt-3">
            ลืมรหัสผ่าน
          </div>
          <div className="text-dark font-weight-boldest font-size-sm text-center">
            กรุณากรอกอีเมลของคุณ
            <br />
            เราจะทำการส่งลิงก์เพื่อตั้งรหัสผ่านใหม่ไปยังอีเมลของคุณ
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework my-5"
          >
            {invalid && (
              <div className="text-danger text-center font-size-xs font-weight-bold my-2">
                *ไม่พบอีเมลนี้ในระบบ กรุณากรอกอีเมลใหม่*
              </div>
            )}
            <div className="form-group fv-plugins-icon-container color-primary">
              <div className="font-size-xs font-weight-bolder">อีเมล*</div>
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto font-size-xs py-5 px-6
                ${
                  formik.touched.email && formik.errors.email
                    ? "border-danger"
                    : ""
                }`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginBottom: "3rem" }}
            >
              <button
                type="submit"
                disabled={loading}
                className={`btn btn-primary font-size-xs d-flex align-items-center font-weight-bold`}
              >
                ยืนยัน
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-3"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </form>
          {formik.status && (
            <div className="d-flex justify-content-center my-5">
              <div className="font-size-xs text-muted mx-2">
                *หากลืมอีเมล กรุณาสมัครบัญชีใช้งานใหม่
              </div>
              <button
                type="button"
                class="btn btn-link p-0 font-size-xs "
                onClick={() => onClickSignUp()}
              >
                สร้างบัญชีผู้ใช้งาน
              </button>
            </div>
          )}
          <div className="d-flex justify-content-center">
            <div className="font-size-xs mx-2 text-dark">
              จำรหัสผ่านเข้าใช้งานได้?
            </div>
            <button
              type="button"
              class="btn btn-link p-0 font-size-xs "
              onClick={() => onClickSignIn()}
            >
              <u>เข้าสู่ระบบ</u>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotDialog));
