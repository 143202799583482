import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { dashboardSlice } from "../app/modules/Dashboard/_redux/dashboardSlice";
import { generalSlice } from "./general/generalSlice";
import { UserSlice } from "../app/modules/ManageUser/_redux/ManageUserSlice";
import { HistoryChildrensSlice } from "../app/modules/HistoryChildrens/_redux/HistoryChildrensSlice";
import { ManageChildrensSlice } from "../app/modules/ManageChildrens/_redux/ManageChildrensSlice";
import { ChildrensProfileSlice } from "../app/modules/ChildrensProfile/_redux/ChildrensProfileSlice";
import { EvaluateChildrenSlice } from "../app/modules/EvaluateChildren/_redux/EvaluateChildrenSlice";
import { ageDetailSlice } from "../app/modules/AgeDetail/_redux/AgeDetailSlice";
import { NutritionInformationSlice } from "../app/modules/NutritionInformation/_redux/NutritionInformationSlice";
import { NutritionFoodSlice } from "../app/modules/NutritionFood/_redux/NutritionFoodSlice";
import { NutritionFoodEditSlice } from "../app/modules/NutritionFoodEdit/_redux/NutritionFoodEditSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  manageUser: UserSlice.reducer,
  historyChildrens: HistoryChildrensSlice.reducer,
  manageChildrens: ManageChildrensSlice.reducer,
  childrensProfile: ChildrensProfileSlice.reducer,
  dashboard: dashboardSlice.reducer,
  general: generalSlice.reducer,
  evaluateChildren: EvaluateChildrenSlice.reducer,
  ageDetail: ageDetailSlice.reducer,
  nutritionInformation: NutritionInformationSlice.reducer,
  nutritionFood: NutritionFoodSlice.reducer,
  nutritionFoodEdit: NutritionFoodEditSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
