import * as requestFromServer from "./NutritionInformationCrud";
import { NutritionInformationSlice, callTypes } from "./NutritionInformationSlice";
const { actions } = NutritionInformationSlice;

export const getNutritionInformationList = (queryParams, childrenCenterId) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getNutritionInformation(queryParams, childrenCenterId)
      .then((res) => {
        console.log(res.data.docs);
        dispatch(actions.setEntities(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getChildrenCenterById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getChildrensCenterById(id)
      .then((res) => {
        dispatch(actions.setChildrenCenter(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getChildrenById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getInfoChildrenById(id)
      .then((res) => {
        dispatch(actions.setChildrenInfo(res.data));
        resolve()
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};
