import axios from "axios";
import * as config from "../../../../config";

const { API_ENDPOINT } = config;

export function getChildrenCenter(authToken) {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "JWT " + authToken,
    },
  };
  return axios.get(`${API_ENDPOINT}/masterdata/childrenCenter/all`, config);
}

export function getDashboarByCenterId(id) {
  return axios.get(`${API_ENDPOINT}/dashboard/?CHILDREN_CENTER_ID=${id}`);
}

export function changePassword(data) {
  return axios.put(`${API_ENDPOINT}/user/changepassword`, data);
}
