import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
  useLocation,
  Switch,
  Route,
} from "react-router-dom";
import * as actions from "../_redux/NutritionFoodActions";
import { Button, Icon, Divider } from "@chakra-ui/react";
import { FiChevronLeft } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";
import th from "date-fns/locale/th";
import { useToast } from "@chakra-ui/react";
import { BsInfoCircleFill } from "react-icons/bs";
import moment from "moment";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { DeleteDialog } from "./DeleteDialog";
import { Nutrients } from "../../../../_metronic/_helpers/FoodEstimate";

export function NutritionFoodPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const [date, setDate] = useState(null);
  const toast = useToast();

  useEffect(() => {
    dispatch(actions.getChildrenById(id));
  }, []);

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const param = searchParams.get("date");

      setDate(param);
    }
  }, [location]);

  useEffect(() => {
    if (date) {
      dispatch(actions.getFoodDetail(date, id));
    }
  }, [date]);

  const { currentState, generalState, authState } = useSelector(
    (state) => ({
      currentState: state.nutritionFood,
      generalState: state.general,
      authState: state.auth.user,
    }),
    shallowEqual
  );

  const { actionsLoading, childrenInfo, error, foodDetail } = currentState;

  const { ROLE } = authState;

  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (ROLE == 2 || ROLE == 4) {
      setCanEdit(false);
    } else {
      setCanEdit(true);
    }
  }, [ROLE]);

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState());
    }
  }, [error]);

  const onClickNew = () => {
    history.push(`/nutrition-information/${id}/children/new?date=${date}`);
  };

  const onEdit = (meal_time_id) => {
    history.push(
      `/nutrition-information/${id}/children/${meal_time_id}/edit?date=${date}`
    );
  };

  const getMealTime = (id) => {
    const find = generalState.mealTime.find((item) => item.ID == id);
    return find?.MEAL_TIME;
  };

  const templeteTootip = (nutrition) => {
    return (
      <div
        style={{
          margin: 0,
          color: "#000000",
        }}
      >
        <div className="text-left" style={{ fontSize: 16, fontWeight: 600 }}>
          คุณค่าทางโภชนาการ
        </div>
        <div className="text-left px-5 mt-3">
          <ul>
            {Object.keys(nutrition).map((item) => {
              if (Nutrients[item]) {
                return (
                  <li>
                    {Nutrients[item].nameTH}{" "}
                    <span style={{ fontWeight: 500 }}>
                      {nutrition[item]} {Nutrients[item].type}
                    </span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    );
  };

  const onDelete = (meal_time_id) => {
    history.push(
      `/nutrition-information/${meal_time_id}/food/delete?date=${date}&childrenId=${id}`
    );
  };

  const onHide = () => {
    history.goBack();
  };

  return (
    <div className="container-fluid gb-light-white p-10 mt-5 shadow-sm rounded">
      <Switch>
        <Route path="/nutrition-information/:id/food/delete">
          {({ history, match }) => (
            <DeleteDialog show={match != null} onHide={onHide} />
          )}
        </Route>
      </Switch>
      <div className="mb-10">
        <Button
          onClick={() => history.goBack()}
          leftIcon={<FiChevronLeft style={{ fontSize: 20 }} />}
          size="lg"
          style={{ color: "#ffff", backgroundColor: "#58A3BC" }}
        >
          ย้อนกลับไป
        </Button>
      </div>
      <div className="d-flex justify-content-between mb-5">
        <div>
          <div className="font-weight-bolder text-f2" style={{ fontSize: 16 }}>
            ข้อมูลภาวะโภชนาการ
          </div>
          <div className="text-primary font-size-h5 font-weight-bolder">
            {childrenInfo?.CHILDREN.FIRSTNAME} {childrenInfo?.CHILDREN.LASTNAME}
          </div>
          <div className="text-f2" style={{ fontSize: 14 }}>
            อายุ
            <span className="text-primary mx-2">
              {childrenInfo?.AGE.years} ปี {childrenInfo?.AGE.months} เดือน
            </span>
            น้ำหนัก
            <span className="text-primary mx-2">
              {childrenInfo?.WEIGHT} กิโลกรัม
            </span>
            ส่วนสูง
            <span className="text-primary mx-2">
              {childrenInfo?.HEIGHT} เซนติเมตร
            </span>
          </div>
        </div>
        {canEdit && (
          <div
            style={{
              width: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size={"lg"}
              className="text-white bg-primary"
              onClick={onClickNew}
              leftIcon={<AiFillPlusCircle style={{ fontSize: 20 }} />}
              style={{ alignSelf: "center" }}
            >
              เพิ่มข้อมูลโภชนาการ
            </Button>
          </div>
        )}
      </div>
      <div className="mb-5">
        <Divider />
      </div>
      <div className="mb-5 d-flex mb-5">
        <p className="text-dark fw-bold">
          รายการมื้ออาหารประจำวันที่ :
          <span className="text-primary mx-2">
            {moment(date)
              .add(543, "year")
              .format("DD MMMM YYYY")}{" "}
          </span>
        </p>
      </div>
      <div>
        {foodDetail.map((item, index) => (
          <div
            key={index}
            className="gb-light-white p-5 mt-5 shadow-sm rounded"
          >
            <div
              className="d-flex mb-5"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <div
                className="py-3 px-5 fw-bold"
                style={{
                  borderRadius: 5,
                  backgroundColor: "#E4F1FF",
                  color: "#3E83A8",
                  alignSelf: "center",
                }}
              >
                {getMealTime(item.MEAL_TIME)}
              </div>
              {item.value.length > 0 && canEdit && (
                <div className="d-flex">
                  <Button
                    size={"lg"}
                    className="text-dark mr-3"
                    style={{ backgroundColor: "#E0DEDE" }}
                    onClick={() => onDelete(item.MEAL_TIME)}
                  >
                    ลบ
                  </Button>
                  <Button
                    size={"lg"}
                    className="text-white"
                    style={{ backgroundColor: "#FEA73F" }}
                    onClick={() => onEdit(item.MEAL_TIME)}
                  >
                    แก้ไขข้อมูลโภชนาการ
                  </Button>
                </div>
              )}
            </div>
            {item.value.length == 0 ? (
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>ยังไม่มีข้อมูล</div>
              </div>
            ) : (
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>เมนูอาหารที่รับประทาน</div>
                <div>จำนวน</div>
              </div>
            )}
            {item.value.map((menuItem, index) => (
              <div
                key={index}
                className="d-flex mt-5"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="d-flex"
                  style={{ width: "93%", alignItems: "center" }}
                >
                  <div>
                    <img
                      src={menuItem.PICTURE}
                      alt=""
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 5,
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="ml-3">
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <span className="mr-2" style={{ fontWeight: 600 }}>
                        {menuItem.NAME}
                      </span>
                      <Tooltip
                        position="right"
                        theme={"light"}
                        html={templeteTootip(menuItem)}
                        arrow={true}
                      >
                        <Icon
                          as={BsInfoCircleFill}
                          style={{ color: "#E0DEDE", fontSize: 14 }}
                        />
                      </Tooltip>
                    </div>
                    <span style={{ color: "#58A3BC" }}>
                      ({menuItem.UNIT} {menuItem.UNIT_TEXT})
                    </span>
                  </div>
                </div>
                <div style={{ width: "7%" }}>
                  <input
                    type="text"
                    disabled
                    className="form-control form-control-solid text-center"
                    value={menuItem.UNIT}
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
