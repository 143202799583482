/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/dashboardAction";
import { Select } from "../../../components/Select";
import { Divider } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  ArcElement,
  Tooltip,
  ChartDataLabels,
  BarElement,
  CategoryScale,
  LinearScale
);

export function DashPage() {
  const dispatch = useDispatch();
  const [selectChildrenCenter, setSelectChildrenCenter] = useState(null);
  const { authToken, currentState } = useSelector(
    (state) => ({
      authToken: state.auth.authToken,
      currentState: state.dashboard,
    }),
    shallowEqual
  );

  const { childrenCenter, dashboardData } = currentState;

  const [formatDashboard, setFormatDashboard] = useState([]);

  useEffect(() => {
    if (authToken) {
      dispatch(actions.getChildrenCenter(authToken));
    }
  }, [authToken]);

  useEffect(() => {
    if (dashboardData) {
      const arr = [];
      dashboardData.detail.map((item) => {
        let chart = {
          labels: ["ชาย", "หญิง"],
          datasets: [
            {
              data: [item.gender_m, item.gender_f],
              backgroundColor: ["#28527A", "#FF9EAA"],
              borderColor: ["#28527A", "#FF9EAA"],
              borderWidth: 1,
            },
          ],
        };
        let weight_for_height = {
          labels: Object.keys(item.dashboard.WEIGHT_FOR_HEIGHT).map(
            (item) => item
          ),
          datasets: [
            {
              barThickness: 50,
              data: Object.keys(item.dashboard.WEIGHT_FOR_HEIGHT).map(
                (key) => item.dashboard.WEIGHT_FOR_HEIGHT[key]
              ),
              backgroundColor: "#FFA800",
            },
          ],
        };

        let height_for_age = {
          labels: Object.keys(item.dashboard.HEIGHT_FOR_AGE).map(
            (item) => item
          ),
          datasets: [
            {
              barThickness: 50,
              data: Object.keys(item.dashboard.HEIGHT_FOR_AGE).map(
                (key) => item.dashboard.HEIGHT_FOR_AGE[key]
              ),
              backgroundColor: "#FFA800",
            },
          ],
        };

        let weight_for_age = {
          labels: Object.keys(item.dashboard.WEIGHT_FOR_AGE).map(
            (item) => item
          ),
          datasets: [
            {
              barThickness: 50,
              data: Object.keys(item.dashboard.WEIGHT_FOR_AGE).map(
                (key) => item.dashboard.WEIGHT_FOR_AGE[key]
              ),
              backgroundColor: "#FFA800",
            },
          ],
        };

        let payload = {
          age: item.age,
          percen: item.percen,
          total: item.total,
          male: item.gender_m,
          female: item.gender_f,
          chart,
          weight_for_height,
          height_for_age,
          weight_for_age,
        };

        arr.push(payload);
      });
      setFormatDashboard(arr);
    }
  }, [dashboardData]);

  useEffect(() => {
    return () => {
      dispatch(actions.clearDashboardData());
    };
  }, []);

  useEffect(() => {
    if (childrenCenter.length > 0) {
      const initialChildrenCenter = childrenCenter[0].ID;
      setSelectChildrenCenter(initialChildrenCenter);
      dispatch(actions.getDashboardByChildrenCenter(initialChildrenCenter));
    }
  }, [childrenCenter]);

  useEffect(() => {
    dispatch(actions.getDashboardByChildrenCenter(selectChildrenCenter));
  }, [selectChildrenCenter]);

  return (
    <>
      {/* <Switch>
        <Route path="/password/reset">
          {({ history, match }) => (
            <ResetDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/info/edit">
          {({ history, match }) => (
            <EditDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/password/success">
          {({ history, match }) => (
            <SuccessDialog show={match != null} onHide={onHide} />
          )}
        </Route>
      </Switch> */}
      <div className="row mb-10">
        <div className="col-md-4">
          <div
            className="bg-primary p-5 shadow-custom"
            style={{
              borderRadius: 10,
              minHeight: 350,
              height: "100%",
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/dashboard/Mask-group-2.png"
              )})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "80%",
              backgroundPositionX: "right",
              backgroundPositionY: "bottom",
            }}
          >
            <p className="text-white" style={{ fontSize: 30 }}>
              ยินดีต้อนรับเข้าสู่
            </p>
            <p className="text-white" style={{ fontSize: 16 }}>
              ระบบฐานข้อมูลภาวะโภชนาการของเด็กวัยก่อนเรียน
              ในศูนย์พัฒนาเด็กเล็กในพื้นที่ อำเภอแม่ฟ้าหลวง จังหวัดเชียงราย
            </p>
          </div>
        </div>
        <div className="col-md-8">
          <div
            class="d-flex"
            style={{
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div className="row">
              <div className="col-md-7">
                <p
                  className="text-primary"
                  style={{ fontSize: 30, fontWeight: 700 }}
                >
                  เลือกพื้นที่ศูนย์พัฒนาเด็กเล็ก
                </p>
                <p className="text-f2" style={{ fontSize: 20 }}>
                  แสดงสถิติข้อมูลของพื้นที่ศูนย์พัฒนาเด็กเล็ก
                </p>
              </div>
              <div className="col-md-5">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", height: "100%" }}
                >
                  <Select
                    item={childrenCenter}
                    labelObject={"NAME"}
                    valueObject={"ID"}
                    value={selectChildrenCenter}
                    onChange={(e) => setSelectChildrenCenter(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-4">
                <div
                  className="p-6 shadow-custom"
                  style={{
                    backgroundColor: "#E8F5D8",
                    height: 250,
                    borderRadius: 10,
                  }}
                >
                  <div
                    className="mb-3 bg-success p-3"
                    style={{ borderRadius: 10, width: 75, height: 75 }}
                  >
                    <img
                      src={toAbsoluteUrl(
                        "/media/dashboard/father-with-children-raising-arms.png"
                      )}
                      alt="person"
                    />
                  </div>
                  <div
                    className="mb-3 text-success"
                    style={{ fontWeight: 700, fontSize: 15 }}
                  >
                    จำนวนเด็กในศูนย์พัฒนาทั้งหมด
                  </div>
                  <div>
                    <p
                      className="text-success mb-0"
                      style={{ fontWeight: 700 }}
                    >
                      <span style={{ fontSize: 50 }}>
                        {dashboardData?.info.total}
                      </span>{" "}
                      คน
                    </p>
                    {/* <p
                    className="text-success mb-0"
                    style={{ fontWeight: 700, fontSize: 12 }}
                  >
                    <span style={{ fontSize: 15 }}>59%</span>{" "}
                    ของพื้นที่ศูนย์พัฒนาเด็ก
                  </p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="p-6 shadow-custom"
                  style={{
                    backgroundColor: "#E4F1FF",
                    height: 250,
                    borderRadius: 10,
                  }}
                >
                  <div
                    className="mb-3 bg-primary p-3 d-flex"
                    style={{
                      borderRadius: 10,
                      width: 75,
                      height: 75,
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={toAbsoluteUrl("/media/dashboard/boy.png")}
                      style={{ objectFit: "cover", height: "100%" }}
                      alt="person"
                    />
                  </div>
                  <div
                    className="mb-3 text-primary"
                    style={{ fontWeight: 700, fontSize: 15 }}
                  >
                    จำนวนเด็กเพศชาย
                  </div>
                  <div>
                    <p
                      className="text-primary mb-0"
                      style={{ fontWeight: 700 }}
                    >
                      <span style={{ fontSize: 50 }}>
                        {dashboardData?.info.gender_m}
                      </span>{" "}
                      คน
                    </p>
                    <p
                      className="text-primary mb-0"
                      style={{ fontWeight: 700, fontSize: 12 }}
                    >
                      <span style={{ fontSize: 15 }}>
                        {dashboardData?.info.gender_m_percen}%
                      </span>{" "}
                      ของพื้นที่ศูนย์พัฒนาเด็ก
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="p-6 shadow-custom"
                  style={{
                    backgroundColor: "#FDF4F5",
                    height: 250,
                    borderRadius: 10,
                  }}
                >
                  <div
                    className="mb-3 p-3 d-flex"
                    style={{
                      borderRadius: 10,
                      width: 75,
                      height: 75,
                      backgroundColor: "#FF9EAA",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={toAbsoluteUrl("/media/dashboard/girl.png")}
                      style={{ objectFit: "cover", height: "100%" }}
                      alt="person"
                    />
                  </div>
                  <div
                    className="mb-3"
                    style={{ fontWeight: 700, fontSize: 15, color: "#FF9EAA" }}
                  >
                    จำนวนเด็กเพศหญิง
                  </div>
                  <div>
                    <p
                      className="mb-0"
                      style={{ fontWeight: 700, color: "#FF9EAA" }}
                    >
                      <span style={{ fontSize: 50 }}>
                        {dashboardData?.info.gender_f}
                      </span>{" "}
                      คน
                    </p>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#FF9EAA",
                      }}
                    >
                      <span style={{ fontSize: 15 }}>
                        {dashboardData?.info.gender_f_percen}%
                      </span>{" "}
                      ของพื้นที่ศูนย์พัฒนาเด็ก
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="row mt-10">
        <div className="col-12">
          <p className="text-primary" style={{ fontSize: 25, fontWeight: 700 }}>
            ภาพรวมสถิติข้อมูลเบื้องต้นของศูนย์พัฒนาเด็กเล็กตามช่วงอายุเด็ก
          </p>
          <p style={{ fontSize: 15, fontWeight: 700, color: "#979797" }}>
            สถิติข้อมูลเบื้องต้นของศูนย์พัฒนาเด็กเล็ก
          </p>
        </div>
        {formatDashboard.map((item, index) => (
          <div className="col-12 mt-10">
            <div
              className="card shadow-custom"
              key={index}
              style={{ border: "none" }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div
                      className="bg-primary d-flex"
                      style={{
                        borderRadius: 5,
                        height: 60,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="text-white"
                        style={{ fontSize: 20, fontWeight: 700 }}
                      >
                        อายุ {item.age} ขวบ
                      </p>
                    </div>
                    <div className="mt-5">
                      <Pie
                        data={item.chart}
                        options={{
                          plugins: {
                            datalabels: {
                              color: "#ffff",
                              textAlign: "center",
                              font: {
                                size: 25,
                                weight: "bold",
                                family: "Sarabun",
                              },
                              formatter: function(value, ctx) {
                                if (value == 0) {
                                  return "";
                                } else {
                                  return `${value} คน`;
                                }
                              },
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="my-5">
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-between",
                          fontWeight: 600,
                        }}
                      >
                        <p>จำนวนเด็กทั้งหมด</p>
                        <p>{item.total} คน</p>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-between",
                          fontWeight: 600,
                        }}
                      >
                        <p className="text-f2" style={{ fontSize: 16 }}>
                          คิดเห็นร้อยละ
                        </p>
                        <p className="text-f2" style={{ fontSize: 16 }}>
                          {item.percen} %
                        </p>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-between",
                          fontWeight: "normal",
                        }}
                      >
                        <p className="text-f2" style={{ fontSize: 14 }}>
                          *จากจำนวนเด็กในศูนย์พัฒนาทั้งหมด
                        </p>
                      </div>
                    </div>
                    <Divider />
                    <div
                      className="mt-5 d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <div
                          className="mr-2"
                          style={{
                            backgroundColor: "#28527A",
                            width: 20,
                            height: 5,
                            borderRadius: 10,
                          }}
                        ></div>
                        เพศชาย
                      </div>
                      <div>{item.male} คน</div>
                    </div>
                    <div
                      className="mt-5 d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <div
                          className="mr-2"
                          style={{
                            backgroundColor: "#FF9EAA",
                            width: 20,
                            height: 5,
                            borderRadius: 10,
                          }}
                        ></div>
                        เพศหญิง
                      </div>
                      <div>{item.female} คน</div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="p-5"
                          style={{
                            backgroundColor: "#F3F6F9",
                            borderRadius: 5,
                            // height: 400,
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div>
                              <p className="text-primary">
                                น้ำหนักตามเกณฑ์ส่วนสูง
                              </p>
                              <p style={{ fontSize: 15 }} className="text-f2">
                                (Weight for Height : W/H)
                              </p>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#E4F1FF",
                                borderRadius: 5,
                                alignSelf: "center",
                              }}
                              className="text-primary px-5 py-2"
                            >
                              ข้อมูลบันทึก ณ ปัจจุบัน
                            </div>
                          </div>
                          <div>
                            <Bar
                              data={item.weight_for_height}
                              height={220}
                              options={{
                                maintainAspectRatio: false,
                                scales: {
                                  xAxes: [
                                    {
                                      // barThickness: 2,
                                      barThickness: 1,
                                    },
                                  ],
                                  x: {
                                    grid: {
                                      display: false,
                                    },
                                  },
                                  y: {
                                    grid: {
                                      borderDash: [1, 4],
                                      display: true,
                                    },
                                  },
                                },
                                plugins: {
                                  tooltip: {
                                    callbacks: {
                                      label: function(context) {
                                        return `${context.formattedValue} คน`;
                                      },
                                    },
                                  },
                                  datalabels: {
                                    color: "#ffff",
                                    textAlign: "center",
                                    font: {
                                      size: 25,
                                      weight: "bold",
                                      family: "Sarabun",
                                    },
                                    formatter: function(value, ctx) {
                                      return ``;
                                    },
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-md-6">
                        <div
                          className="p-5"
                          style={{
                            backgroundColor: "#F3F6F9",
                            borderRadius: 5,
                            // height: 200,
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div>
                              <p className="text-primary">น้ำหนักตามอายุ</p>
                              <p style={{ fontSize: 15 }} className="text-f2">
                                (Weight for Age : W/A)
                              </p>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#E4F1FF",
                                borderRadius: 5,
                                alignSelf: "center",
                              }}
                              className="text-primary px-5 py-2"
                            >
                              ข้อมูลบันทึก ณ ปัจจุบัน
                            </div>
                          </div>
                          <div>
                            <Bar
                              data={item.height_for_age}
                              options={{
                                scales: {
                                  x: {
                                    grid: {
                                      display: false,
                                    },
                                  },
                                  y: {
                                    grid: {
                                      borderDash: [1, 4],
                                      display: true,
                                    },
                                  },
                                },
                                plugins: {
                                  tooltip: {
                                    callbacks: {
                                      label: function(context) {
                                        return `${context.formattedValue} คน`;
                                      },
                                    },
                                  },
                                  datalabels: {
                                    color: "#ffff",
                                    textAlign: "center",
                                    font: {
                                      size: 25,
                                      weight: "bold",
                                      family: "Sarabun",
                                    },
                                    formatter: function(value, ctx) {
                                      return ``;
                                    },
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="p-5"
                          style={{
                            backgroundColor: "#F3F6F9",
                            borderRadius: 5,
                            // height: 200,
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div>
                              <p className="text-primary">ส่วนสูงตามอายุ</p>
                              <p style={{ fontSize: 15 }} className="text-f2">
                                (Height for Age : W/A)
                              </p>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#E4F1FF",
                                borderRadius: 5,
                                alignSelf: "center",
                              }}
                              className="text-primary px-5 py-2"
                            >
                              ข้อมูลบันทึก ณ ปัจจุบัน
                            </div>
                          </div>
                          <div>
                            <Bar
                              data={item.weight_for_age}
                              options={{
                                scales: {
                                  x: {
                                    grid: {
                                      display: false,
                                    },
                                  },
                                  y: {
                                    grid: {
                                      borderDash: [1, 4],
                                      display: true,
                                    },
                                  },
                                },
                                plugins: {
                                  tooltip: {
                                    callbacks: {
                                      label: function(context) {
                                        return `${context.formattedValue} คน`;
                                      },
                                    },
                                  },
                                  datalabels: {
                                    color: "#ffff",
                                    textAlign: "center",
                                    font: {
                                      size: 25,
                                      weight: "bold",
                                      family: "Sarabun",
                                    },
                                    formatter: function(value, ctx) {
                                      return ``;
                                    },
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
