import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import * as config from "../../../../config";
import * as actions from "../_redux/ManageUserActions";

export function InfoDialog({ show, onHide }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.manageUser,
    }),
    shallowEqual
  );

  const { error, actionsLoading } = currentState;
  const [province, setProvince] = useState([]);
  const [amphoe, setAmphoe] = useState([]);
  const [tambon, setTambon] = useState([]);
  const [role, setRole] = useState([]);
  const [department, setDepartment] = useState([]);

  const [initialValues, setInitialValues] = useState({
    ID: null,
    USERNAME: null,
    PASSWORD: null,
    ROLE: null,
    USED: "Y",
    CREATED_DATE: new Date(),
    CREATED_BY: 0,
    UPDATED_DATE: new Date(),
    UPDATED_BY: 0,
    PROVINCE: null,
    AMPHOE: null,
    TAMBON: null,
    FIRSTNAME: null,
    LASTNAME: null,
    DEPARTMENT: null,
    TELEPHONE: null,
  });

  useEffect(() => {
    if (id) {
      dispatch(actions.getUserById(id)).then((res) => {
        setInitialValues({ ...res });
      });
      config.getProvince().then((res) => {
        setProvince(res.data);
      });
      config.masterDataRole().then((res) => {
        setRole(res.data);
      });
      config.masterDataDepartment().then((res) => {
        setDepartment(res.data);
      });
    }
  }, []);

  const getNameProvince = (id) => {
    const findName = province.find((item) => item.id == id);
    return findName?.nameTh || "-";
  };

  const getNameAmphur = (id) => {
    const findName = amphoe.find((item) => item.id == id);
    return findName?.nameTh || "-";
  };

  const getNameTambol = (id) => {
    const findName = tambon.find((item) => item.id == id);
    return findName?.nameTh || "-";
  };

  const getNameRole = (id) => {
    const findName = role.find((item) => item.ID == id);
    return findName?.ROLE || "-";
  };

  const getNameDepartment = (id) => {
    const findName = department.find((item) => item.ID == id);
    return findName?.NAME || "-";
  };

  useEffect(() => {
    if (initialValues.PROVINCE) {
      config.getDistrictByProvinceId(initialValues.PROVINCE).then((res) => {
        setAmphoe(res.data);
      });
    }
  }, [initialValues.PROVINCE]);

  useEffect(() => {
    if (initialValues.AMPHOE) {
      config.getSubDistrictByDistrictId(initialValues.AMPHOE).then((res) => {
        setTambon(res.data);
      });
    }
  }, [initialValues.AMPHOE]);

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={onHide}
      size="lg"
      centered
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Body>
        <div className="d-flex justify-content-between w-100 pb-5 border-bottom">
          <div className="text-primary font-weight-boldest font-size-h5">
            ข้อมูลส่วนตัวผู้ใช้งาน
          </div>
        </div>
        <div className="container-fluid p-5 d-flex flex-column font-weight-bolder">
          <div
            className="row mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col px-0 text-dark-75 font-size-xs ">
              <div className="required">ชื่อ-นามสกุล</div>
            </div>
            <div className="col px-0 d-flex">
              <div style={{ width: "50%" }}>
                <input
                  type="text"
                  className={`form-control font-size-sm mr-1 text-dark`}
                  style={{ fontSize: "1rem" }}
                  value={initialValues.FIRSTNAME}
                  disabled
                />
              </div>
              <div style={{ width: "50%" }}>
                <input
                  type="text"
                  className={`form-control font-size-sm ml-1 text-dark`}
                  style={{ fontSize: "1rem" }}
                  value={initialValues.LASTNAME}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="row mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col px-0 text-dark-75 font-size-xs ">
              <div className="required">อีเมล</div>
            </div>
            <div className="col px-0">
              <input
                type="text"
                className={`form-control font-size-sm text-dark`}
                name="USERNAME"
                style={{
                  fontSize: "1rem",
                }}
                disabled={true}
                value={initialValues.USERNAME}
              />
            </div>
          </div>
          <div
            className="row mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col px-0 text-dark-75 font-size-xs ">
              <div style={{fontSize: '1rem'}}>หมายเลขโทรศัพท์</div>
            </div>
            <div className="col px-0">
              <input
                type="text"
                className={`form-control font-size-sm text-dark`}
                disabled={true}
                value={initialValues.TELEPHONE}
                style={{ fontSize: "1rem" }}
              />
            </div>
          </div>
          <div
            className="row mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col px-0 text-dark-75 font-size-xs ">
              <div className="required">บทบาทผู้ใช้งาน</div>
            </div>
            <div className="col px-0">
              <input
                className={`form-control font-size-sm text-dark`}
                disabled={true}
                value={getNameRole(initialValues.ROLE)}
                style={{ fontSize: "1rem" }}
              />
            </div>
          </div>
          <div className="row mb-3" style={{ display: "flex" }}>
            <div className="col-6 px-0 text-dark-75 font-size-xs">
              <div className="required">พื้นที่ (จังหวัด/อำเภอ/ตำบล)</div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-12 px-0 ">
                  <input
                    className={`form-control font-size-sm text-dark`}
                    disabled={true}
                    value={getNameProvince(initialValues.PROVINCE)}
                    style={{ fontSize: "1rem" }}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6 pl-0">
                  <input
                    className={`form-control font-size-sm text-dark`}
                    disabled={true}
                    value={getNameAmphur(initialValues.AMPHOE)}
                    style={{ fontSize: "1rem" }}
                  />
                </div>
                <div className="col-6 pr-0">
                  <input
                    className={`form-control font-size-sm text-dark`}
                    disabled={true}
                    value={getNameTambol(initialValues.TAMBON)}
                    style={{ fontSize: "1rem" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="row mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col px-0 text-dark-75 font-size-xs ">
              <div className="required">หน่วยงานที่สังกัด</div>
            </div>
            <div className="col px-0">
              <input
                className={`form-control font-size-sm text-dark`}
                disabled={true}
                value={getNameDepartment(initialValues.DEPARTMENT)}
                style={{ fontSize: "1rem" }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center border-top align-items-center py-5">
          <button
            type="button"
            className="btn btn-primary font-size-sm"
            onClick={() => onHide()}
          >
            ปิด
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
