import React from "react";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { Content } from "../../../components/HomePage/Content";
import { Manual } from "../../../components/HomePage/Manual";
import { Banner } from "../../../components/HomePage/Banner";
import { ContactUs } from "../../../components/ContactUs";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Home() {
  const history = useHistory();
  const navigateBlog = () => {
    history.push(`/home/blog`);
  };
  return (
    <div className="container-fluid p-0">
      <div className="bg-c3">
        <div>
          <Banner />
        </div>
        <div>
          <div className="row">
            <div className="col-md-6">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={`${toAbsoluteUrl(
                    "/media/home/adorable-little-girl-laughing-street.png"
                  )}`}
                  alt=""
                  style={{
                    height: "100%",
                    width: '100%',
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="d-flex"
                style={{
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <p style={{ fontSize: 30, fontWeight: 600 }}>
                  หน้าที่และแหล่งสารอาหาร
                </p>
                <p className="text-primary fw-bold" style={{ fontSize: 40 }}>
                  “ภาวะโภชนาการเด็กก่อนวัยเรียน”
                </p>
                <div className="mt-5">
                  <button
                    onClick={navigateBlog}
                    className="btn-lg btn btn-outline-primary"
                  >
                    อ่านทั้งหมด
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-primary">
          <div className="container">
            <div className="col-12 p-10">
              <div className="text-white font-size-h3 font-weight-bolder text-center">
                คำแนะนำการบริโภคอาหารที่เหมาะสมกับการเจริญเติบโต
              </div>
              <div className="font-size-h1 fw-bold text-white text-center">
                “เด็กก่อนวัยเรียนเบื้องต้น”
              </div>
              <div>
                <Content />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Manual />
        </div>
        {/* <div>
          <ContactUs />
        </div> */}
      </div>
    </div>
  );
}
