import axios from "axios";
import * as config from "../../../../config";

const { API_ENDPOINT } = config;

export function getChildrensProfilList(params, childrenId) {
  let formatParams = `?childrenId=${childrenId}`;
  Object.keys(params).map((item, index) => {
    if (params[item] == "" && item != "filter") return;
    if (formatParams == "") {
      return (formatParams += `?${item}=${params[item]}`);
    }
    formatParams += `&${item}=${params[item]}`;
  });
  return axios.get(`${API_ENDPOINT}/children/history${formatParams}`);
}

export function getChildrensCenterById(id) {
  return axios.get(`${API_ENDPOINT}/masterdata/childrenCenter/all?id=${id}`);
}

export function getChildrensById(id) {
  return axios.get(`${API_ENDPOINT}/children/?id=${id}`);
}

export function getInfoChildrenById(id) {
  return axios.get(`${API_ENDPOINT}/children/info?childrenProfileId=${id}`);
}

export function postChildrens(payload) {
  return axios.post(`${API_ENDPOINT}/children/`, payload);
}

export function postWeightHeight(payload) {
  return axios.post(`${API_ENDPOINT}/children/history`, payload);
}

export function getWeightHeightById(id) {
  return axios.get(`${API_ENDPOINT}/children/history?id=${id}`);
}

export function deleteWeightHeight(payload) {
  return axios.delete(`${API_ENDPOINT}/children/history`, { data: payload });
}

export function getDashboardById(id) {
  return axios.get(`${API_ENDPOINT}/children/history?childrenId=${id}`);
}
