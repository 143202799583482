import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { AGE } from "../../../_mock/Home";
import { useHistory } from "react-router-dom";
export function Content() {
  const history = useHistory();

  const ageDetail = (id) => {
    history.push(`/home/age/${id}`);
  };

  return (
    <>
      <div className="w-100 d-flex flex-wrap justify-content-center">
        <div className="w-100 d-flex flex-wrap justify-content-center">
          {AGE.map((item, index) => (
            <div
              className="m-2 bg-white text-center bg-hover"
              style={{ width: 200, height: 200, borderRadius: 10, cursor: 'pointer' }}
              key={item.id}
              onClick={() => ageDetail(item.id)}
            >
              <p
                className="text-primary"
                style={{ fontSize: 60, fontWeight: 600 }}
              >
                {item.id}
              </p>
              <p className="text-primary" style={{ fontSize: 30 }}>
                ขวบ
              </p>
              <a
                className="text-primary"
                style={{  fontSize: 18 }}
                
              >
                ดูรายละเอียด
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
