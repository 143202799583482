import React, { useState, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  NoRecordsFoundMessage,
  getHandlerTableChange,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import * as actions from "../_redux/ChildrensProfileActions";
import * as uiHelpers from "./ChildrensProfileUIHelpers";
import { useChildrensProfileUIContext } from "./ChildrensProfileUIContext";
import { Pagination } from "../../../../_metronic/_partials/controls";
import {
  Button,
  Divider,
  useToast,
  Tooltip as Toolstip,
  Icon,
} from "@chakra-ui/react";
import { DatePicker } from "../../../components/DatePicker";
import { AiFillPlusCircle } from "react-icons/ai";
import { PiPencilSimpleDuotone } from "react-icons/pi";
import { GiWeightScale } from "react-icons/gi";
import { GiBodyHeight } from "react-icons/gi";
import { CgTrash } from "react-icons/cg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiFillInfoCircle } from "react-icons/ai";
import moment from "moment";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "moment/locale/th";
import "chartjs-adapter-moment";

ChartJS.register(
  ArcElement,
  Tooltip,
  ChartDataLabels,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
  // TimeScale,
  // TimeSeriesScale
);

moment.locale("th");

export function ChildrensProfileTable({ canEdit }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const toast = useToast();

  const childrensProfileUIContext = useChildrensProfileUIContext();
  const childrensProfileUIProps = useMemo(() => {
    return {
      ids: childrensProfileUIContext.ids,
      queryParams: childrensProfileUIContext.queryParams,
      setQueryParams: childrensProfileUIContext.setQueryParams,
    };
  }, [childrensProfileUIContext]);

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.childrensProfile,
    }),
    shallowEqual
  );

  const {
    totalCount,
    entities,
    actionsLoading,
    error,
    dashboard,
  } = currentState;

  const [updateWeightHeight, setUpdateWeightHeight] = useState(false);
  const [initialValues, setInitialValues] = useState({
    ID: null,
    WEIGHT: null,
    HEIGHT: null,
    CHILDREN_PROFILE_ID: parseInt(id),
    DATE: null,
  });

  const [weightChart, setWeightChart] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        borderColor: "#28527A",
        backgroundColor: "rgba(40, 82, 122, .2)",
        fill: true,
      },
    ],
  });

  const [heightChart, setHeightChart] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        borderColor: "#28527A",
        backgroundColor: "rgba(40, 82, 122, .2)",
        fill: true,
      },
    ],
  });

  // const [randomNumber, setRandomNumber] = useState([]);

  useEffect(() => {
    if (dashboard) {
      const weight = [];
      const height = [];
      const labels = [];

      for (let index = 0; index < dashboard.length; index++) {
        weight.push(dashboard[index].WEIGHT);
        height.push(dashboard[index].HEIGHT);
        labels.push(
          moment(dashboard[index].DATE)
            .add(543, "year")
            .format("DD/MM/YYYY")
        );
      }

      // const arr = [];
      // while (arr.length < 10) {
      //   console.log(`dashboard.length`);
      //   console.log(dashboard.length);
      //   const ran = Math.floor(Math.random() * dashboard.length);
      //   if (arr.length == 0) {
      //     arr.push(0);
      //   } else if (!arr.includes(ran)) {
      //     arr.push(ran);
      //   }
      // }
      // console.log(arr);
      // console.log(`arr`);
      // console.log(
      //   arr.sort(function(a, b) {
      //     return a - b;
      //   })
      // );
      // setRandomNumber(
      //   arr.sort(function(a, b) {
      //     return a - b;
      //   })
      // );
      setWeightChart({
        ...weightChart,
        labels,
        datasets: [
          {
            data: weight,
            borderColor: "#28527A",
            backgroundColor: "rgba(40, 82, 122, .2)",
            fill: true,
          },
        ],
      });

      setHeightChart({
        ...heightChart,
        labels,
        datasets: [
          {
            data: height,
            borderColor: "#28527A",
            backgroundColor: "rgba(40, 82, 122, .2)",
            fill: true,
          },
        ],
      });
    }
  }, [dashboard]);

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState());
    }
  }, [error]);

  useEffect(() => {
    dispatch(
      actions.getChildrensHistoryList(childrensProfileUIProps.queryParams, id)
    );
    dispatch(actions.getDashboard(id));
  }, [childrensProfileUIProps.queryParams, dispatch]);

  useEffect(() => {
    formik.validateForm();
  }, []);

  const onClickEdit = (weightId) => {
    history.push(`/history-childrens/${id}/profile/${weightId}/edit`);
  };

  const onClickDelete = (weightId) => {
    history.push(`/history-childrens/${id}/profile/${weightId}/delete`);
  };

  const columns = [
    {
      text: "วันที่",
      // style: { width: "30%" },
      // formatter: (cell, row, rowIndex) => {
      //   return (
      //     rowIndex +
      //     (childrensProfileUIProps.queryParams.pageNumber - 1) *
      //       childrensProfileUIProps.queryParams.pageSize +
      //     1
      //   );
      // },
      formatter: (cell, row, rowIndex) => {
        return moment(row.DATE)
          .add(543, "year")
          .format("DD/MM/YYYY");
      },
    },
    {
      dataField: "WEIGHT",
      text: "น้ำหนัก",
      // style: { width: "80%" },
    },
    {
      dataField: "HEIGHT",
      text: "ส่วนสูง",
      // style: { minWidth: "40%" },
    },
    {
      dataField: "WEIGHT_FOR_HEIGHT",
      text: "น้ำหนักตามเกณฑ์ส่วนสูง",
      headerFormatter: HeaderCustom,
    },
    {
      dataField: "WEIGHT_FOR_AGE",
      text: "น้ำหนักตามอายุ",
      headerFormatter: HeaderCustom,
    },
    {
      dataField: "HEIGHT_FOR_AGE",
      text: "ส่วนสูงตามอายุ",
      headerFormatter: HeaderCustom,
    },
    {
      dataField: "action",
      text: "จัดการ",
      formatter: ActionCell,
      formatExtraData: {
        onClickEdit: onClickEdit,
        onClickDelete: onClickDelete,
        canEdit: canEdit,
        canDelete: entities && entities.length > 1 ? true : false,
      },
      style: { width: "10%" },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: childrensProfileUIProps.queryParams.pageSize,
    page: childrensProfileUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  const AddSchema = Yup.object().shape({
    WEIGHT: Yup.number().required(),
    HEIGHT: Yup.number().required(),
    DATE: Yup.date().required(),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AddSchema,
    onSubmit: (values) => {
      dispatch(
        actions.postWeightHeight(values, childrensProfileUIProps.queryParams)
      ).then((res) => {
        toast({
          title: "สร้างประวัติน้ำหนักและส่วนสูง สำเร็จ!",
          description: "คุณได้ทำการสร้างประวัติประวัติน้ำหนักและส่วนสูงสำเร็จ",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        formik.setFieldValue("DATE", "");
        formik.setFieldValue("WEIGHT", "");
        formik.setFieldValue("HEIGHT", "");
        formik.resetForm();
        setUpdateWeightHeight(false);
      });
    },
  });

  // useEffect(() => {
  //   formik.setFieldValue("WEIGHT", formik.values.WEIGHT.replace(/[^0-9]+/g, ""))
  // }, [formik.values.WEIGHT])

  return (
    <>
      <div className="d-flex justify-content-between mb-5">
        <div>
          <div className="text-primary font-size-h5 font-weight-bolder">
            ประวัติน้ำหนักและส่วนสูง
          </div>
        </div>
        <div>
          <Button
            size={"lg"}
            className="text-white bg-primary"
            onClick={() => setUpdateWeightHeight(!updateWeightHeight)}
            leftIcon={<AiFillPlusCircle style={{ fontSize: 20 }} />}
          >
            อัปเดตข้อมูลน้ำหนักและส่วนสูง
          </Button>
        </div>
      </div>
      <div className="row my-10">
        <div className="col-md-6">
          <div>
            <div
              className="d-flex mb-5 ml-10"
              style={{ justifyContent: "flex-start", alignItems: "center" }}
            >
              <div
                style={{
                  backgroundColor: "#28527A",
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  as={GiWeightScale}
                  style={{ color: "white", fontSize: 25 }}
                />
              </div>
              <p className="text-dark fw-bold ml-5">น้ำหนัก</p>
            </div>
            <div>
              <Line
                data={weightChart}
                height={100}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      ticks: {
                        autoSkip: true,
                        maxTicksLimit: 8,
                        // callback: function(val, index) {
                        //   if (weightChart.labels.length <= 10) {
                        //     return this.getLabelForValue(val);
                        //   } else if (randomNumber.includes(index)) {
                        //     return this.getLabelForValue(val);
                        //   }
                        // },
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        title: function(context) {
                          return `วันที่ : ${context[0].label}`;
                        },
                        label: function(context) {
                          return `น้ำหนัก : ${context.formattedValue} กก.`;
                        },
                      },
                    },
                    datalabels: {
                      color: "#ffff",
                      textAlign: "center",
                      font: {
                        size: 25,
                        weight: "bold",
                        family: "Sarabun",
                      },
                      fill: true,
                      formatter: function(value, ctx) {
                        return ``;
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <div
              className="d-flex mb-5 ml-10"
              style={{ justifyContent: "flex-start", alignItems: "center" }}
            >
              <div
                style={{
                  backgroundColor: "#28527A",
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  as={GiBodyHeight}
                  style={{ color: "white", fontSize: 25 }}
                />
              </div>
              <p className="text-dark fw-bold ml-5">ส่วนสูง</p>
            </div>
            <div>
              <Line
                data={heightChart}
                height={100}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      ticks: {
                        autoSkip: true,
                        maxTicksLimit: 8,
                        // callback: function(val, index) {
                        //   if (weightChart.labels.length <= 10) {
                        //     return this.getLabelForValue(val);
                        //   }
                        //   return randomNumber.includes(index)
                        //     ? this.getLabelForValue(val)
                        //     : "";
                        // },
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        title: function(context) {
                          return `วันที่ : ${context[0].label}`;
                        },
                        label: function(context) {
                          return `ส่วนสูง : ${context.formattedValue} ซม.`;
                        },
                      },
                    },
                    datalabels: {
                      color: "#ffff",
                      textAlign: "center",
                      font: {
                        size: 25,
                        weight: "bold",
                        family: "Sarabun",
                      },
                      fill: true,
                      formatter: function(value, ctx) {
                        return ``;
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {updateWeightHeight && (
        <div>
          <div className="my-5 d-flex">
            <Divider />
          </div>
          <div className="mb-5">
            <div
              className="text-f2 mb-5"
              style={{ fontSize: 16, fontWeight: 600 }}
            >
              อัปเดตข้อมูลน้ำหนักและส่วนสูงเด็ก
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex"
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <div className="d-flex" style={{ alignItems: "center" }}>
                <div style={{ minWidth: 250 }}>
                  <DatePicker
                    placeholder={"เลือกวันที่"}
                    className={`${
                      formik.touched.DATE && formik.errors.DATE
                        ? "border-danger"
                        : "border-f2"
                    }`}
                    onFocus={() =>
                      formik.setTouched({ ...formik.touched, ["DATE"]: true })
                    }
                    name={"DATE"}
                    value={formik.values.DATE}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "DATE",
                        moment(e).format("YYYY-MM-DD")
                      );
                    }}
                    // {...formik.getFieldProps("DATE")}
                  />
                  {/* {formik.touched.DATE && formik.errors.DATE && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.DATE}
                  </span>
                )} */}
                </div>
                <div>
                  <div
                    className="d-flex mx-5"
                    style={{ minWidth: 200, alignItems: "center" }}
                  >
                    <div style={{ minWidth: 50 }}>น้ำหนัก</div>
                    <input
                      placeholder="น้ำหนัก"
                      type="number"
                      className={`form-control ${
                        formik.touched.WEIGHT && formik.errors.WEIGHT
                          ? "border-danger"
                          : "border-f2"
                      } font-size-sm text-dark`}
                      style={{ backgroundColor: "#ffffff", fontSize: "1rem" }}
                      name="WEIGHT"
                      // onFocus={() =>
                      //   formik.setTouched({ ...formik.touched, ["WEIGHT"]: true })
                      // }
                      // value={formik.values.WEIGHT}
                      // onChange={(e) => {
                      //   formik.setFieldValue(
                      //     "WEIGHT",
                      //    e.replace(/[^0-9]+/g, "")
                      //   );
                      // }}
                      {...formik.getFieldProps("WEIGHT")}
                    />
                  </div>
                  {/* <div
                    className="d-flex mx-5"
                    style={{ minWidth: 200, alignItems: "center" }}
                  >
                    <div style={{ width: 50, backgroundColor: "red" }}></div>
                    {formik.touched.WEIGHT && formik.errors.WEIGHT && (
                      <span
                        className="text-danger"
                        style={{ fontWeight: "normal" }}
                      >
                        {formik.errors.WEIGHT}
                      </span>
                    )}
                  </div> */}
                </div>

                <div
                  className="d-flex"
                  style={{ minWidth: 200, alignItems: "center" }}
                >
                  <div style={{ minWidth: 50 }}>ส่วนสูง</div>
                  <input
                    type="number"
                    placeholder="ส่วนสูง"
                    style={{ backgroundColor: "#ffffff", fontSize: "1rem" }}
                    className={`form-control ${
                      formik.touched.HEIGHT && formik.errors.HEIGHT
                        ? "border-danger"
                        : "border-f2"
                    } font-size-sm text-dark`}
                    name="HEIGHT"
                    {...formik.getFieldProps("HEIGHT")}
                  />
                  {/* {formik.touched.HEIGHT && formik.errors.HEIGHT && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.HEIGHT}
                  </span>
                )} */}
                </div>
              </div>
              <div className="d-flex" style={{ width: "auto" }}>
                <Button
                  size={"lg"}
                  className="text-dark"
                  style={{ backgroundColor: "#E0DEDE", height: "100%" }}
                  onClick={() => {
                    formik.setFieldValue("DATE", "");
                    formik.setFieldValue("WEIGHT", "");
                    formik.setFieldValue("HEIGHT", "");
                    setUpdateWeightHeight(false);
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  size={"lg"}
                  className="text-white bg-primary ml-3"
                  style={{ height: "100%" }}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  disabled={!formik.isValid || actionsLoading}
                >
                  {actionsLoading && (
                    <span
                      className="spinner-border spinner-border-sm mr-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  บันทึก
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}

      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={actionsLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-vertical-center overflow-hidden text-dark font-size-xs font-weight-bolder"
                bodyClasses="text-dark font-size-xs font-weight-bolder"
                bootstrap4
                remote
                keyField="id"
                columns={columns}
                data={entities || []}
                onTableChange={getHandlerTableChange(
                  childrensProfileUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

function HeaderCustom({ text }) {
  return (
    <div className="d-flex" style={{ alignItems: "center" }}>
      {text}
      <Toolstip label="*คำนวณจากวันที่บันทึกผล">
        <span className="ml-1">
          <AiFillInfoCircle style={{ color: "#E0DEDE" }} />
        </span>
      </Toolstip>
    </div>
  );
}

function ActionCell(
  cellContent,
  row,
  rowIndex,
  { onClickEdit, onClickDelete, canEdit, canDelete }
) {
  return (
    <>
      <div className="d-flex">
        {canEdit && (
          <button
            className="btn btn-warning mx-2"
            onClick={() => onClickEdit(row.ID)}
            style={{
              width: 45,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              as={PiPencilSimpleDuotone}
              style={{ color: "white", fontSize: 20 }}
            />
          </button>
        )}
        {canDelete && (
          <button
            className="btn btn-danger mx-2"
            onClick={() => onClickDelete(row.ID)}
            style={{
              width: 45,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon as={CgTrash} style={{ color: "white", fontSize: 20 }} />
          </button>
        )}
      </div>
    </>
  );
}

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-5">
    <span className="text-f2">แสดง</span> {from} - {to}{" "}
    <span className="text-f2">จาก</span> {size}
  </span>
);
