import * as requestFromServer from "./HistoryChildrensCrud";
import { HistoryChildrensSlice, callTypes } from "./HistoryChildrensSlice";
const { actions } = HistoryChildrensSlice;

export const getHistoryChildrensList = (queryParams) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getHistoryChildrens(queryParams)
      .then((res) => {
        dispatch(actions.setEntities(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const setLoading = () => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
};
