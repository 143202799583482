import React from "react";

export default function Card({ img, name, position, ...props }) {
  return (
    <div className="card" {...props} style={{ width: 350, borderRadius: 5 }}>
      <img
        src={img}
        className="card-img-top pt-5"
        alt="img"
        style={{ height: 250, objectFit: 'contain', objectPosition: "50%", }}
      />
      <div className="card-body p-3 text-center">
        <p
          className="card-text text-dark"
          style={{ fontSize: 20, fontWeight: 500 }}
        >
          {name}
        </p>
        <p className="card-text text-f2" style={{ fontSize: 16 }}>
          ตำแหน่ง: {position}
        </p>
      </div>
    </div>
  );
}
