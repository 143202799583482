import React from "react";
import { NutritionInformationTable } from "./NutritionInformationTable";
import { NutritionInformationUIProvider } from "./NutritionInformationUIContext";

export function NutritionInformationPage() {
  return (
    <NutritionInformationUIProvider>
      <div className="constainer-fluid gb-light-white p-10 mt-5 shadow-sm rounded">
        <NutritionInformationTable />
      </div>
    </NutritionInformationUIProvider>
  );
}
