import axios from "axios";
import * as config from "../../../../config";

const { API_ENDPOINT } = config;

export function getManageChildrens(params, childrenCenterId) {
  let formatParams = `?childrenCenterId=${childrenCenterId}`;
  Object.keys(params).map((item, index) => {
    if (params[item] == "" && item != "filter") return;
    if (formatParams == "") {
      return (formatParams += `?${item}=${params[item]}`);
    }
    formatParams += `&${item}=${params[item]}`;
  });
  return axios.get(`${API_ENDPOINT}/children/${formatParams}`);
}

export function getChildrensCenterById(id) {
  return axios.get(`${API_ENDPOINT}/masterdata/childrenCenter/all?id=${id}`);
}

export function getChildrensById(id) {
  return axios.get(`${API_ENDPOINT}/children/?id=${id}`);
}

export function getWeightHeightById(id) {
  return axios.get(`${API_ENDPOINT}/children/history?id=${id}`);
}

export function postChildrens(payload) {
  return axios.post(`${API_ENDPOINT}/children/`, payload);
}

export function deleteChildrens(payload) {
  console.log(payload);
  return axios.delete(`${API_ENDPOINT}/children/`, {data: payload});
}

export function postWeightHeight(payload) {
  return axios.post(`${API_ENDPOINT}/children/history`, payload);
}

export function deleteWeightHeight(payload) {
  return axios.delete(`${API_ENDPOINT}/children/history`, payload);
}



