import React, { useEffect, useState } from "react";
import { ChildrensProfileTable } from "./ChildrensProfileTable";
import { Switch, Route, useHistory, useParams } from "react-router-dom";
import { EditDialog } from "./EditDialog";
import { DeleteDialog } from "./DeleteDialog";
import { DeleteSuccessDialog } from "../../../components/DeleteSuccessDialog";
import { ChildrensProfileUIProvider } from "./ChildrensProfileUIContext";
import { ProfileInfo } from "./ProfileInfo";
import { Button } from "@chakra-ui/react";
import { FiChevronLeft } from "react-icons/fi";
import { EditProfileDialog } from "./EditProfileDialog";
import * as actions from "../_redux/ChildrensProfileActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useToast } from "@chakra-ui/react";

export function ChildrensProfilePage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const { id } = useParams();

  const { currentState, authState } = useSelector(
    (state) => ({
      currentState: state.childrensProfile,
      authState: state.auth.user,
    }),
    shallowEqual
  );

  const {
    childrenCenter,
    childrenProfile,
    error,
  } = currentState;

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState())
    }
  }, [error]);

  const { ROLE } = authState;

  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (ROLE == 2 || ROLE == 4) {
      setCanEdit(false);
    } else {
      setCanEdit(true);
    }
  }, [ROLE]);

  useEffect(() => {
    dispatch(actions.getChildrenById(id));
  }, []);

  const [state, setState] = useState({
    WEIGHT: "",
    HEIGHT: "",
    YEAR: "",
    MONTH: ""
  });

  useEffect(() => {
    if (childrenProfile) {
      dispatch(
        actions.getChildrenCenterById(childrenProfile.CHILDREN_CENTER_ID)
      );
      dispatch(actions.getChildrenInfoById(id)).then((res) => {
        setState({
          ...state,
          WEIGHT: res.WEIGHT,
          HEIGHT: res.HEIGHT,
          YEAR: res.AGE.years,
          MONTH: res.AGE.months
        });
      });
    }
  }, [childrenProfile]);

  const onHide = () => {
    history.goBack();
  };

  const editProfile = () => {
    history.push(`/history-childrens/${id}/profile/edit`);
  };

  return (
    <ChildrensProfileUIProvider>
      <Switch>
        <Route path="/history-childrens/:childrenId/profile/edit">
          {({ history, match }) => (
            <EditProfileDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/history-childrens/:childrenId/profile/:id/edit">
          {({ history, match }) => (
            <EditDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/history-childrens/:childrenId/profile/:id/delete">
          {({ history, match }) => (
            <DeleteDialog show={match != null} onHide={onHide} />
          )}
        </Route>
        <Route path="/history-childrens/:childrenId/profile/delete_success">
          {({ history, match }) => (
            <DeleteSuccessDialog show={match != null} onHide={onHide} />
          )}
        </Route>
      </Switch>
      <div className="my-5">
        <Button
          onClick={() => history.goBack()}
          leftIcon={<FiChevronLeft style={{ fontSize: 20 }} />}
          size="lg"
          style={{ color: "#ffff", backgroundColor: "#58A3BC" }}
        >
          ย้อนกลับไป
        </Button>
      </div>
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="bg-light-white p-10 shadow-sm rounded">
            <ProfileInfo
              editProfile={editProfile}
              childrenProfile={childrenProfile}
              childrenCenter={childrenCenter}
              canEdit={canEdit}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div
            className="d-flex flex-column"
            style={{ justifyContent: "space-between", height: "100%"}}
          >
            <div className="text-primary">* ข้อมูลสุขภาพ ณ ปัจจุบัน</div>
            <div className="row">
              <div className="col-4">
                <div
                  className="bg-light-white px-5 py-10 d-flex text-center flex-column text-primary shadow-sm rounded"
                  style={{
                    fontWeight: 700,
                    height: 230,
                    justifyContent: "space-between",
                    fontSize: 14,
                  }}
                >
                  <span>อายุ (ปี)</span>
                  <div
                    className="p-3 my-3"
                    style={{
                      backgroundColor: "#E4F1FF",
                      borderRadius: 5,
                      fontSize: 30,
                    }}
                  >
                    {state.YEAR} ปี
                  </div>
                  <span className="text-dark">
                    {state.MONTH} เดือน
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="bg-light-white px-5 py-10 d-flex text-center flex-column text-primary shadow-sm rounded"
                  style={{
                    fontWeight: 700,
                    height: "100%",
                    justifyContent: "space-between",
                    fontSize: 14,
                  }}
                >
                  <span>น้ำหนัก</span>
                  <div
                    className="p-3 my-3"
                    style={{
                      backgroundColor: "#E4F1FF",
                      borderRadius: 5,
                      fontSize: 30,
                    }}
                  >
                    {state.WEIGHT}
                  </div>
                  <span className="text-dark">กิโลกรัม</span>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="bg-light-white px-5 py-10 d-flex text-center flex-column text-primary shadow-sm rounded"
                  style={{
                    fontWeight: 700,
                    height: "100%",
                    justifyContent: "space-between",
                    fontSize: 14,
                  }}
                >
                  <span>ส่วนสูง</span>
                  <div
                    className="p-3 my-3"
                    style={{
                      backgroundColor: "#E4F1FF",
                      borderRadius: 5,
                      fontSize: 30,
                    }}
                  >
                    {state.HEIGHT}
                  </div>
                  <span className="text-dark">เซนติเมตร</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light-white p-10 mt-5 shadow-sm rounded">
        <ChildrensProfileTable canEdit={canEdit}/>
      </div>
    </ChildrensProfileUIProvider>
  );
}
