import axios from "axios";
import * as config from "../../../../config";
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

const { API_ENDPOINT } = config;

export function login(payload) {
  return axios.post(`${API_ENDPOINT}/auth/token`, payload);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(data, token) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  };
  return axios.post(`${API_ENDPOINT}/user/reset`, data, config);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function checkEmail(email) {
  return axios.get(`${API_ENDPOINT}/user/checkemail?email=${email}`);
}

export function register(payload) {
  return axios.post(`${API_ENDPOINT}/user/register`, payload);
}

export function forgotPassword(payload) {
  return axios.put(`${API_ENDPOINT}/user/forgot`, payload);
}

export function getLesson() {
  return axios.get(`${API_ENDPOINT}/course/1`);
}
