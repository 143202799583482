import * as requestFromServer from "./NutritionFoodCrud";
import { NutritionFoodSlice, callTypes } from "./NutritionFoodSlice";
const { actions } = NutritionFoodSlice;

export const getNutritionFoodList = (queryParams, childrenCenterId) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getNutritionFood(queryParams, childrenCenterId)
      .then((res) => {
        console.log(res.data.docs);
        dispatch(actions.setEntities(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getChildrenCenterById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getChildrensCenterById(id)
      .then((res) => {
        dispatch(actions.setChildrenCenter(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const getFoodDetail = (date, id) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  requestFromServer
    .getFoodDetail(date, id)
    .then((res) => {
      dispatch(actions.setFoodDetail(res.data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteFoodDetail = (date, childrenId, mealTimeId) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .deleteFoodDetail(date, childrenId, mealTimeId)
      .then((res) => {
        dispatch(getFoodDetail(date, childrenId));
        resolve()
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject()
      });
  });
};

export const getChildrenById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getInfoChildrenById(id)
      .then((res) => {
        dispatch(actions.setChildrenInfo(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const clearState = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.clearState());
  });
};