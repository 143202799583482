import * as actions from "./general/generalAction";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const storage = JSON.parse(
        localStorage.getItem("persist:v726-demo1-auth")
      );

      if (storage?.authToken && !config.url.includes("/api/auth/token")) {
        config.headers.Authorization = `JWT ${storage?.authToken.replaceAll(
          '"',
          ""
        )}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response && error.response.status == 403) {
        store.dispatch(actions.foceLogin(true));
      }
      console.log(error.response);
      return Promise.reject(error);
    }
  );
}
