import { createSlice } from "@reduxjs/toolkit";

const initialNutritionFoodState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  error: undefined,
  entities: null,
  childrenCenter: null,
  childrenInfo: null,
  foodDetail: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const NutritionFoodSlice = createSlice({
  name: "nutritionFood",
  initialState: initialNutritionFoodState,
  reducers: {
    catchError: (state, action) => {
      state.error =
        action.payload.error.status >= 500
          ? "กรุณาติดต่อผู้ดูแลระบบ"
          : action.payload.error?.response?.data?.message;
      state.stateAction = "error";
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    setChildrenInfo: (state, action) => {
      state.childrenInfo = action.payload;
      state.actionsLoading = false;
    },
    setFoodDetail: (state, action) => {
      state.actionsLoading = false;
      state.foodDetail = action.payload;
      state.error = null;
    },
    setChildrenCenter: (state, action) => {
      state.childrenCenter = action.payload;
      state.error = null;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    setEntities: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = action.payload.docs;
      state.totalCount = action.payload.total;
    },
    stopCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    clearState: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
  },
});
