import React, { useEffect, useState } from "react";
import { Brand } from "./Brand";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../_metronic/_helpers";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import jwt_decode from "jwt-decode";
import { PATH } from "../../../_metronic/_helpers/Path";

export function Aside() {
  const location = useLocation();
  const { t } = useTranslation();
  const { authToken } = useSelector(
    (state) => ({
      authToken: state.auth.authToken,
    }),
    shallowEqual
  );

  const [MENU, setMENU] = useState([]);

  useEffect(() => {
    if (authToken) {
      var decoded = jwt_decode(authToken);
      const arr = [];
      decoded.MENU.map((item) => {
        arr.push({
          PATHNAME: item.NAME.replace(/\s/g, ""),
          PATH: PATH[item.NAME.replace(/\s/g, "")].path,
          ICON: PATH[item.NAME.replace(/\s/g, "")].icon,
        });
      });
      setMENU(arr);
    }
  }, [authToken]);

  const getMenuItemActive = (url) => {
    for (let i = 0; i < url.length; i++) {
      if (checkIsActive(location, url[i])) {
        return "bg-white text-secondary";
      }
    }
    return "text-white";
  };

  return (
    <div className="aside aside-left d-flex flex-column bg-primary">
      <div className="d-flex align-items-center justify-content-center mt-3 mb-10 mx-5">
        <Brand />
      </div>
      <div className="px-5 font-size-xs text-white my-2">{t("MENU")}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {MENU.map((item) => (
            <NavLink
              className={`${getMenuItemActive([item.PATH])} p-5`}
              to={item.PATH}
            >
              <span className="svg-icon">
                <Icon
                  as={item.ICON}
                  className={`${getMenuItemActive([item.PATH])}`}
                />
              </span>
              <span className="font-size-sm mx-2 font-weight-bold">
                {item.PATHNAME}
              </span>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
}
