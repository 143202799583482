import React, { useState, useEffect, useMemo, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { isEqual } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  NoRecordsFoundMessage,
  getHandlerTableChange,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import * as actions from "../_redux/HistoryChildrensActions";
import * as uiHelpers from "./HistoryChildrensUIHelpers";
import { useHistoryChildrensUIContext } from "./HistoryChildrensUIContext";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { InputGroup, InputLeftElement, Input, Icon } from "@chakra-ui/react";
import { CiSearch } from "react-icons/ci";
import { RiSearchEyeLine } from "react-icons/ri";
import * as masterData from "../../../../config";
import { useToast } from "@chakra-ui/react";

export function HistoryChildrensTable() {
  const toast = useToast();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const historyChildrensUIContext = useHistoryChildrensUIContext();
  const historyChildrensUIProps = useMemo(() => {
    return {
      ids: historyChildrensUIContext.ids,
      queryParams: historyChildrensUIContext.queryParams,
      setQueryParams: historyChildrensUIContext.setQueryParams,
    };
  }, [historyChildrensUIContext]);

  const firstRender = useRef(true);

  const { currentState, generalState } = useSelector(
    (state) => ({
      currentState: state.historyChildrens,
      generalState: state.general,
    }),
    shallowEqual
  );

  const { totalCount, entities, actionsLoading } = currentState;
  const { province, amphoe, tambon } = generalState;

  const [state, setState] = useState({
    filter: "",
  });

  useEffect(() => {
    dispatch(
      actions.getHistoryChildrensList(historyChildrensUIProps.queryParams)
    );
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    dispatch(
      actions.getHistoryChildrensList(historyChildrensUIProps.queryParams)
    );
  }, [historyChildrensUIProps.queryParams]);

  useEffect(() => {
    applyFilter(state);
  }, [state]);

  const onClickInfo = (id) => {
    if (location.pathname == "/evaluate-children") {
      history.push(`/evaluate-children/${id}/childrens`);
    } else if (location.pathname == "/nutrition-information") {
      history.push(`/nutrition-information/${id}/childrens`);
    } else {
      history.push(`/history-childrens/${id}/manage-childrens`);
    }
  };

  const getProvinceName = (id) => {
    return province.find((item) => item.id == id)?.nameTh || "-";
  };

  const getAmphoeName = (id) => {
    return amphoe.find((item) => item.id == id)?.nameTh || "-";
  };

  const getTambonName = (id) => {
    return tambon.find((item) => item.id == id)?.nameTh || "-";
  };

  const columns = [
    {
      text: "ลำดับ",
      style: { width: "10%" },
      formatter: (cell, row, rowIndex) => {
        return (
          rowIndex +
          (historyChildrensUIProps.queryParams.pageNumber - 1) *
            historyChildrensUIProps.queryParams.pageSize +
          1
        );
      },
    },
    {
      dataField: "NAME",
      text: "ชื่อพื้นที่",
    },
    {
      text: "ตำบล",
      formatter: (cell, row, rowIndex) => {
        return `${getTambonName(row.TAMBON_ID)}`;
      },
    },
    {
      text: "อำเภอ",
      formatter: (cell, row, rowIndex) => {
        return `${getAmphoeName(row.AMPHOE_ID)}`;
      },
    },
    {
      text: "จังหวัด",
      formatter: (cell, row, rowIndex) => {
        return `${getProvinceName(row.PROVINCE_ID)}`;
      },
    },
    {
      dataField: "action",
      text: "จัดการ",
      formatter: ActionCell,
      formatExtraData: {
        onClickInfo: onClickInfo,
      },
      style: { width: "10%" },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: historyChildrensUIProps.queryParams.pageSize,
    page: historyChildrensUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  const applyFilter = (values) => {
    const newQueryParams = {
      ...historyChildrensUIProps.queryParams,
      ...values,
    };
    if (!isEqual(newQueryParams, historyChildrensUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      historyChildrensUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div>
          <div className="text-primary font-size-h5 font-weight-bolder">
            {location.pathname == "/evaluate-children"
              ? "ระบบประเมินผลภาวะโภชนาการเด็กเป็นรายบุคคล"
              : location.pathname == "/nutrition-information"
              ? "ระบบจัดการข้อมูลภาวะโภชนาการ"
              : "ระบบจัดการข้อมูลประวัติสุขภาพของเด็กก่อนวัยเรียน"}
          </div>
          <div className="text-f2" style={{ fontSize: 14 }}>
            จำนวนใน
            <span className="text-primary mx-2">{totalCount}</span>
            พื้นที่
          </div>
        </div>
        <div style={{ width: "40%" }}>
          <InputGroup
            size="lg"
            style={{ border: "1px solid #979797", borderRadius: 5 }}
          >
            <InputLeftElement
              pointerEvents="none"
              children={<CiSearch color="gray" />}
            />
            <Input
              type="text"
              placeholder="ค้นหา"
              style={{ border: "none", backgroundColor: "transparent" }}
              onChange={(e) => {
                setState({ ...state, filter: e.target.value });
              }}
            />
          </InputGroup>
        </div>
      </div>
      {province &&
        amphoe &&
        tambon && (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={actionsLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-vertical-center overflow-hidden text-dark font-size-xs font-weight-bolder"
                    bodyClasses="text-dark font-size-xs font-weight-bolder"
                    bootstrap4
                    remote
                    keyField="id"
                    columns={columns}
                    data={entities || []}
                    onTableChange={getHandlerTableChange(
                      historyChildrensUIProps.setQueryParams
                    )}
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={entities} />
                    <NoRecordsFoundMessage entities={entities} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        )}
    </>
  );
}

function ActionCell(cellContent, row, rowIndex, { onClickInfo }) {
  return (
    <>
      <div className="d-flex">
        <button
          className="btn mx-2"
          onClick={() => onClickInfo(row.ID)}
          style={{
            width: 45,
            backgroundColor: "#979797",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon as={RiSearchEyeLine} style={{ color: "white", fontSize: 20 }} />
        </button>
      </div>
    </>
  );
}

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-5">
    <span className="text-f2">แสดง</span> {from} - {to}{" "}
    <span className="text-f2">จาก</span> {size}
  </span>
);
