import React from "react";
import { Image } from "@chakra-ui/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {COOPERATION_AGENCY} from "../../../_mock/Home"
import "swiper/swiper.scss";
// import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Pagination, Autoplay]);
export function Manual() {

  return (
    <div className="col-12 pt-20 pb-20" style={{ backgroundColor: "#ffff" }}>
      <p className="font-size-h1 text-primary mb-5 fw-bold text-center">
        หน่วยงานความร่วมมือ
      </p>
      <div className="container">
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          pagination={true}
          autoplay={true}
          slidesPerGroup={4}
        >
          {COOPERATION_AGENCY.map((item) => (
            <SwiperSlide>
              <div className="d-flex flex-column justify-content-center align-items-center" style={{ minWidth: 200}}>
                <Image
                  borderRadius="full"
                  height={"130px"}
                  width={"130px"}
                  src={item.img}
                  style={{objectFit: 'contain'}}
                  alt="Dan Abramov"
                />
                <p
                  className="text-center mt-3"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {item.name}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
