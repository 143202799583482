export const AGE = [
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
];

export const BLOG = [
  {
    id: 1,
    type: "ENER",
    typeTH: "พลังงาน",
    duty:
      "ร่างกายได้รับพลังงานจากอาหารที่บริโภค เพื่อใช้ในการทำงานระบบต่างๆของร่างกาย เช่น การทำงานของระบบหายใจ ระบบประสาท การไหลเวียนของโลหิต การรักษาอุณหภูมิของร่างกายและการประกอบกิจกรรมต่างๆ ในชีวิตประจำวัน รวมถึงการเจริญเติบโตในเด็ก",
    nutrientSource:
      "แหล่งพลังงานมาจากอาหารที่เป็นแหล่งโปรตีน คาร์โบไฮเดรต และไขมัน",
  },
  {
    id: 2,
    type: "PRO",
    typeTH: "โปรตีน",
    duty:
      "โปรตีนเป็นสารอาหารที่เป็นองค์ประกอบหลักของร่างกาย เป็นตัวประสานและควบคุมการทำงานในร่างกาย หากขาดโปรตีนการทำงานทุกระบบในร่างกายจะไม่สามารถดำเนินต่อไปได้ อีกทั้งโปรตีนเป็นส่วนประกอบโครงสร้างร่างกาย คือ กล้ามเนื้อ กระดูก ผิวหนัง และระบบภูมิคุ้มกัน",
    nutrientSource:
      "สารอาหารโปรตีนได้จากสัตว์และพืช โปรตีนจากเนื้อสัตว์เป็นโปรตีนที่มีคุณภาพดี มีกรดอะมิโนจำเป็นครบถ้วน ได้แก่ เนื้อสัตว์ ไข่ นม",
  },
  {
    id: 3,
    type: "SUGAR",
    typeTH: "น้ำตาล",
    duty:
      "น้ำตาลถูกใช้เป็นสารที่เพิ่มความหวานให้แก่ผลิตภัณฑ์อาหารเพื่อช่วยให้อาหารมีรสชาติโดยรวมดีขึ้น การรับประทานน้ำตาลในปริมาณสูงมีความเสี่ยงต่อการเกิดโรคอ้วนและเบาหวานในเด็กได้ ดังนั้นควรหลีกเลี่ยงการบริโภคน้ำตาลในเด็กปฐมวัย ",
    nutrientSource:
      "หลีกเลี่ยงขนมหวาน เบเกอรี่ ขนมไทยรสหวาน เครื่องดื่มที่เติมน้ำตาลสูง และหันมาบริโภคผลไม้เป็นอาหารว่างแทนแหล่งอาหารที่มีน้ำตาลสูง",
  },
  {
    id: 4,
    type: "SATFAT",
    typeTH: "ไขมันอิ่มตัว",
    duty:
      "ไขมันอิ่มตัวและคอเลสเตอรอลสามารถได้รับจากภายนอกและสร้างได้ภายในร่างกาย ในเด็กเล็กใช้ไขมันและคอเลสเตอรอลในการสร้างฮอร์โมน ใช้ละลายวิตามินที่ละลายในไขมัน เอ ดี อี เค และมีความสำคัญต่องมีความสำคัญต่อการดูดซึมไขมันในอาหาร แหล่งไขมันที่ดีควรมาจากไขมันไม่อิ่มตัว และควรหลีกเลี่ยงการบริโภคไขมันอิ่มตัวที่มาจากสัตว์ในปริมาณสูง",
    nutrientSource:
      "แหล่งไขมันที่ดีสำหรับเด็กควรมาจากน้ำมันพืชได้แก่ น้ำมันถั่วเหลือง น้ำมันรำข้าว น้ำมันมะกอกน้ำ เป็นต้น",
  },
  {
    id: 5,
    type: "CHOLE",
    typeTH: "คอเลสเตอรอล",
    duty:
      "ไขมันอิ่มตัวและคอเลสเตอรอลสามารถได้รับจากภายนอกและสร้างได้ภายในร่างกาย ในเด็กเล็กใช้ไขมันและคอเลสเตอรอลในการสร้างฮอร์โมน ใช้ละลายวิตามินที่ละลายในไขมัน เอ ดี อี เค และมีความสำคัญต่องมีความสำคัญต่อการดูดซึมไขมันในอาหาร แหล่งไขมันที่ดีควรมาจากไขมันไม่อิ่มตัว และควรหลีกเลี่ยงการบริโภคไขมันอิ่มตัวที่มาจากสัตว์ในปริมาณสูง",
    nutrientSource:
      "แหล่งไขมันที่ดีสำหรับเด็กควรมาจากน้ำมันพืชได้แก่ น้ำมันถั่วเหลือง น้ำมันรำข้าว น้ำมันมะกอกน้ำ เป็นต้น",
  },
  {
    id: 6,
    type: "CA",
    typeTH: "แคลเซียม",
    duty:
      "แคลเซียมเป็นแร่ธาตุที่มีมากที่สุดในร่างกาย มีหน้าที่หลักคือรักษาความแข็งแรงและรูปร่าง",
    nutrientSource:
      "น้ำนมและผลิตภัณฑ์นมเป็นแหล่งอาหารแคลเซียมที่ดีที่สุด หากดื่ม",
  },
  {
    id: 7,
    type: "P",
    typeTH: "ฟอสฟอรัส",
    duty:
      "ฟอสฟอรัสมีบทบาทหน้าที่สำคัญในร่างกาย ทั้งด้านโครงสร้างร่างกายเป็นส่วนประกอบที่สำคัญของกระดูก เกี่ยวข้องกับการทำหน้าที่ของเซลล์และอวัยวะต่าง ๆ",
    nutrientSource:
      "แหล่งอาหารที่สำคัญของฟอสฟอรัส คือ น้ำนมและผลิตภัณฑ์ เนื้อสัตว์ต่าง ๆ ทั้งสัตว์บก สัตว์น้ำ และสัตว์ปีก ไข่แดง รำข้าว ถั่วเหลืองและผลิตภัณฑ์",
  },
  {
    id: 8,
    type: "FE",
    typeTH: "ธาตุเหล็ก",
    duty:
      "ธาตุเหล็กมีความสำคัญต่อโครงสร้างของฮีโมโกลบินซึ่งทำหน้าที่นำออกซิเจนไปยังส่วนต่าง ๆ ของร่างกาย ในเอนไซม์ที่ใช้ในกระบวนการสร้างพลังงาน มีบทบาทในการทำงานของสมอง",
    nutrientSource:
      "พบในอาหารกลุ่มเนื้อสัตว์ ร่างกายสามารถดูดซึมและนำไปใช้ได้ดีกว่า ธาตุเหล็กที่อยู่ในอาหารกลุ่มพืชเกือบทั้งหมด เช่น ถั่วเมล็ดแห้ง ผักใบเขียวเข้ม",
  },
  {
    id: 9,
    type: "K",
    typeTH: "โปรตัสเซียม",
    duty: "โปตัสเซียมช่วยรักษาภาวะสมดุลของน้ำและความเป็นกรด-ด่างภายในร่างกาย",
    nutrientSource:
      "โปตัสเซียมพบอยู่ในอาหารหลายประเภทโปตัสเซียมพบมากในเนื้อสัตว์ กระถิน ผักบุ้งไทย ชะอม ปวยเล้ง ดอกกระเจี๊ยบ ใบขี้เหล็ก ใบชะพลู กล้วย มะละกอสุก ลำไย ขนุน ทุเรียน ถั่วเมล็ดแห้งต่างๆ ข้าวกล้อง เมล็ดฟักทอง",
  },
  {
    id: 10,
    type: "NA",
    typeTH: "โซเดียม",
    duty:
      "ร่างกายต้องการโซเดียมเพื่อช่วยรักษาความสมดุลของแรงดันและการกระจายตัวของของเหลวในร่างกาย ทำให้ระบบไหลเวียนของของเหลวภายในร่างกายเป็นปกติ โดยเฉพาะของเหลวในหลอดเลือดเพื่อให้เลือดไหลเวียนนำอาหารและออกซิเจนไปเลี้ยงเซลล์ในอวัยวะต่าง ๆ ได้",
    nutrientSource:
      "อาหารทั่วไปตามธรรมชาติมีปริมาณโซเดียมเล็กน้อย อาหารที่มีปริมาณโซเดียมสูงได้แก่ อาหารสำเร็จรูป อาหารปรุงรสด้วยเครื่องปรุงต่างๆ การบริโภคซึ่งควรระมัดระวังในการบริโภค หากเด็กได้รับโซเดียมในปริมาณสูงอาจทำให้เด็กมีภาวะความดันโลหิตสูงและนำไปสู่การเกิดโรคหัวใจและโรคไตได้",
  },
  {
    id: 11,
    type: "CU",
    typeTH: "ทองแดง",
    duty:
      "ทองแดงเป็นส่วนประกอบเอนไซม์ที่เกี่ยวข้องกับการทำงานของเม็ดเลือดขาว ระบบประสาท",
    nutrientSource:
      "อาหารที่มีธาตุทองแดงมาก ได้แก่ เนื้อสัตว์ชนิดต่าง ๆ โดยเฉพาะตับมีมากที่สุด รองลงมาได้แก่ อาหารทะเลเช่น หอยนางรม ถั่วเมล็ดแห้ง โกโก้ เชอร์รี เห็ด ธัญชาติ และเจลาติน",
  },
  {
    id: 12,
    type: "MG",
    typeTH: "แมกนีเซียม",
    duty:
      "แมกนีเซียมมีบทบาทสำคัญในร่างกาย ช่วยการทำงานของเอนไซม์จำนวนมาก มีบทบาทในการควบคุมอุณหภูมิ การยืดหดของกล้ามเนื้อ การสังเคราะห์โปรตีน ซึ่งมีความสำคัญในเด็ก",
    nutrientSource:
      "อาหารที่มีปริมาณแมกนีเซียมสูง ได้แก่ ผักใบเขียว ผลไม้ ธัญชาติ ถั่วเมล็ดแห้ง น้ำนม",
  },
  {
    id: 13,
    type: "SE",
    typeTH: "ซีลีเนียม",
    duty: "ซีลีเนียมเป็นส่วนประกอบของเอนไซม์และโปรตีนต่าง ๆ มากมาย",
    nutrientSource: "ซีลีเนียมพบมากในอาหารทะเล ไข่ เนื้อสัตว์ และธัญชาติ",
  },
  {
    id: 14,
    type: "ZN",
    typeTH: "สังกะสี",
    duty:
      "ธาตุสังกะสี มีความสำคัญต่อการทำงานของร่างกายที่เกี่ยวข้องกับการเจริญเติบโต ภูมิคุ้มกันโรคติดเชื้อ การสืบพันธุ์และระบบประสาทที่ควบคุมพฤติกรรม",
    nutrientSource:
      "แหล่งอาหารที่ดีคือ เนื้อสัตว์และเครื่องในสัตว์ สัตว์น้ำเปลือกแข็งโดยเฉพาะหอยนางรม สัตว์ปีก และปลา แหล่งอาหารรองลงมา คือ ไข่ น้ำนมและผลิตภัณฑ์",
  },
  {
    id: 15,
    type: "VITA",
    typeTH: "วิตามินเอ",
    duty:
      "วิตามินเอ มีหน้าที่สำคัญเกี่ยวกับการมองเห็น โดยเฉพาะในแสงสลัว การเจริญเติบโต และทำให้เนื้อเยื่อต่าง ๆ อยู่ในสภาวะปกติ",
    nutrientSource:
      "แหล่งอาหารที่อุดมด้วยวิตามินเอ มาจากสัตว์ เช่น น้ำมันตับปลา ตับสัตว์ เนื้อสัตว์ ไข่ น้ำนม และผลิตภัณฑ์จากน้ำนม ผักใบสีเขียวเข้ม ผักและผลไม้ สีเหลืองหรือส้ม เช่น ตำลึง ผักบุ้ง แครอท ฟักทอง มันเทศเหลือง มะละกอสุก เป็นต้น",
  },
  {
    id: 16,
    type: "VITB1",
    typeTH: "วิตามินบี1",
    duty:
      "วิตามินบี1 ช่วยในการทำงานของเอนไซม์ในขบวนการเมตาบอลิสมของคาร์โบไฮเดรตและโปรตีน ร่างกายมนุษย์ไม่สามารถสร้างวิตามินบี 1 ได้ จำเป็นต้องได้รับจากอาหารเท่านั้น",
    nutrientSource:
      "วิตามินบี1 ทั้งในอาหารที่มาจากสัตว์และพืช มีมากในเนื้อหมู ข้าวซ้อมมือ จมูกข้าว ถั่วลิสง ถั่วเหลือง ถั่วดำและงา",
  },
  {
    id: 17,
    type: "VITB2",
    typeTH: "วิตามินบี2",
    duty:
      "วิตามินบี2 มีความสำคัญในขบวนการเผาผลาญสารอาหารคาร์โบไฮเดรต ไขมันและโปรตีน",
    nutrientSource:
      "วิตามินบี2 พบในอาหารทั่วไปทั้งในสัตว์และพืช อาหารที่พบมาก ได้แก่ เครื่องในสัตว์ เนื้อสัตว์ ไข่ น้ำนม ฯลฯ",
  },
  {
    id: 18,
    type: "VITB6",
    typeTH: "วิตามินบี6",
    duty:
      "วิตามินบี 6 มีบทบาทสำคัญช่วยในการทำงานของเอนไซม์ในปฏิกิริยาต่าง ๆ ที่เกี่ยวข้องกับการสังเคราะห์และการเผาผลาญกรดอะมิโน การสลายไกลโคเจนในกล้ามเนื้อและการสร้างกลูโคส จากกรดอะมิโนในกล้ามเนื้อ การสร้างฮีม ซึ่งเป็นส่วนประกอบของฮีโมโกลบินในเม็ดเลือดแดง",
    nutrientSource:
      "วิตามินบี 6 มีอยู่ในอาหารทั่วไปทั้งพืชและสัตว์ โดยเฉพาะเนื้อสัตว์ และไข่แดง",
  },
  {
    id: 19,
    type: "VITB12",
    typeTH: "วิตามินบี12",
    duty:
      " วิตามินบี12 ทำหน้าที่หลักที่สำคัญในการช่วยการทำงานของเอนไซม์กระบวนการเมตาบอลิสมของโปรตีน ไขมัน และคาร์โบไฮเดรต",
    nutrientSource:
      "อาหารที่มาจากสัตว์จึงเป็นแหล่งของวิตามินบี 12 ได้แก่ ตับ ไต เนื้อสัตว์ เช่น เนื้อหมู สัตว์ปีก เป็ด ไก่ ปลา รวมทั้ง กุ้ง หอย ปู ไข่ น้ำนมและผลิตภัณฑ์จากน้ำนม",
  },
  {
    id: 20,
    type: "VITBC",
    typeTH: "วิตามินซี / แอสคอรบิค",
    duty:
      "วิตามินซี เป็นวิตามินที่มีความสำคัญต่อชีวิตและการมีสุขภาพดี มีความสำคัญต่อการสร้างคอลลาเจน สารส่งผ่านประสาท มีบทบาทต่อเมตาบอลิสมของกรดอะมิโนและคาร์โบไฮเดรต ช่วยเพิ่มการดูดซึมธาตุเหล็ก รวมถึงการเพิ่มภูมิต้านทาน มนุษย์ไม่สามารถสังเคร",
    nutrientSource:
      "วิตามินซีพบมากในผัก ผลไม้ เช่น ฝรั่ง มะขามป้อม มะขามเทศ เงาะ มะละกอ ส้มโอ พริกหวาน คะน้า บรอกโคลี เป็นต้น",
  },
  {
    id: 21,
    type: "NIA",
    typeTH: "วิตามินบี3 / ไนอะซิน",
    duty:
      "วิตามินบี3 มีส่วนสำคัญในการสังเคราะห์ฮอร์โมนเพศ ลดระดับคอเลสเตอรอล ควบคุมการทำงานของสมองและระบบประสาท รักษาสุขภาพของผิวหนัง ลิ้น และเนื้อเยื่อ ของระบบย่อยอาหาร",
    nutrientSource:
      "แหล่งอาหารที่มีปริมาณวิตามินบี3 สูง ได้แก่ ไข่ ปลา เนื้อสัตว์ต่าง ๆ เครื่องในสัตว์ ถั่วเมล็ดแห้ง รำข้าว และยีสต์",
  },
  {
    id: 22,
    type: "VITE",
    typeTH: "วิตามินอี",
    duty:
      "วิตามินอีทำหน้าที่ขจัดอนุมูลอิสระที่เกิดขึ้นจากปฏิกิริยาต่าง ๆ ในร่างกายมนุษย์ ดังนั้น วิตามินอีจึงมีบทบาทในการป้องกันมิให้กรดไขมันไม่อิ่มตัวและส่วนประกอบเยื่อหุ้มเซลล์ของอวัยวะในร่างกายถูกทำลาย",
    nutrientSource:
      "น้ำมันพืชชนิดต่าง ๆ เช่น น้ำมันข้าวโพด น้ำมันรำข้าว น้ำมันถั่วเหลือง น้ำมันจมูกข้าวสาลี เป็นต้น",
  },
  {
    id: 23,
    type: "FBD",
    typeTH: "ปริมาณเส้นใย อาหาร",
    duty:
      "ใยอาหารทำให้การดูดซึมสารอาหารเป็นไปอย่างช้า ๆ ช่วยเพิ่มปริมาณอุจจาระ",
    nutrientSource:
      "ธัญชาติที่ไม่ผ่านการขัดสี เช่นเมล็ดถั่วต่างๆ ข้าวซ้อมมือ ข้าวกล้อง แหล่งอื่นๆ ได้แก่ โอลิโกแซคคาไรด์ อินูลิน เช่น กล้วย หัวหอม กระเทียม หน่อไม้ฝรั่ง เป็นต้น",
  },
];

export const AGE_DETAIL = [
  {
    id: 2,
    title: "2 ขวบ",
    detail: [
      {
        status: "good",
        bgColor: "#E8F5D8",
        iconColor: "#6B8A47",
        title: "ตามเกณฑ์ - ดี",
        detail: `<ol>
        <li style="list-style-type: none;">
        <ol style="font-size: 16px">
          <li>ควรรับประทานอาหารหลัก 3 มื้อต่อวันและอาหารว่างที่มีคุณภาพไม่เกิน 2 มื้อต่อวัน โดยอาหารมื้อหลักควรครบ 5 หมู่ และมีความหลากหลายแต่ละวัน การรับประทานอาหารในแต่ละวันควรประกอบด้วยดังต่อไปนี้&nbsp; โดยลักษณะอาหารคล้ายคลึงกับผู้ใหญ่**</li>
          <ul>
            <li>ข้าวแป้งวันละ 3 ทัพพี&nbsp;</li>
            <li>เนื้อสัตว์ 3 ช้อนกินข้าว&nbsp;</li>
            <li>ผัก 6 ช้อนกินข้าว&nbsp;</li>
            <li>ผลไม้ 3 ส่วน (1 ส่วนเท่ากับ 6-8 พอดีคำ)&nbsp;</li>
            <li>น้ำมันหรือกะทิน้อยกว่า 3 ช้อนชา</li>
            <li>น้ำตาลน้อยกว่า 2 ช้อนชา</li>
          </ul>
          <li>
            อาหารว่างที่เหมาะสมควร ควรมีพลังงานไม่เกิน 100-150 กิโลแคลอรีต่อมื้อ
            โดยมีปริมาณน้ำมัน
            น้ำตาลและเกลือไม่สูงเกินไปและควรประกอบด้วยสารอาหารที่จำเป็นต่อร่างกาย
            เช่น นมจืด น้ำผลไม้รสธรรมชาติ ผลไม้ มันเทศต้ม ข้าวโพดต้ม
            ขนมไทยขนมไทยรสไม่หวาน ขนมจีบ ซาลาเปา
          </li>
          <li>อาหารว่างที่ควรหลีกเลี่ยง ได้แก่ เบเกอรี่ เช่น เค้ก คุกกี้ พายชนิดต่างๆ ขนมกรุบกรอบ ลูกกวาด ลูกอม ช็อกโกแลต ไอศกรีม น้ำอัดลม นมเปรี้ยวพร้อมดื่มหรือนมปรุงแต่งรสหวาน นมผลไม้ เครื่องดื่มรสหวาน เช่น น้ำหวาน ชานม หรือน้ำสมุนไพรที่เติมน้ำตาลมาก</li>
          <li>เด็กวัยเตาะแตะควร ควรฝึกฝนให้กินอาหารรสธรรมชาติ ไม่หวานจัด มันจัดและเค็มจัด</li>
          <li>ให้นมแม่ต่อเนื่องถึงอายุ 2 ปี หรือดื่มนมสดรสจืด วันละ 2-3 แก้ว (แก้วละ 200 มิลลิลิตร) การดื่มนมมากเกินไปจะส่งผลให้เด็กเกิดโรคอ้วนหรือขาดสารอาหารจำเป็นบางชนิด เช่น เหล็ก วิตามินซี</li>
          <li>เด็กวัยเตาะแตะจะเริ่มกินอาหารเองโดยการใช้ช้อนร่วมกับการใช้นิ้วมือ เริ่มขออาหารเองได้ แต่จะมีการต่อต้าน (negativism) อาจบอกว่าไม่กินแม้ว่าจะหิวก็ตามเนื่องจากต้องการควบคุมการกินและมื้ออาหารด้วยตนเอง หลังอายุ 2 ปี เด็กจะเริ่มใช้ส้อมได้ดีขึ้น เริ่มกินอาหารเป็นเวลา ชอบช่วยเตรียมและเก็บโต๊ะอาหาร ดังนั้นผู้ปกครองควรฝึกฝนวินัยการกินอย่างเหมาะสมตามวัยจนเป็นนิสัย โดยกินอาหารมื้อหลักมื้อละไม่เกิน 30 นาทีและมื้ออาหารหลักกับอาหารว่างรวมถึงนมควรห่างกันอย่างน้อย 2 ชั่วโมง</li>
          <li>การให้อาหารชนิดใหม่ ถ้าเด็กไม่รับประทานในครั้งแรก ควรให้ซ้ำๆ 10-15 ครั้ง จึงตัดสินว่าเด็กปฏิเสธอาหารชนิดนั้นเนื่องจากวัยนี้เด็กจะมีความกลัวอาหารชนิดใหม่ (food neophobia) ซึ่งเป็นพัฒนาการปกติของเด็กวัยเตาะแตะ</li>
          <li>ทารกและเด็กเล็กกอายุ 6 เดือนถึง 2 ปี ควรได้รับยาน้ำเสริมธาตุเหล็กขนาด 12.5 มิลลิกรัม สัปดาห์ละครั้ง</li>
          </ol>
          </li>
        </ol>`,
      },
      {
        status: "bad",
        bgColor: "#FFFBEB",
        iconColor: "#FEA73F",
        title: "เด็กมีภาวะเตี้ย/ผอม",
        detail: `<ol style="font-size: 16px">
        <li>ทบทวนว่า เด็กได้รับอาหารที่มีพลังงานเพียงพอหรือไม่ ซึ่งสามารถสังเกตได้จากปริมาณและสัดส่วนของอาหารว่าเป็นไปตามคำแนะนำหรือไม่ นอกจากนี้ยังควรทบทวนว่าเด็กได้อาหารกลุ่มที่มีปริมาณธาตุเหล็กและสังกะสีสูง เช่น เนื้อสัตว์ ตับ ไข่แดง อย่าง เพียงพอ อาจจะพิจารณาเพิ่มความเข้มข้นของพลังงานโดยการเติมน้ำมันลงในอาหารหรือเพิ่ม อาหารกลุ่มที่ปรุงด้วยวิธีผัด ทอด เจียวหรือมีส่วนประกอบของกะทิ</li>
        <li>ทบทวนวินัยการกิน โดยอาหารมื้อหลักแต่ละมื้อควรห่างกันอย่างน้อย 3-4 ชั่วโมงและอาหารมื้อหลักกับอาหารว่างรวมถึงนมควรห่างกันอย่างน้อย 2 ชั่วโมง ในระหว่างมื้ออาหารไม่ควรให้อาหารอื่นนอกจากน้ำเปล่า ในระหว่างมื้ออาหารไม่ควรมีสิ่งรบกวน</li>
        <li>หากดื่มนมมากกว่า 2-3 แก้วต่อวัน ควรลดปริมาณนมลง เพราะการดื่มนมมากเกินไปอาจทำให้กินอาหารมื้อหลักได้น้อย อาจทำให้ได้สารอาหารไม่ครบ</li>
        </ol>`,
      },
      {
        status: "bad",
        bgColor: "#FFFBEB",
        iconColor: "#FEA73F",
        title: "เด็กมีภาวะอ้วน",
        detail: `<ol style="font-size: 16px">
        <li>ให้ทบทวนว่าเด็กได้รับอาหารมื้อหลักและอาหารว่างในปริมาณและสัดส่วนที่เหมาะสม ลดอาหารที่ปรุงด้วยวิธีผัด ทอด เจียวหรือมีส่วนประกอบของกะทิ</li>
        <li>กรณีที่ดื่มนมมากกว่า 2-3 แก้วต่อวัน ควรลดปริมาณนมลงและเปลี่ยนชนิดเป็นนมรสจืด หากยังได้รับนมจากขวดนม ให้เลิกขวดนม</li>
        <li>ควรทบทวนเพิ่มเติมว่าเด็กได้รับอาหารที่มีการปรุงแต่งรสหรือไม่ ได้รับอาหารว่างที่ไม่เป็นผลดีต่อสุขภาพหรือไม่ หากได้รับให้งด และเปลี่ยนเป็นอาหารว่างที่มีคุณภาพแทน</li>
        </ol>`,
      },
    ],
  },
  {
    id: 3,
    title: "3 ขวบ",
    detail: [
      {
        status: "good",
        bgColor: "#E8F5D8",
        iconColor: "#6B8A47",
        title: "ตามเกณฑ์ - ดี",
        detail: `<ol>
        <li style="list-style-type: none;">
        <ol style="font-size: 16px">
          <li><span>ควรรับประทานอาหารหลัก 3 มื้อต่อวันและอาหารว่างที่มีคุณภาพไม่เกิน 2 มื้อต่อวัน โดยอาหารมื้อหลักควรครบ 5 หมู่ และมีความหลากหลายแต่ละวัน </span>การรับประทานอาหารในแต่ละวันควรประกอบด้วยดังต่อไปนี้&nbsp; <span>โดยลักษณะอาหารคล้ายคลึงกับผู้ใหญ่</span>**</li>
          <ul>
            <li><span>ข้าวแป้งวันละ 3 ทัพพี&nbsp;</span></li>
            <li><span>เนื้อสัตว์ 3 ช้อนกินข้าว&nbsp;</span></li>
            <li><span>ผัก 6 ช้อนกินข้าว&nbsp;</span></li>
            <li><span>ผลไม้ 3 ส่วน (1 ส่วนเท่ากับ 6-8 พอดีคำ)&nbsp;</span></li>
            <li><span>น้ำมันหรือกะทิน้อยกว่า 3 ช้อนชา&nbsp;</span></li>
            <li><span>น้ำตาลน้อยกว่า 2 ช้อนชา</span></li>
          </ul>
          <li><span>อาหารว่างที่เหมาะสมควร ควรมีพลังงานไม่เกิน 100-150 กิโลแคลอรีต่อมื้อ โดยมีปริมาณน้ำมัน น้ำตาลและเกลือไม่สูงเกินไปและควรประกอบด้วยสารอาหารที่จำเป็นต่อร่างกาย เช่น นมจืด น้ำผลไม้รสธรรมชาติ ผลไม้ มันเทศต้ม ข้าวโพดต้ม ขนมไทยรสไม่หวาน ขนมจีบ ซาลาเปา</span></li>
          <li><span>อาหารว่างที่ควรหลีกเลี่ยง ได้แก่ เบเกอรี่ เช่น เค้ก คุกกี้ พายชนิดต่างๆ ขนมกรุบกรอบ ลูกกวาด ลูกอม ช็อกโกแลต ไอศกรีม น้ำอัดลม นมเปรี้ยวพร้อมดื่มหรือนมปรุงแต่งรสหวาน นมผลไม้ เครื่องดื่มรสหวาน เช่น น้ำหวาน ชานม หรือน้ำสมุนไพรที่เติมน้ำตาลมาก</span></li>
          <li><span>เด็กวัยเตาะแตะควร ควรฝึกฝนให้กินอาหารรสธรรมชาติ ไม่หวานจัด มันจัดและเค็มจัด</span></li>
          <li><span>ให้นมแม่ต่อเนื่องถึงอายุ 2 ปีหรือดื่มนมสดรสจืด วันละ 2-3 แก้ว (แก้วละ 200 มิลลิลิตร) การดื่มนมมากเกินไปจะส่งผลให้เด็กเกิดโรคอ้วนหรือขาดสารอาหารจำเป็นบางชนิด เช่น เหล็ก วิตามินซี</span></li>
          <li><span>เด็กอายุ 3 ปี จะสามารถใช้ช้อนและส้อมได้ดี ล้างมือเองได้ ชอบช่วยเตรียมอาหารแต่การให้อาหารชนิดใหม่ อาจจะมีปัญหาปฏิเสธอาหารชนิดใหม่และต่อต้านการกินอยู่บ้าง</span></li>
          <li><span>ผู้ปกครองควรฝึกฝนวินัยการกินอย่างเหมาะสมตามวัยจนเป็นนิสัย โดยกินอาหารมื้อหลัก มื้อละไม่เกิน 30 นาที และมื้ออาหารหลักกับอาหารว่างรวมถึงนมควรห่างกันอย่างน้อย 2 ชั่วโมง</span></li>
          <li><span>การให้อาหารชนิดใหม่ ถ้าเด็กไม่รับประทานในครั้งแรก ควรให้ซ้ำๆ 10-15 ครั้ง จึงตัดสินว่าเด็กปฏิเสธอาหารชนิดนั้น</span></li>
          <li><span>เด็กอายุ 2-5 ปี ควรได้รับยาน้ำเสริมธาตุเหล็ก 25 มิลลิกรัม สัปดาห์ละ 1 ครั้ง</span></li>
        </ol>`,
      },
      {
        status: "bad",
        bgColor: "#FFFBEB",
        iconColor: "#FEA73F",
        title: "เด็กมีภาวะเตี้ย/ผอม",
        detail: `<ol style="font-size: 16px">
        <li><span>ทบทวนว่า เด็กได้รับอาหารที่มีพลังงานเพียงพอหรือไม่ ซึ่งสามารถสังเกตได้จากปริมาณและสัดส่วนของอาหารว่าเป็นไปตามคำแนะนำหรือไม่ นอกจากนี้ยังควรทบทวนว่าเด็กได้อาหารกลุ่มที่มีปริมาณธาตุเหล็กและสังกะสีสูง เช่น เนื้อสัตว์ ตับ ไข่แดง อย่าง เพียง</span><span>พอ อาจจะพิจารณาเพิ่มความเข้มข้นของพลังงานโดยการเติมน้ำมันลงในอาหารหรือเพิ่ม อาหารกลุ่มที่ปรุงด้วยวิธีผัด ทอด เจียวหรือมีส่วนประกอบของกะทิ</span></li>
        <li><span>ทบทวนวินัยการกิน โดยอาหารมื้อหลักแต่ละมื้อควรห่างกันอย่างน้อย 3-4 ชั่วโมงและอาหารมื้อหลักกับอาหารว่างรวมถึงนมควรห่างกันอย่างน้อย 2 ชั่วโมง ในระหว่างมื้ออาหารไม่ควรให้อาหารอื่นนอกจากน้ำเปล่า ในระหว่างมื้ออาหารไม่ควรมีสิ่งรบกวน</span></li>
        <li><span>หากดื่มนมมากกว่า 2-3 แก้วต่อวัน ควรลดปริมาณนมลง เพราะการดื่มนมมากเกินไปอาจทำให้กินอาหารมื้อหลักได้น้อย อาจทำให้ได้สารอาหารไม่ครบ</span></li>
        </ol>`,
      },
      {
        status: "bad",
        bgColor: "#FFFBEB",
        iconColor: "#FEA73F",
        title: "เด็กมีภาวะอ้วน",
        detail: `<ol style="font-size: 16px">
        <li><span>ให้ทบทวนว่าเด็กได้รับอาหารมื้อหลักและอาหารว่างในปริมาณและสัดส่วนที่เหมาะสม ลดอาหารที่ปรุงด้วยวิธีผัด ทอด เจียวหรือมีส่วนประกอบของกะทิ</span></li>
        <li><span>กรณีที่ดื่มนมมากกว่า 2-3 แก้วต่อวัน ควรลดปริมาณนมลงและเปลี่ยนชนิดเป็นนมรสจืด หากยังได้รับนมจากขวดนม ให้เลิกขวดนม</span></li>
        <li><span>ควรทบทวนเพิ่มเติมว่าเด็กได้รับอาหารที่มีการปรุงแต่งรสหรือไม่ ได้รับอาหารว่างที่ไม่เป็นผลดีต่อสุขภาพหรือไม่ หากได้รับให้งด และเปลี่ยนเป็นอาหารว่างที่มีคุณภาพแทน</span></li>
        </ol>`,
      },
    ],
  },
  {
    id: 4,
    title: "4 ขวบ",
    detail: [
      {
        status: "good",
        bgColor: "#E8F5D8",
        iconColor: "#6B8A47",
        title: "ตามเกณฑ์ - ดี",
        detail: `<ol>
        <li style="list-style-type: none;">
        <ol style="font-size: 16px">
          <li><span>ควรรับประทานอาหารหลัก 3 มื้อต่อวันและอาหารว่างที่มีคุณภาพไม่เกิน 2 มื้อต่อวัน โดยอาหารมื้อหลักควรครบ 5 หมู่ และมีความหลากหลายแต่ละวัน </span>การรับประทานอาหารในแต่ละวันควรประกอบด้วยดังต่อไปนี้&nbsp;<span>โดยลักษณะอาหารคล้ายคลึงกับผู้ใหญ่</span>**</li>
          <ul>
            <li><span>ข้าวแป้งวันละ 5 ทัพพี&nbsp;</span></li>
            <li><span>เนื้อสัตว์ 3 ช้อนกินข้าว&nbsp;</span></li>
            <li><span>ผัก 6 ช้อนกินข้าว&nbsp;</span></li>
            <li><span>ผลไม้ 3 ส่วน (1 ส่วนเท่ากับ 6-8 พอดีคำ)&nbsp;</span></li>
            <li><span>น้ำมันหรือกะทิน้อยกว่า 4 ช้อนชา&nbsp;</span></li>
            <li><span>น้ำตาลน้อยกว่า 3 ช้อนชา&nbsp;</span></li>
            <li><span>เสริมนมจืดวันละ 2-3 แก้ว (แก้วละ 200 มิลลิลิตร)</span></li>
          </ul>
          <li><span>อาหารว่างที่เหมาะสมควร ควรมีพลังงานไม่เกิน 100-150 กิโลแคลอรีต่อมื้อ โดยมีปริมาณน้ำมัน น้ำตาลและเกลือไม่สูงเกินไปและควรประกอบด้วยสารอาหารที่จำเป็นต่อร่างกาย เช่น นม ผลไม้ เป็นต้น</span></li>
          <li><span>ควรฝึกฝนให้กินอาหารรสธรรมชาติ ไม่หวานจัด มันจัด และเค็มจัด รวมถึงหลีกเลี่ยงเครื่องดื่มรสหวานและน้ำอัดลม ขนมกรุบกรอบ</span></li>
          <li><span>ฝึกฝนวินัยการกินอย่างเหมาะสมตามวัยจนเป็นนิสัย โดยกินอาหารมื้อหลักมื้อละไม่เกิน</span><span><br /></span><span>30 นาที และมื้ออาหารหลักกับอาหารว่างรวมถึงนมควรห่างกันอย่างน้อย 2 ชั่วโมง</span></li>
          <li><span>ควรให้อาหารชนิดใหม่ซ้ำๆ 10-15 ครั้ง จึงตัดสินว่าเด็กปฏิเสธอาหารชนิดนั้นๆ</span></li>
          <li><span>เด็กอายุ 2-5 ปี ควรได้รับยาน้ำเสริมธาตุเหล็ก 25 มิลลิกรัม สัปดาห์ละ 1 ครั้ง</span></li>
        </ol>`,
      },
      {
        status: "bad",
        bgColor: "#FFFBEB",
        iconColor: "#FEA73F",
        title: "เด็กมีภาวะเตี้ย/ผอม",
        detail: `<ol style="font-size: 16px">
        <li><span>ทบทวนว่า เด็กได้รับอาหารที่มีพลังงานเพียงพอหรือไม่ ซึ่งสามารถสังเกตได้จากปริมาณและสัดส่วนของอาหารว่าเป็นไปตามคำแนะนำหรือไม่ นอกจากนี้ยังควรทบทวนว่าเด็ก</span><span>ได้อาหารกลุ่มที่มีปริมาณธาตุเหล็กและสังกะสีสูง เช่น เนื้อสัตว์ ตับ ไข่แดง อย่าง เพียงพอ อาจจะพิจารณาเพิ่มความเข้มข้นของพลังงานโดยการเติมน้ำมันลงในอาหารหรือเพิ่ม อาหารกลุ่มที่ปรุงด้วยวิธีผัด ทอด เจียวหรือมีส่วนประกอบของกะทิ</span></li>
        <li><span>ทบทวนวินัยการกิน โดยอาหารมื้อหลักแต่ละมื้อควรห่างกันอย่างน้อย 3-4 ชั่วโมงและอาหารมื้อหลักกับอาหารว่างรวมถึงนมควรห่างกันอย่างน้อย 2 ชั่วโมง ในระหว่างมื้ออาหารไม่ควรให้อาหารอื่นนอกจากน้ำเปล่า ในระหว่างมื้ออาหารไม่ควรมีสิ่งรบกวน</span></li>
        <li><span>หากดื่มนมมากกว่า 2-3 แก้วต่อวัน ควรลดปริมาณนมลง เพราะการดื่มนมมากเกินไปอาจทำให้กินอาหารมื้อหลักได้น้อย อาจทำให้ได้สารอาหารไม่ครบ</span></li>
        </ol>`,
      },
      {
        status: "bad",
        bgColor: "#FFFBEB",
        iconColor: "#FEA73F",
        title: "เด็กมีภาวะอ้วน",
        detail: `<ol style="font-size: 16px">
        <li><span>ให้ทบทวนว่าเด็กได้รับอาหารมื้อหลักและอาหารว่างในปริมาณและสัดส่วนที่เหมาะสม ลดอาหารที่ปรุงด้วยวิธีผัด ทอด เจียวหรือมีส่วนประกอบของกะทิ</span></li>
        <li><span>กรณีที่ดื่มนมมากกว่า 2-3 แก้วต่อวัน ควรลดปริมาณนมลงและเปลี่ยนชนิดเป็นนมรสจืด หากยังได้รับนมจากขวดนม ให้เลิกขวดนม</span></li>
        <li><span>ควรทบทวนเพิ่มเติมว่าเด็กได้รับอาหารที่มีการปรุงแต่งรสหรือไม่ ได้รับขนมกรุบกรอบ ขนมหวาน ขนมเบเกอรี่หรือเครื่องดื่มรสหวาน เช่น น้ำผลไม้ที่เติมน้ำตาล น้ำอัดลมหรือไม่ หากได้รับให้งดและเปลี่ยนเป็นอาหารว่างที่มีคุณภาพแทน</span></li>
        </ol>`,
      },
    ],
  },
  {
    id: 5,
    title: "5 ขวบ",
    detail: [
      {
        status: "good",
        bgColor: "#E8F5D8",
        iconColor: "#6B8A47",
        title: "ตามเกณฑ์ - ดี",
        detail: `<ol>
        <li style="list-style-type: none;">
        <ol style="font-size: 16px">
          <li><span>ควรรับประทานอาหารหลัก 3 มื้อต่อวันและอาหารว่างที่มีคุณภาพไม่เกิน 2 มื้อต่อวัน โดยอาหารมื้อหลักควรครบ 5 หมู่ และมีความหลากหลายแต่ละวัน </span>การรับประทานอาหารในแต่ละวันควรประกอบด้วยดังต่อไปนี้&nbsp; <span>โดยลักษณะอาหารคล้ายคลึงกับผู้ใหญ่</span>**</li>
          <ul>
            <li><span >ข้าวแป้งวันละ 5 ทัพพี&nbsp;</span></li>
            <li><span >เนื้อสัตว์ 3 ช้อนกินข้าว&nbsp;</span></li>
            <li><span >ผัก 9 ช้อนกินข้าว&nbsp;</span></li>
            <li><span >ผลไม้ 3 ส่วน (1 ส่วนเท่ากับ 6-8 พอดีคำ)&nbsp;</span></li>
            <li><span >น้ำมันหรือกะทิน้อยกว่า 4 ช้อนชา&nbsp;</span></li>
            <li><span >น้ำตาลน้อยกว่า 3 ช้อนชา&nbsp;</span></li>
            <li><span >เสริมนมจืดวันละ 2-3 แก้ว (แก้วละ 200 มิลลิลิตร)</span></li>
          </ul>
          <li><span>อาหารว่างที่เหมาะสมควร ควรมีพลังงานไม่เกิน 100-150 กิโลแคลอรีต่อมื้อ โดยมีปริมาณน้ำมัน น้ำตาลและเกลือไม่สูงเกินไปและควรประกอบด้วยสารอาหารที่จำเป็นต่อร่างกาย เช่น นม ผลไม้ เป็นต้น</span></li>
          <li><span>ควรฝึกฝนให้กินอาหารรสธรรมชาติ ไม่หวานจัด มันจัด และเค็มจัด รวมถึงหลีกเลี่ยงเครื่องดื่มรสหวานและน้ำอัดลม ขนมกรุบกรอบ</span></li>
          <li><span>ฝึกฝนวินัยการกินอย่างเหมาะสมตามวัยจนเป็นนิสัย โดยกินอาหารมื้อหลักมื้อละไม่เกิน</span><span><br /></span><span>30 นาที และมื้ออาหารหลักกับอาหารว่างรวมถึงนมควรห่างกันอย่างน้อย 2 ชั่วโมง</span></li>
          <li><span>ควรให้อาหารชนิดใหม่ซ้ำๆ 10-15 ครั้ง จึงตัดสินว่าเด็กปฏิเสธอาหารชนิดนั้นๆ</span></li>
          <li><span>เด็กอายุ 2-5 ปี ควรได้รับยาน้ำเสริมธาตุเหล็ก 25 มิลลิกรัม สัปดาห์ละ 1 ครั้ง</span></li>
      </ol>`,
      },
      {
        status: "bad",
        bgColor: "#FFFBEB",
        iconColor: "#FEA73F",
        title: "เด็กมีภาวะเตี้ย/ผอม",
        detail: `<ol style="font-size: 16px">
        <li><span>ทบทวนว่า เด็กได้รับอาหารที่มีพลังงานเพียงพอหรือไม่ ซึ่งสามารถสังเกตได้จากปริมาณและสัดส่วนของอาหารว่าเป็นไปตามคำแนะนำหรือไม่ นอกจากนี้ยังควรทบทวนว่าเด็กได้อาหารกลุ่มที่มีปริมาณธาตุเหล็กและสังกะสีสูง เช่น เนื้อสัตว์ ตับ ไข่แดง อย่าง เพียงพอ อาจจะพิจารณาเพิ่มความเข้มข้นของพลังงานโดยการเติมน้ำมันลงในอาหารหรือเพิ่ม อาหารกลุ่มที่ปรุงด้วยวิธีผัด ทอด เจียวหรือมีส่วนประกอบของกะทิ</span></li>
        <li><span>ทบทวนวินัยการกิน โดยอาหารมื้อหลักแต่ละมื้อควรห่างกันอย่างน้อย 3-4 ชั่วโมงและอาหารมื้อหลักกับอาหารว่างรวมถึงนมควรห่างกันอย่างน้อย 2 ชั่วโมง ในระหว่างมื้ออาหารไม่ควรให้อาหารอื่นนอกจากน้ำเปล่า ในระหว่างมื้ออาหารไม่ควรมีสิ่งรบกวน</span></li>
        <li><span>หากดื่มนมมากกว่า 2-3 แก้วต่อวัน ควรลดปริมาณนมลง เพราะการดื่มนมมากเกินไปอาจทำให้กินอาหารมื้อหลักได้น้อย อาจทำให้ได้สารอาหารไม่ครบ</span></li>
        </ol>`,
      },
      {
        status: "bad",
        bgColor: "#FFFBEB",
        iconColor: "#FEA73F",
        title: "เด็กมีภาวะอ้วน",
        detail: `<ol style="font-size: 16px">
        <li><span>ให้ทบทวนว่าเด็กได้รับอาหารมื้อหลักและอาหารว่างในปริมาณและสัดส่วนที่เหมาะสม ลดอาหารที่ปรุงด้วยวิธีผัด ทอด เจียวหรือมีส่วนประกอบของกะทิ</span></li>
        <li><span>กรณีที่ดื่มนมมากกว่า 2-3 แก้วต่อวัน ควรลดปริมาณนมลงและเปลี่ยนชนิดเป็นนมรสจืด หากยังได้รับนมจากขวดนม ให้เลิกขวดนม</span></li>
        <li><span>ควรทบทวนเพิ่มเติมว่าเด็กได้รับอาหารที่มีการปรุงแต่งรสหรือไม่ ได้รับขนมกรุบกรอบ ขนมหวาน ขนมเบเกอรี่หรือเครื่องดื่มรสหวาน เช่น น้ำผลไม้ที่เติมน้ำตาล น้ำอัดลมหรือไม่ หากได้รับให้งดและเปลี่ยนเป็นอาหารว่างที่มีคุณภาพแทน</span></li>
        </ol>`,
      },
    ],
  },
  {
    id: 6,
    title: "6 ขวบ",
    detail: [
      {
        status: "good",
        bgColor: "#E8F5D8",
        iconColor: "#6B8A47",
        title: "ตามเกณฑ์ - ดี",
        detail: `<ol>
        <li style="list-style-type: none;">
        <ol style="font-size: 16px">
          <li>ควรรับประทานอาหารหลัก 3 มื้อต่อวันและอาหารว่างที่มีคุณภาพไม่เกิน 2 มื้อต่อวัน โดยอาหารมื้อหลักควรครบ 5 หมู่ และมีความหลากหลายแต่ละวัน การรับประทานอาหารในแต่ละวันควรประกอบด้วยดังต่อไปนี้&nbsp; โดยลักษณะอาหารคล้ายคลึงกับผู้ใหญ่**</li>
          <ul>
            <li><span>ข้าวแป้งวันละ 5 ทัพพี&nbsp;</span></li>
            <li><span>เนื้อสัตว์ 3 ช้อนกินข้าว&nbsp;</span></li>
            <li><span>ผัก 9 ช้อนกินข้าว&nbsp;</span></li>
            <li><span>ผลไม้ 3 ส่วน (1 ส่วนเท่ากับ 6-8 พอดีคำ)&nbsp;</span></li>
            <li><span>น้ำมันหรือกะทิน้อยกว่า 4 ช้อนชา&nbsp;</span></li>
            <li><span>น้ำตาลน้อยกว่า 3 ช้อนชา&nbsp;</span></li>
            <li><span>เสริมนมจืดวันละ 2-3 แก้ว (แก้วละ 200 มิลลิลิตร)</span></li>
            <li>&nbsp;</li>
          </ul>
          <li><span>อาหารว่างที่เหมาะสมควร ควรมีพลังงานไม่เกิน 100-150 กิโลแคลอรีต่อมื้อ โดยมีปริมาณน้ำมัน น้ำตาลและเกลือไม่สูงเกินไปและควรประกอบด้วยสารอาหารที่จำเป็นต่อร่างกาย เช่น นม ผลไม้ เป็นต้น</span></li>
          <li><span>ควรฝึกฝนให้กินอาหารรสธรรมชาติ ไม่หวานจัด มันจัด และเค็มจัด รวมถึงหลีกเลี่ยงเครื่องดื่มรสหวานและน้ำอัดลม ขนมกรุบกรอบ</span></li>
          <li><span>ฝึกฝนวินัยการกินอย่างเหมาะสมตามวัยจนเป็นนิสัย โดยกินอาหารมื้อหลักมื้อละไม่เกิน 30 นาที และมื้ออาหารหลักกับอาหารว่างรวมถึงนมควรห่างกันอย่างน้อย 2 ชั่วโมง</span></li>
          <li><span>ควรให้อาหารชนิดใหม่ซ้ำๆ 10-15 ครั้ง จึงตัดสินว่าเด็กปฏิเสธอาหารชนิดนั้นๆ</span></li>
          <li><span>เด็กอายุ 2-5 ปี ควรได้รับยาน้ำเสริมธาตุเหล็ก 25 มิลลิกรัม สัปดาห์ละ 1 ครั้ง</span></li>
        </ol>`,
      },
      {
        status: "bad",
        bgColor: "#FFFBEB",
        iconColor: "#FEA73F",
        title: "เด็กมีภาวะเตี้ย/ผอม",
        detail: `<ol style="font-size: 16px">
        <li><span>ทบทวนว่า เด็กได้รับอาหารที่มีพลังงานเพียงพอหรือไม่ ซึ่งสามารถสังเกตได้จากปริมาณและสัดส่วนของอาหารว่าเป็นไปตามคำแนะนำหรือไม่ นอกจากนี้ยังควรทบทวนว่าเด็กได้อาหารกลุ่มที่มีปริมาณธาตุเหล็กและสังกะสีสูง เช่น เนื้อสัตว์ ตับ ไข่แดง อย่าง เพียงพอ อาจจะพิจารณาเพิ่มความเข้มข้นของพลังงานโดยการเติมน้ำมันลงในอาหารหรือเพิ่ม อาหารกลุ่มที่ปรุงด้วยวิธีผัด ทอด เจียวหรือมีส่วนประกอบของกะทิ</span></li>
        <li><span>ทบทวนวินัยการกิน โดยอาหารมื้อหลักแต่ละมื้อควรห่างกันอย่างน้อย 3-4 ชั่วโมงและอาหารมื้อหลักกับอาหารว่างรวมถึงนมควรห่างกันอย่างน้อย 2 ชั่วโมง ในระหว่างมื้ออาหารไม่ควรให้อาหารอื่นนอกจากน้ำเปล่า ในระหว่างมื้ออาหารไม่ควรมีสิ่งรบกวน</span></li>
        <li><span>หากดื่มนมมากกว่า 2-3 แก้วต่อวัน ควรลดปริมาณนมลง เพราะการดื่มนมมากเกินไปอาจทำให้กินอาหารมื้อหลักได้น้อย อาจทำให้ได้สารอาหารไม่ครบ</span></li>
        </ol>`,
      },
      {
        status: "bad",
        bgColor: "#FFFBEB",
        iconColor: "#FEA73F",
        title: "เด็กมีภาวะอ้วน",
        detail: `<ol style="font-size: 16px">
        <li><span>ให้ทบทวนว่าเด็กได้รับอาหารมื้อหลักและอาหารว่างในปริมาณและสัดส่วนที่เหมาะสม ลดอาหารที่ปรุงด้วยวิธีผัด ทอด เจียวหรือมีส่วนประกอบของกะทิ</span></li>
        <li><span>กรณีที่ดื่มนมมากกว่า 2-3 แก้วต่อวัน ควรลดปริมาณนมลงและเปลี่ยนชนิดเป็นนมรสจืด หากยังได้รับนมจากขวดนม ให้เลิกขวดนม</span></li>
        <li><span>ควรทบทวนเพิ่มเติมว่าเด็กได้รับอาหารที่มีการปรุงแต่งรสหรือไม่ ได้รับขนมกรุบกรอบ ขนมหวาน ขนมเบเกอรี่หรือเครื่องดื่มรสหวาน เช่น น้ำผลไม้ที่เติมน้ำตาล น้ำอัดลมหรือไม่ หากได้รับให้งดและเปลี่ยนเป็นอาหารว่างที่มีคุณภาพแทน</span></li>
        </ol>`,
      },
    ],
  },
];

export const ABOUT = [
  {
    img: "/media/S__49102859.png",
    detail: [
      {
        title: "วัตถุประสงค์",
        detail:
          "1. เพื่อศึกษาภาวะโภชนาการ พฤติกรรมการบริโภค และประเมินอาหารบริโภคของเด็กวัยก่อนเรียนในศูนย์พัฒนาเด็กเล็กในพื้นที่อำเภอแม่ฟ้าหลวง จังหวัดเชียงราย \n2. เพื่อสร้างเว็บไซต์ที่เป็นระบบฐานข้อมูลภาวะโภชนาการของเด็กวัยก่อนเรียนในศูนย์พัฒนาเด็กเล็กในพื้นที่อำเภอแม่ฟ้าหลวง จังหวัดเชียงราย",
      },
      {
        title: "แหล่งทุน",
        detail:
          "กองทุนส่งเสริมวิทยาศาสตร์ วิจัยและนวัตกรรม (กองทุน ววน.) : งบประมาณด้าน ววน. ประเภท Fundamental Fund ประจำปีงบประมาณ 2566",
      },
    ],
  },
];

export const EXPERT = [
  {
    img: "/media/ศิวรักษ์.png",
    name: "ผศ.ดร. ศิวรักษ์ กิจชนะไพบูลย์",
    position: "หัวหน้าโครงการ",
  },
  {
    img: "/media/ขวัญตา.png",
    name: "ดร. ขวัญตา คีรีมาศทอง",
    position: "ผู้ร่วมวิจัย",
  },
  {
    img: "/media/ชมนาด.png",
    name: "อาจารย์ ชมนาด สิงห์หันต์",
    position: "ผู้ร่วมวิจัย",
  },
  {
    img: "/media/ฐาปกรณ์.png",
    name: "อาจารย์ฐาปกรณ์ เรือนใจ",
    position: "ผู้ร่วมวิจัย",
  },
  {
    img: "/media/วันเฉลิม.png",
    name: "นาย วันเฉลิม ชุ่มมงคล",
    position: "ผู้ช่วยนักวิจัย",
  },
];

export const COOPERATION_AGENCY = [
  { name: "มหาวิทยาลัย \n แม่ฟ้าหลวง", img: "/media/สัญลักษณ์ประจำมหาวิทยาลัยแม่ฟ้าหลวง.png" },
  { name: "สำนักงานสาธารณสุข \n อำเภอแม่ฟ้าหลวง", img: "/media/สัญลักษณ์_สสอ.มฟล.jpg" },
  { name: "องค์การบริหาร \n ส่วนตำบลเทอดไทย", img: "/media/สัญลักษณ์_อบต.เทอดไทย.png" },
  { name: "โรงพยาบาลส่งเสริมสุขภาพ \n ตำบลบ้านเทอดไทย", img: "/media/สัญลักษณ์ รพสต.เทอดไทย.png" },
];
