import React from "react";

export function RadioCheck({
  checked,
  name,
  value,
  onChange,
  label,
  onFocus,
  className,
}) {
  return (
    <div
      className="form-check form-check-primary form-check-solid form-check-md"
      
    >
      <input
        className={`form-check-input ${className}`}
        onFocus={onFocus}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        id={label}
        style={{ cursor: "pointer" }}
      />
      <label
        className="text-dark text-nowrap"
        style={{ fontSize: "1rem", marginBottom: 0, cursor: 'pointer' }}
        htmlFor={label}
      >
        {label}
      </label>
    </div>
  );
}
