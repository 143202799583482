import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux";
import { login } from "../../modules/Auth/_redux/authCrud";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import usePasswordToggle from "../Layout/usePasswordToggle";
import { useToast } from "@chakra-ui/react";

const initialValues = {
  email: "",
  password: "",
};

function LoginDialog(props) {
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const history = useHistory();
  const toast = useToast();
  const { t } = useTranslation();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("อีเมลไม่ถูกต้อง")
      .required("อีเมล"),
    password: Yup.string()
      // .min(8, "รหัสผ่านต้องมากกว่าหรือเท่ากับ 8 ตัวอักษร")
      // .max(16, "รหัสผ่านต้องน้อยกว่าหรือเท่ากับ 16 ตัวอักษร")
      .required("รหัสผ่าน"),
  });

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.auth,
    }),
    shallowEqual
  );

  const { error, actionsLoading } = currentState;

  const [invalidNotfound, setInvalidNotfound] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setLoading(true);
      const payload = {
        username: values.email,
        password: values.password,
        type: "SYSTEM",
      };
      login(payload)
        .then((res) => {
          props.login(res.data);
        })
        .catch(({ response }) => {
          if (response?.status == 401) {
            setInvalidLogin(true);
            setInvalidNotfound(false);
          }
          if (response?.status == 404) {
            setInvalidLogin(false);
            setInvalidNotfound(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, []);

  const onClickForgot = () => {
    props.onShow("modalForgotPassword");
  };

  const onClickSignUp = () => {
    props.onShow("modalSignUp");
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.onHide("modalLogin")}
        dialogClassName="modal-90w"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="d-flex justify-content-end w-100">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.onHide("modalLogin")}
            >
              <i aria-hidden="true" class="ki ki-close text-dark"></i>
            </button>
          </div>
          <div className="text-primary font-weight-boldest font-size-h3 text-center mt-3">
            เข้าสู่ระบบ
          </div>
          <div className="font-weight-bolder font-size-sm text-center font-weight-bolder text-dark">
            ระบบฐานข้อมูลภาวะโภชนาการของเด็กวัยก่อนเรียน
            ในศูนย์พัฒนาเด็กเล็กในพื้นที่ อำเภอแม่ฟ้าหลวง จังหวัดเชียงราย
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework my-5"
          >
            {invalidLogin && (
              <div className="text-danger text-center font-size-xs font-weight-bold my-2">
                *อีเมลหรือรหัสผ่านไม่ถูกต้อง*
              </div>
            )}
            {invalidNotfound && (
              <div className="text-danger text-center font-size-xs font-weight-bold my-2">
                *ไม่พบอีเมลในระบบ*
              </div>
            )}
            <div className="form-group fv-plugins-icon-container color-primary">
              <div className="font-size-xs font-weight-bolder">อีเมล</div>
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid font-size-xs h-auto ${
                  formik.touched.email && formik.errors.email
                    ? "border-danger"
                    : ""
                } py-5 px-6`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {/* {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null} */}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <div className="d-flex justify-content-between">
                <div className="font-size-xs font-weight-bolder">รหัสผ่าน</div>
                <button
                  type="button"
                  className="btn btn-link p-0 font-size-xs text-dark"
                  onClick={() => onClickForgot()}
                >
                  ลืมรหัสผ่าน ?
                </button>
              </div>
              <div className="input-block">
                <input
                  placeholder="Password"
                  type={PasswordInputType}
                  className={`form-control form-control-solid font-size-xs h-auto ${
                    formik.touched.password && formik.errors.password
                      ? "border-danger"
                      : ""
                  } py-5 px-6`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                <span
                  className="password-toogle-icon "
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translate(0, -50%)",
                    zIndex: 1000,
                    cursor: "pointer",
                  }}
                >
                  {ToggleIcon}
                </span>
              </div>
              {/* {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null} */}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <button
                type="submit"
                disabled={loading || !formik.isValid}
                className={`btn btn-primary font-size-xs d-flex align-items-center`}
              >
                เข้าสู่ระบบ
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-3"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(LoginDialog));
