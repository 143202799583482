import React, { useMemo, useEffect, useState } from "react";
import { checkIsActive } from "../../../_metronic/_helpers";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { PATH } from "../../../_metronic/_helpers/Path";
import { useSelector, shallowEqual } from "react-redux";
import { Icon } from "@chakra-ui/react";
import jwt_decode from "jwt-decode";
export function MenuTop() {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { authToken } = useSelector(
    (state) => ({
      authToken: state.auth.authToken,
    }),
    shallowEqual
  );
  const [MENU, setMENU] = useState([]);

  useEffect(() => {
    if (authToken) {
      var decoded = jwt_decode(authToken);
      const arr = [];
      decoded.MENU.map((item) => {
        arr.push({
          PATHNAME: item.NAME.replace(/\s/g, ""),
          PATH: PATH[item.NAME.replace(/\s/g, "")].path,
          ICON: PATH[item.NAME.replace(/\s/g, "")].icon,
        });
      });
      setMENU(arr);
    }
  }, [authToken]);

  const getMenuItemActive = (url) => {
    for (let i = 0; i < url.length; i++) {
      if (checkIsActive(location, url[i])) {
        return "btn-primary text-white";
      }
    }
    return "btn-light-white text-secondary";
  };
  return (
    <Dropdown
      className="menu-mobile"
      style={{ minWidth: "250px" }}
      drop="down"
      alignRight
    >
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-link w-auto d-flex align-items-center px-5 font-size-sm"
          }
        >
          {t("MENU")}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="px-2 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround w-100 bg-light-white d-flex flex-column">
        {MENU.map((item) => (
          <Dropdown.Item
            className={`btn ${getMenuItemActive([item.PATH])} p-5`}
            onClick={() => history.push(item.PATH)}
          >
            <span className="svg-icon">
              <Icon
                as={item.ICON}
                className={`${getMenuItemActive([item.PATH])}`}
              />
            </span>
            <span className="font-size-sm mx-2 font-weight-bold">{item.PATHNAME}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
