import * as requestFromServer from "./bannerCrud";
import { bannerSlice, callTypes } from "./bannerSlice";

const { actions } = bannerSlice;

export const getBanner = () => dispatch => {
  requestFromServer.getBanner().then((res) => {
    dispatch(actions.setBanner(res.data))
  }).catch((error) => {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  })
}