import React, { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { AgeDetail } from "../../AgeDetail/AgeDetail";
export function EvaluateChildrenDetail() {
  const { id } = useParams();
  const history = useHistory();

  return (
    <>
      <div>
        <Button
          onClick={() => history.goBack()}
          leftIcon={<FiChevronLeft style={{ fontSize: 20 }} />}
          size="lg"
          className="mt-5"
          style={{ color: "#ffff", backgroundColor: "#58A3BC" }}
        >
          ย้อนกลับไป
        </Button>
      </div>
      <div className="my-5">
        <div className="text-primary font-size-h5 font-weight-bolder">
          คำแนะนำการบริโภคอาหารที่เหมาะสมกับการเจริญเติบโต
        </div>
        <div className="text-f2" style={{ fontSize: 14 }}>
          ตามช่วงอายุของเด็ก
        </div>
      </div>
      <div>
        <AgeDetail buttonBack={false} />
      </div>
    </>
  );
}
