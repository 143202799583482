import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Divider } from "@chakra-ui/react";
import * as actions from "../../modules/Dashboard/_redux/dashboardAction";
import usePasswordToggle from "./usePasswordToggle";
import { useToast } from "@chakra-ui/react";

const initialValues = {
  password: "",
  new_password: "",
  confirm_password: "",
};

export function ResetDialog({ show, onHide, onComplete }) {
  const [PasswordInputType1, ToggleIcon1] = usePasswordToggle();
  const [PasswordInputType2, ToggleIcon2] = usePasswordToggle();
  const [PasswordInputType3, ToggleIcon3] = usePasswordToggle();
  const toast = useToast();
  const dispatch = useDispatch();
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.dashboard,
    }),
    shallowEqual
  );

  const { actionsLoading, error } = currentState;

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearError());
    }
  }, [error]);

  const ResetSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, " ")
      .max(16, " ")
      .required(" "),
    new_password: Yup.string()
      .min(8, " ")
      .max(16, " ")
      .required(" "),
    confirm_password: Yup.string()
      .required(" ")
      .oneOf([Yup.ref("new_password"), null], " "),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ResetSchema,
    onSubmit: (values) => {
      const payload = {
        oldPassword: values.password,
        newPassword: values.new_password,
      };
      console.log(payload);
      dispatch(actions.changePassword(payload)).then((res) => {
        onComplete();
      });
    },
  });
  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide()}
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div
            className="text-primary font-size-h4 text-center mt-3"
            style={{ fontWeight: 600 }}
          >
            เปลี่ยนรหัสผ่านใหม่
          </div>
          <div className="font-size-sm text-center text-dark">
            ระบบฐานข้อมูลภาวะโภชนาการของเด็กวัยก่อนเรียน
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework my-5"
          >
            <div className="form-group fv-plugins-icon-container">
              <div className="font-weight-bolder required">
                <span style={{ fontSize: 16 }}>รหัสผ่านปัจจุบัน</span>
              </div>
              <div className="input-block">
                <input
                  type={PasswordInputType1}
                  className={`form-control form-control-solid font-size-xs h-auto py-5 px-6
                  ${
                    formik.touched.password && formik.errors.password
                      ? "border-danger"
                      : ""
                  }`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                <span
                  className="password-toogle-icon "
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translate(0, -50%)",
                    zIndex: 1000,
                    cursor: "pointer",
                  }}
                >
                  {ToggleIcon1}
                </span>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <div className="d-flex justify-content-between">
                <div className="font-weight-bolder required">
                  <span style={{ fontSize: 16 }}>รหัสผ่านใหม่</span>
                </div>
                <div className="font-size-xs">8 -16 ตัวอักษร</div>
              </div>
              <div className="input-block">
                <input
                  type={PasswordInputType2}
                  className={`form-control form-control-solid font-size-xs h-auto py-5 px-6
                  ${
                    formik.touched.new_password && formik.errors.new_password
                      ? "border-danger"
                      : ""
                  }`}
                  name="new_password"
                  {...formik.getFieldProps("new_password")}
                />
                <span
                  className="password-toogle-icon "
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translate(0, -50%)",
                    zIndex: 1000,
                    cursor: "pointer",
                  }}
                >
                  {ToggleIcon2}
                </span>
              </div>
              {formik.touched.new_password && formik.errors.new_password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.new_password}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <div className="font-weight-bolder required">
                <span style={{ fontSize: 16 }}>ยืนยันรหัสผ่านใหม่</span>
              </div>
              <div className="input-block">
                <input
                  type={PasswordInputType3}
                  className={`form-control form-control-solid font-size-xs h-auto py-5 px-6
                  ${
                    formik.touched.confirm_password &&
                    formik.errors.confirm_password
                      ? "border-danger"
                      : ""
                  }`}
                  name="confirm_password"
                  {...formik.getFieldProps("confirm_password")}
                />
                <span
                  className="password-toogle-icon rounded"
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translate(0, -50%)",
                    zIndex: 1000,
                    cursor: "pointer",
                  }}
                >
                  {ToggleIcon3}
                </span>
              </div>
              {formik.touched.confirm_password &&
              formik.errors.confirm_password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.confirm_password}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="mb-5">
              <Divider />
            </div>
            <div className="d-flex text-dark justify-content-end align-items-center">
              <button
                className={`btn font-size-xs mr-5`}
                onClick={() => onHide()}
                style={{ width: 90, backgroundColor: "#E0DEDE" }}
              >
                ยกเลิก
              </button>
              <button
                className="btn btn-primary font-size-xs"
                type="submit"
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: 90,
                  justifyContent: "center",
                }}
                disabled={actionsLoading}
              >
                {actionsLoading && (
                  <span
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                บันทึก
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
