import React, { useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { Select } from "../../../components/Select";
import * as config from "../../../../config";
import * as actions from "../_redux/ManageUserActions";
import { useToast } from "@chakra-ui/react";
import { useManageUserUIContext } from "./ManageUserUIContext";

export function EditDialog({ show, onHide }) {
  const manageUserUIContext = useManageUserUIContext();
  const manageUserUIProps = useMemo(() => {
    return {
      ids: manageUserUIContext.ids,
      queryParams: manageUserUIContext.queryParams,
      setQueryParams: manageUserUIContext.setQueryParams,
    };
  }, [manageUserUIContext]);

  const { id } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();
  const { auth, currentState } = useSelector(
    (state) => ({
      auth: state.auth,
      currentState: state.manageUser,
    }),
    shallowEqual
  );

  const { error, actionsLoading } = currentState;

  const [province, setProvince] = useState([{ nameTh: "เลือกจังหวัด", id: 0 }]);
  const [amphoe, setAmphoe] = useState([{ nameTh: "เลือกอำเภอ", id: 0 }]);
  const [tambon, setTambon] = useState([{ nameTh: "เลือกตำบล", id: 0 }]);
  const [role, setRole] = useState([{ ROLE: "เลือกบทบาทผู้ใช้งาน", ID: 0 }]);
  const [department, setDepartment] = useState([
    { NAME: "เลือกหน่วยงานที่สังกัด", ID: 0 },
  ]);

  const [initialValues, setInitialValues] = useState({
    ID: null,
    USERNAME: null,
    PASSWORD: null,
    ROLE: null,
    USED: "Y",
    CREATED_DATE: null,
    CREATED_BY: 0,
    UPDATED_DATE: null,
    UPDATED_BY: 0,
    PROVINCE: null,
    AMPHOE: null,
    TAMBON: null,
    FIRSTNAME: null,
    LASTNAME: null,
    DEPARTMENT: null,
    TELEPHONE: null,
  });

  useEffect(() => {
    if (id) {
      dispatch(actions.getUserById(id)).then((res) => {
        setInitialValues({ ...res });
      });
    }
  }, []);

  useEffect(() => {
    if (error) {
      toast({
        title: "เกิดข้อผิดพลาด!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(actions.clearState());
    }
  }, [error]);

  useEffect(() => {
    config.getProvince().then((res) => {
      setProvince(province.concat(res.data));
    });
    config.masterDataRole().then((res) => {
      setRole(role.concat(res.data));
    });
    config.masterDataDepartment().then((res) => {
      setDepartment(department.concat(res.data));
    });
  }, []);

  const AddSchema = Yup.object().shape({
    FIRSTNAME: Yup.string()
      .nullable()
      .trim()
      .required("กรุณากรอกชื่อ")
      .matches(/^[ก-๙]+$/gi, "กรุณากรอกตัวอักษรภาษาไทยเท่านั้น"),
    LASTNAME: Yup.string()
      .nullable()
      .trim()
      .required("กรุณากรอกนามสกุล")
      .matches(/^[ก-๙]+$/gi, "กรุณากรอกตัวอักษรภาษาไทยเท่านั้น"),
    PROVINCE: Yup.number()
      .min(1, "กรุณาเลือกจังหวัด")
      .nullable()
      .required("กรุณาเลือกจังหวัด"),
    AMPHOE: Yup.number()
      .min(1, "กรุณาเลือกอำเภอ")
      .nullable()
      .required("กรุณาเลือกอำเภอ"),
    TAMBON: Yup.number()
      .min(1, "กรุณาเลือกตำบล")
      .nullable()
      .required("กรุณาเลือกตำบล"),
    DEPARTMENT: Yup.number()
      .min(1, "กรุณาเลือกหน่วยงานสังกัด")
      .nullable()
      .required("กรุณาเลือกหน่วยงานสังกัด"),
    USERNAME: Yup.string()
      .nullable()
      .trim()
      .required("กรุณากรอกอีเมล")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "อีเมลไม่ถูกต้อง"
      ),
    ROLE: Yup.number()
      .min(1, "กรุณาเลือกตำแหน่ง")
      .nullable()
      .required("กรุณาเลือกตำแหน่ง"),
    TELEPHONE: Yup.string()
      .nullable()
      .matches(/^[0-9]+$/gi, "กรุณากรอกตัวเลขเท่านั้น")
      .min(9, "เบอร์โทรศัพท์ไม่ถูกต้อง")
      .max(10, "เบอร์โทรศัพท์ไม่ถูกต้อง")
      // .required("กรุณากรอกเบอร์โทรศัพท์"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AddSchema,
    onSubmit: (values) => {
      if (id) {
        const payload = {
          ...values,
          UPDATED_BY: auth.user.ID,
          UPDATED_DATE: new Date(),
        };
        dispatch(
          actions.updateUser(payload, manageUserUIProps.queryParams)
        ).then(() => {
          toast({
            title: "แก้ไขบัญชีผู้ใช้งาน สำเร็จ!",
            description: "คุณได้ทำการแก้ไขบัญชีสำเร็จ",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onHide();
        });
      } else {
        const payload = {
          ...values,
          CREATED_BY: auth.user.ID,
          CREATED_DATE: new Date(),
          UPDATED_BY: auth.user.ID,
          UPDATED_DATE: new Date(),
        };
        dispatch(
          actions.createUser(payload, manageUserUIProps.queryParams)
        ).then(() => {
          toast({
            title: "สร้างบัญชีผู้ใช้งาน สำเร็จ!",
            description: "คุณได้ทำการสร้างบัญชีสำเร็จ",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onHide();
        });
      }
    },
  });

  useEffect(() => {
    if (formik.values.PROVINCE) {
      setTambon([{ nameTh: "เลือกตำบล", id: 0 }]);
      setAmphoe([{ nameTh: "เลือกอำเภอ", id: 0 }]);

      if (!id) {
        formik.setFieldValue("AMPHOE", null);
      }

      config.getDistrictByProvinceId(formik.values.PROVINCE).then((res) => {
        setAmphoe([{ nameTh: "เลือกอำเภอ", id: 0 }, ...res.data]);
      });
    }
  }, [formik.values.PROVINCE]);

  useEffect(() => {
    if (formik.values.AMPHOE) {
      setTambon([{ nameTh: "เลือกตำบล", id: 0 }]);
      
      if (!id) {
        formik.setFieldValue("TAMBON", null);
      }

      config.getSubDistrictByDistrictId(formik.values.AMPHOE).then((res) => {
        setTambon([{ nameTh: "เลือกตำบล", id: 0 }, ...res.data]);
      });
    }
  }, [formik.values.AMPHOE]);

  useEffect(() => {
    console.log(initialValues);
  }, [formik.values]);

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        onHide={onHide}
        size="lg"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between w-100 pb-5 border-bottom">
            <div className="text-primary font-weight-boldest font-size-h5">
              {id ? "แก้ไขข้อมูลส่วนตัวผู้ใช้งาน" : "เพิ่มผู้ใช้งาน"}
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="container-fluid p-5 d-flex flex-column font-weight-bolder"
          >
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark-75 font-size-xs ">
                <div className="required">ชื่อ-นามสกุล</div>
              </div>
              <div
                className="col px-0 d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ width: "49%" }}>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.FIRSTNAME && formik.errors.FIRSTNAME
                        ? "border-danger"
                        : "border-f2"
                    } font-size-sm mr-1 text-dark`}
                    name="FIRSTNAME"
                    style={{ backgroundColor: "transparent", fontSize: "1rem" }}
                    {...formik.getFieldProps("FIRSTNAME")}
                  />
                  {formik.touched.FIRSTNAME && formik.errors.FIRSTNAME && (
                    <span
                      className="text-danger"
                      style={{ fontWeight: "normal" }}
                    >
                      {formik.errors.FIRSTNAME}
                    </span>
                  )}
                </div>
                <div style={{ width: "49%" }}>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.LASTNAME && formik.errors.LASTNAME
                        ? "border-danger"
                        : "border-f2"
                    } font-size-sm text-dark`}
                    name="LASTNAME"
                    style={{ backgroundColor: "transparent", fontSize: "1rem" }}
                    {...formik.getFieldProps("LASTNAME")}
                  />
                  {formik.touched.LASTNAME && formik.errors.LASTNAME && (
                    <span
                      className="text-danger"
                      style={{ fontWeight: "normal" }}
                    >
                      {formik.errors.LASTNAME}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark-75 font-size-xs ">
                <div className="required">อีเมล</div>
              </div>
              <div className="col px-0">
                <input
                  type="text"
                  className={`form-control font-size-sm ${
                    id ? "text-f2" : "text-dark"
                  }
                  ${
                    formik.touched.USERNAME && formik.errors.USERNAME
                      ? "border-danger"
                      : "border-f2"
                  }
                  `}
                  name="USERNAME"
                  style={{
                    backgroundColor: id ? "#F2F3EE" : "transparent",
                    fontSize: "1rem",
                  }}
                  disabled={id ? true : false}
                  value={formik.values.USERNAME}
                  onChange={formik.handleChange}
                  onFocus={() =>
                    formik.setTouched({ ...formik.touched, ["USERNAME"]: true })
                  }
                  onBlur={() => {
                    if (!formik.errors.USERNAME && formik.values.USERNAME) {
                      config.checkEmail(formik.values.USERNAME).then((res) => {
                        if (res.data.status == false) {
                          formik.setFieldError(
                            "USERNAME",
                            "อีเมลนี้ถูกใช้ไปแล้ว"
                          );
                          formik.setFieldValue("USERNAME", "", false);
                        }
                      });
                    }
                  }}
                />
                {formik.touched.USERNAME && formik.errors.USERNAME && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.USERNAME}
                  </span>
                )}
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark-75 font-size-xs ">
                <div style={{fontSize: '1rem'}}>หมายเลขโทรศัพท์</div>
              </div>
              <div className="col px-0">
                <input
                  type="text"
                  className={`form-control ${
                    formik.touched.TELEPHONE && formik.errors.TELEPHONE
                      ? "border-danger"
                      : "border-f2"
                  } font-size-sm text-dark`}
                  name="TELEPHONE"
                  maxLength={10}
                  style={{ backgroundColor: "transparent", fontSize: "1rem" }}
                  {...formik.getFieldProps("TELEPHONE")}
                />
                {formik.touched.TELEPHONE && formik.errors.TELEPHONE && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.TELEPHONE}
                  </span>
                )}
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark-75 font-size-xs ">
                <div className="required">บทบาทผู้ใช้งาน</div>
              </div>
              <div className="col px-0">
                <Select
                  name="ROLE"
                  className={`${
                    formik.touched.ROLE && formik.errors.ROLE
                      ? "border-danger"
                      : "border-f2"
                  }`}
                  onFocus={() =>
                    formik.setTouched({ ...formik.touched, ["ROLE"]: true })
                  }
                  SelectStyle={{ backgroundColor: "transparent" }}
                  item={role}
                  labelObject={"ROLE"}
                  valueObject={"ID"}
                  {...formik.getFieldProps("ROLE")}
                />
                {formik.touched.ROLE && formik.errors.ROLE && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.ROLE}
                  </span>
                )}
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-6 px-0 text-dark-75 font-size-xs">
                <div className="required">พื้นที่ (จังหวัด/อำเภอ/ตำบล)</div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12 px-0 ">
                    <Select
                      name="PROVINCE"
                      className={`${
                        formik.touched.PROVINCE && formik.errors.PROVINCE
                          ? "border-danger"
                          : "border-f2"
                      }`}
                      onFocus={() =>
                        formik.setTouched({
                          ...formik.touched,
                          ["PROVINCE"]: true,
                        })
                      }
                      SelectStyle={{ backgroundColor: "transparent" }}
                      item={province}
                      labelObject={"nameTh"}
                      valueObject={"id"}
                      {...formik.getFieldProps("PROVINCE")}
                    />
                    {formik.touched.PROVINCE && formik.errors.PROVINCE && (
                      <span
                        className="text-danger"
                        style={{ fontWeight: "normal" }}
                      >
                        {formik.errors.PROVINCE}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-6 pl-0">
                    <Select
                      name="AMPHOE"
                      className={`${
                        formik.touched.AMPHOE && formik.errors.AMPHOE
                          ? "border-danger"
                          : "border-f2"
                      }`}
                      onFocus={() =>
                        formik.setTouched({
                          ...formik.touched,
                          ["AMPHOE"]: true,
                        })
                      }
                      SelectStyle={{
                        backgroundColor: "transparent",
                        borderColor: "#979797",
                      }}
                      labelObject={"nameTh"}
                      item={amphoe}
                      valueObject={"id"}
                      {...formik.getFieldProps("AMPHOE")}
                    />
                    {formik.touched.AMPHOE && formik.errors.AMPHOE && (
                      <span
                        className="text-danger"
                        style={{ fontWeight: "normal" }}
                      >
                        {formik.errors.AMPHOE}
                      </span>
                    )}
                  </div>
                  <div className="col-6 pr-0">
                    <Select
                      name="TAMBON"
                      className={`${
                        formik.touched.TAMBON && formik.errors.TAMBON
                          ? "border-danger"
                          : "border-f2"
                      }`}
                      onFocus={() =>
                        formik.setTouched({
                          ...formik.touched,
                          ["TAMBON"]: true,
                        })
                      }
                      SelectStyle={{ backgroundColor: "transparent" }}
                      labelObject={"nameTh"}
                      item={tambon}
                      valueObject={"id"}
                      {...formik.getFieldProps("TAMBON")}
                    />
                    {formik.touched.TAMBON && formik.errors.TAMBON && (
                      <span
                        className="text-danger"
                        style={{ fontWeight: "normal" }}
                      >
                        {formik.errors.TAMBON}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col px-0 text-dark-75 font-size-xs ">
                <div className="required">หน่วยงานที่สังกัด</div>
              </div>
              <div className="col px-0">
                <Select
                  name="DEPARTMENT"
                  className={`${
                    formik.touched.DEPARTMENT && formik.errors.DEPARTMENT
                      ? "border-danger"
                      : "border-f2"
                  }`}
                  onFocus={() =>
                    formik.setTouched({
                      ...formik.touched,
                      ["DEPARTMENT"]: true,
                    })
                  }
                  SelectStyle={{ backgroundColor: "transparent" }}
                  item={department}
                  labelObject={"NAME"}
                  valueObject={"ID"}
                  {...formik.getFieldProps("DEPARTMENT")}
                />
                {formik.touched.DEPARTMENT && formik.errors.DEPARTMENT && (
                  <span
                    className="text-danger"
                    style={{ fontWeight: "normal" }}
                  >
                    {formik.errors.DEPARTMENT}
                  </span>
                )}
              </div>
            </div>
            {/* {formik.errors.USERNAME == "อีเมลนี้ถูกใช้ไปแล้ว" && (
              <div className="row mt-3">
                <div
                  class="d-flex p-0"
                  style={{
                    backgroundColor: "#FCE5E5",
                    borderRadius: 0,
                    width: "100%",
                  }}
                  role="alert"
                >
                  <div
                    style={{
                      height: "100%",
                      width: 3,
                      backgroundColor: "#E00000",
                    }}
                  ></div>
                  <span
                    className="p-5"
                    style={{
                      color: "#E00000",
                      fontSize: 16,
                      fontWeight: "normal",
                    }}
                  >
                    กรุณาตรวจสอบ : {formik.errors.USERNAME}
                  </span>
                </div>
              </div>
            )} */}
          </form>
          <div
            className="d-flex align-items-center py-5 border-top"
            style={{ justifyContent: "space-between" }}
          >
            <button
              type="button"
              className="btn font-size-sm fw-bold"
              onClick={() => onHide()}
              style={{ backgroundColor: "#E0DEDE" }}
            >
              ยกเลิก
            </button>
            <button
              type="button"
              className="btn btn-primary font-size-sm fw-bold"
              disabled={!formik.isValid || actionsLoading}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {actionsLoading && (
                <span
                  className="spinner-border spinner-border-sm mr-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {id ? "บันทึก" : "สร้างบัญชี"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
