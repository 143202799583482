import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { shallowEqual, useSelector } from "react-redux";
export function ForgotSuccessDialog({ show, onHide, onShow }) {
  const history = useHistory();

  const { email } = useSelector(
    ({ auth }) => ({
      email: auth.email,
    }),
    shallowEqual
  );

  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide("modalForgotSuccess")}
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: 500,
            }}
          >
            <div>
              <div className="d-flex justify-content-center align-items-center mb-8">
                <span className="navi-icon">
                  <i
                    class="fa fa-check-circle icon-8x "
                    style={{ color: "#6B8A47" }}
                  ></i>
                </span>
              </div>
              <div className="text-primary font-weight-boldest font-size-h3 text-center mt-3">
                ยืนยันส่งอีเมลของคุณสำเร็จ
              </div>
              <div className="text-center text-dark font-size-xs font-weight-boldest">
                เราได้ส่งลิงก์เพื่อตั้งรหัสผ่านใหม่ไปที่
                <br />
                <span className="text-warning">{email}</span>
                <br /> กรุณาตรวจสอบอีเมลของคุณ
              </div>
              <div className="d-flex justify-content-center align-items-center my-5">
                <button
                  type="button"
                  className="btn btn-primary font-size-sm font-weight-bolder"
                  onClick={() => onShow("modalLogin")}
                >
                  ปิด
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
