import * as requestFromServer from "./ManageUserCrud";
import { UserSlice, callTypes } from "./ManageUserSlice";
const { actions } = UserSlice;

export const getUserList = (queryParams) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getUser(queryParams)
      .then((res) => {
        dispatch(actions.setEntities(res.data));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const createUser = (payload, queryParams) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .createUser(payload)
      .then((res) => {
        dispatch(getUserList(queryParams));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const updateUser = (payload, queryParams) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .updateUser(payload)
      .then((res) => {
        dispatch(getUserList(queryParams));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const deleteUser = (id, queryParams) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .deleteUser(id)
      .then((res) => {
        dispatch(getUserList(queryParams));
        resolve();
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};

export const clearState = () => (dispatch) => {
  dispatch(actions.clearState());
};

export const getUserById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    requestFromServer
      .getUserById(id)
      .then((res) => {
        dispatch(actions.stopCall({ callTypes: callTypes.action }));
        resolve(res.data);
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject();
      });
  });
};
