import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Icon } from "@chakra-ui/react";
import { FiChevronLeft } from "react-icons/fi";
import { FaRegFaceSmile } from "react-icons/fa6";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { AGE_DETAIL } from "../../../_mock/Home";
import * as actions from "./_redux/AgeDetailActions";
import { useDispatch, shallowEqual, useSelector } from "react-redux";

export function AgeDetail({ buttonBack = true }) {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [ageDetail, setAgeDetail] = useState(null);

  useEffect(() => {
    dispatch(actions.getDietary(id));
  }, []);

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.ageDetail,
    }),
    shallowEqual
  );

  const { dietary } = currentState;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const findData = AGE_DETAIL.find((item) => item.id == id);
    setAgeDetail(findData);
  }, [id]);

  return (
    <div className="bg-c3 py-10">
      <div className="container">
        {buttonBack && (
          <div className="mb-10">
            <Button
              onClick={() => history.goBack()}
              leftIcon={<FiChevronLeft style={{ fontSize: 20 }} />}
              size="lg"
              style={{ color: "#ffff", backgroundColor: "#58A3BC" }}
            >
              ย้อนกลับไป
            </Button>
          </div>
        )}

        <div className="text-center mb-10">
          <p style={{ fontWeight: 600, fontSize: 30 }}>
            คำแนะนำการบริโภคอาหารที่เหมาะสมกับการเจริญเติบโต
          </p>
          <p
            className="text-primary"
            style={{ fontWeight: "bold", fontSize: 40 }}
          >
            “{ageDetail?.title}”
          </p>
        </div>
        {ageDetail?.detail.map((item, index) => (
          <div
            className="mb-10 d-flex shadow"
            style={{ backgroundColor: "#ffff", borderRadius: 5, padding: 20 }}
            key={index}
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center px-5 text-center"
              style={{
                backgroundColor: item.bgColor,
                minWidth: 150,
                maxWidth: 150,
                borderRadius: 5,
              }}
            >
              <Icon
                as={item.status == "good" ? FaRegFaceSmile : HiOutlineEmojiSad}
                style={{ color: item.iconColor, fontSize: 70 }}
              />
              <p className="mt-5" style={{ color: item.iconColor }}>
                {item.title}
              </p>
            </div>
            <div
              className="d-flex flex-column pl-10"
              style={{ justifyContent: "space-between" }}
            >
              <p
                className="text-primary"
                style={{ fontSize: 18, fontWeight: 600 }}
              >
                แนวโน้มการเจริญเติบโต : {item.title}
              </p>
              <p
                className="text-primary"
                style={{ fontSize: 18, fontWeight: 600 }}
              >
                วิธีส่งเสริมภาวะโภชนาการ
              </p>
              <div dangerouslySetInnerHTML={{ __html: item.detail }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
