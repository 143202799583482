/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Divider, VStack } from "@chakra-ui/react";

export function FooterBar() {
  return (
    <div className="bg-primary">
      <div className="container py-10">
        <Divider />
        <div className="row mt-3">
          <div className="col-12 text-center">
            <p className="text-f4">Copyright © 2023. All Right Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
